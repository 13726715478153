<template>
    <main id="main" class="checkout">
        <b-container tag="section" class="checkout__header mb-5">
            <b-row>
                <b-col cols="12" md="6">
                    <img src="@/assets/gastronomer-logo.jpg" alt="Gastronomer Logo"
                         class="checkout__store-logo"/>
                </b-col>
                <b-col cols="12" md="6">
                    <h1>Checkout</h1>
                </b-col>
            </b-row>
        </b-container>
        <form>
            <b-container tag="section" class="checkout__details">
                <b-row>
                    <b-col cols="12" lg="6">
                        <div class="checkout__container mb-5 py-5 px-md-5">
                            <h2 class="mb-5">Your Information</h2>
                            <b-container tag="form" class="checkout__form">
                                <b-row>
                                    <b-col>
                                        <label for="checkout_name">Full Name</label>
                                        <input type="text" id="checkout_name" v-model="customer_name" required/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label for="checkout_email">Email Address</label>
                                        <input type="email" id="checkout_email" v-model="customer_email" required/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label for="checkout_address">Address</label>
                                        <input type="text" id="checkout_address" v-model="customer_address" required/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label for="checkout_city">City</label>
                                        <input type="text" id="checkout_city" v-model="customer_city" required/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label for="checkout_province">Province</label>
                                        <input type="text" id="checkout_province" v-model="customer_province" required/>
                                    </b-col>
                                    <b-col>
                                        <label for="checkout_postal">Postal Code</label>
                                        <input type="text" id="checkout_postal" v-model="customer_postal" required/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label for="checkout_phone">Phone Number</label>
                                        <input type="tel" id="checkout_phone" v-model="customer_phone" required/>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="checkout__container py-5 px-md-5 mb-5">
                            <h2 class="mb-5">Payment Details</h2>
                            <b-container tag="form" class="checkout__form">
                                <b-row>
                                    <b-col class="checkout__credit-cards">
                                        <img alt="Visa logo" src="../assets/cc-visa.png"/>
                                        <img alt="MasterCard logo" src="../assets/cc-mastercard.png"/>
                                        <img alt="American Express logo" src="../assets/cc-amex.png"/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <label for="checkout_ccard">Credit Card</label>
                                    <card id="checkout_ccard"
                                          class='stripe-card'
                                          :class="{ complete }"
                                          :stripe="publishableKey"
                                          :options="stripeOptions"
                                          v-on:change="handleCardChange($event)"
                                    />
                                  </b-col>
                                </b-row>
<!--                                <b-row>
                                    <b-col>
                                        <label for="checkout_ccnumber">Credit Card Number</label>
                                        <input type="text" id="checkout_ccnumber" v-model="card_number" required/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6" md="4">
                                        <label for="checkout_month">Expiry Month</label>
                                        <input type="number" id="checkout_month" placeholder="MM" maxlength="2" max="12"
                                               min="1" v-model="card_month" required/>
                                    </b-col>
                                    <b-col cols="6" md="4">
                                        <label for="checkout_year">Expiry Year</label>
                                        <input type="number" id="checkout_year" maxlength="2" max="28" min="20"
                                               placeholder="YY" v-model="card_year" required/>
                                    </b-col>
                                    <b-col cols="6" md="4">
                                        <label for="checkout_cccvv">CVV</label>
                                        <input type="text" id="checkout_cccvv" v-model="card_cvv" required  />
                                    </b-col>
                                </b-row>-->
                                <!--<b-row>
                                    <b-col>
                                        <label for="checkout_ccname">Name on Card</label>
                                        <input type="text" id="checkout_ccname"/>
                                    </b-col>
                                </b-row>-->
                            </b-container>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="6">
                        <div class="checkout__container mb-5 py-5 px-md-5">
                            <h2 class="mb-5">Your Order</h2>
                            <Cart :fixed="false"/>
                        </div>
                        <div class="checkout__container mb-5 py-5 px-md-5">
                            <h2 class="mb-5">Choose a Pickup Date & Time</h2>
                            <b-container tag="form" class="checkout__form">
                                <b-row v-if="!withinHours" class="mb-3">
                                    <b-col>
                                        <div class="alert-danger">Pickup time must be during available hours</div>
                                    </b-col>
                                </b-row>
                                <b-row v-if="!withinLimit" class="mb-3">
                                    <b-col>
                                        <div class="alert-warning">Some items in your cart require
                                            {{processingTime}} hours to process. Please select another date.
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <label for="checkout_pickupdate">Date</label>
                                        <b-form-datepicker id="checkout_pickupdate" v-model="pickup_date" :key="'pickupForm' + pickupFormKey + 'date'"
                                                           class="mb-2" :min="minDate"></b-form-datepicker>
                                        <!--<input type="text" id="checkout_pickupdate" v-model="pickup_date"/>-->
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <label for="checkout_pickuptime">Time</label>
                                        <b-form-timepicker id="checkout_pickuptime" v-model="pickup_time" :key="'pickupForm' + pickupFormKey + 'time'"
                                                           locale="en"></b-form-timepicker>
                                        <!--<input type="text" id="checkout_pickuptime" v-model="pickup_time"/>-->
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="mt-4">
                                        <small>Pickup available between 12:30pm and 5pm. <template v-if="processingTime >= 24">We will need at least 24 hours to prepare your order.</template></small>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="pt-5">

                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p v-html="errorMessage" aria-label="Error message" class="error_message text-center"></p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" lg="6" xl="4" offset-xl="2" class="p-5">
                        <b-row>
                            <b-col>Subtotal:</b-col>
                            <b-col aria-label="Subtotal">{{cart.totals.subtotal | currency}}</b-col>
                        </b-row>
                        <b-row>
                            <b-col>HST:</b-col>
                            <b-col aria-label="HST">{{cart.totals.taxTotal | currency}}</b-col>
                        </b-row>
                        <b-row>
                            <b-col id="checkout-total"><b>TOTAL:</b></b-col>
                            <b-col aria-label="Total"><b>{{cart.totals.totalValue | currency}}</b></b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" lg="6" xl="4" class="d-flex flex-column justify-content-center mb-3">
                        <label for="checkout_terms" class="checkout__terms-statement mb-3">
                            <input type="checkbox" id="checkout_terms" v-model="termscheckbox"/>
                            I accept the <a href="https://storage.googleapis.com/bayview-village/gastronomer-terms.pdf"
                                            target="_blank" required>terms &
                            conditions</a>.
                        </label>
                        <input v-if="processing" type="button" class="checkout__submit-button" value="Processing..."
                               disabled/>
                        <input v-else type="submit" class="checkout__submit-button" value="Place Order"
                               v-on:click.prevent="orderSubmit">
                        <small class="checkout__submit-comment mt-2">Please review your order prior to purchase as all
                            sales are final.</small>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <router-link to="/gastronomer" class="checkout__back-link text-center mb-5">Back to Shop
                        </router-link>
                    </b-col>
                </b-row>
            </b-container>
        </form>
    </main>
</template>
<script>
    import {Card, createToken} from 'vue-stripe-elements-plus'
    import Cart from '@/components/Cart'
    import {mapState} from "vuex"
    import {mapFields} from 'vuex-map-fields'

    export default {
        name: 'Checkout',
        components: {Cart, Card},
        data() {
            return {
                processing: false,
                errorMessage: '',
                termscheckbox: false,
                pickupFormKey: 1,
                complete: false,
                stripeOptions: {},
                publishableKey:
                    location.host.indexOf('localhost') === -1 ?
                        'pk_live_51JlvnaI8gXbbruyUwUEh0agheqhm4fEulb8kX8n9eVtWeEDqmEENvgUfUAk7jtDg0bjhIvCsn5NDazTMxO53y8fS00orllqXyZ' :
                        'pk_test_51JlvnaI8gXbbruyU0kmbKvJBQ7znd4JDYd5LUrS8zwHjj72pyXiXrkGm4KcaarcEGcH6qLTDPgUEQSJ5KCpBUlKU00RHwm4xuS'
            }
        },
        computed: {
            ...mapState({
                cart: state => state.cart,
                products: state => state.products
            }),
            ...mapFields([
                'formData.orderForm.customer_name',
                'formData.orderForm.customer_email',
                'formData.orderForm.customer_address',
                'formData.orderForm.customer_city',
                'formData.orderForm.customer_province',
                'formData.orderForm.customer_postal',
                'formData.orderForm.customer_phone',
                'formData.orderForm.pickup_date',
                'formData.orderForm.pickup_time',
                'formData.orderForm.stripe_token'
                /*'formData.orderForm.card_number',
                'formData.orderForm.card_year',
                'formData.orderForm.card_month',
                'formData.orderForm.card_cvv'*/
            ]),
            cartItems() {
              let result = []
              if(this.products.length > 0)
                for (const item_id in this.cart.items) {
                  const product = this.products.filter(product => product.id === item_id)[0]
                  product.qty = this.cart.items[item_id]
                  result.push(product)
                }
              return result
            },
            processingTime() {
              let products = this.cartItems
              let minimumProcessingTime = 0.5
              for (let key in products) {
                let product = products[key]
                let preptime = 24
                if (product.prep_time !== undefined)
                  preptime = parseInt(product.prep_time)
                if (preptime > minimumProcessingTime)
                  minimumProcessingTime = preptime
              }
              return minimumProcessingTime
            },
            minDate() {
              // Set today as default
              const now = new Date()
              let minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

              // Loop products and look for minimum pickup date
              let products = this.cartItems

              for (let key in products) {
                let product = products[key]
                if(product.start) {
                  let productMinDate = new Date(product.start.split('+')[0])
                  if(productMinDate.getTime() >= minDate.getTime())
                    minDate = productMinDate
                }
              }
              return minDate
            },
            withinHours() {
                //let date = new Date(this.pickup_date)
                //let dow = date.getDay()
                let time = this.pickup_time.split(':')[0] + this.pickup_time.split(':')[1]

                if (this.pickup_date !== '' && this.pickup_time !== '') {
                    if (time < 1230 || time > 1700
                        //(dow < 5 && (time < 1200 || time > 1900)) ||
                        // (dow > 4 && (time < 1200 || time > 1700))
                        // (dow < 5 && (time < 1100 || time > 1900)) ||
                        // (dow === 5 && (time < 1000 || time > 1800)) ||
                        // (dow === 6 && (time < 1200 || time > 1700))
                    )
                        return false
                }
                return true
            },
            withinLimit() {
                if (this.pickup_time === '' || this.pickup_date === '')
                    return true
                let now = new Date()
                let minimum = parseInt(this.processingTime * 60 * 60 * 1000)
                let datearray = this.pickup_date.split('-')
                let year = parseInt(datearray[0])
                let month = parseInt(datearray[1])
                let day = parseInt(datearray[2])
                let timearray = this.pickup_time.split(':')
                let hour = parseInt(timearray[0])
                let minute = parseInt(timearray[1])
                let datetime = new Date()
                datetime.setFullYear(year)
                datetime.setMonth(month - 1)
                datetime.setDate(day)
                datetime.setHours(hour)
                datetime.setMinutes(minute)
                datetime.setSeconds(0)
                return datetime.getTime() >= (now.getTime() + minimum);
            }
        },
        methods: {
            orderSubmit() {
                if (this.validateForm()) {
                    this.processing = true
                    let closure = this
                    this.$store.dispatch('CHECK_OUT').then(function () {
                        closure.$store.commit('RESET_FORM_STATE', 'orderForm')
                        closure.$store.commit('RESET_STATE', 'cart')
                        closure.errorMessage = ''
                        closure.processing = false
                        closure.$router.push('/gastronomer/thanks')
                    }).catch(function (message) {
                        closure.errorMessage = "There was a problem submitting the form. " + message
                        closure.processing = false
                    })
                }
            },
            validateForm() {
                if (this.$store.state.cart.totals.totalValue === 0) {
                    this.errorMessage = "Your cart is empty."
                    return false
                }
                if (!this.stripe_token || this.stripe_token.length === 0) {
                  this.errorMessage = "Problem with payment details. Please verify and try again."
                  return false
                }
                if (!this.withinHours || !this.withinLimit) {
                    this.errorMessage = "There is an issue with your pick-up time. Please see above."
                    return false
                }
                if (this.termscheckbox === false) {
                    this.errorMessage = "You must agree to the terms and conditions."
                    return false
                }
                for (let i in this.$store.state.formData.orderForm) {
                    if (this.$store.state.formData.orderForm[i].length === 0) {
                        this.errorMessage = "All fields are required. Please check the form and submit again."
                        return false
                    }
                }
                return true
            },
            resetPickupForm() {
                this.pickupFormKey += 1
                return true
            },
             handleCardChange(event) {
              this.complete = event.complete
              createToken().then(data => {
                if (data.token && data.token.id)
                  this.stripe_token = data.token.id
              })
            }
        },
        mounted() {
            if (this.products.length === 0) {
                this.$store.dispatch('LOAD_API', {name: 'products', url: 'products?showsubs=true'})
            }
        },
        watch: {
          'minDate': function() {
            this.resetPickupForm()
          }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .checkout {
        h1, h2 {
            text-transform: uppercase;
        }

        &__header {

            [class*='col'] {
                display: flex;
                align-items: flex-end;
                justify-content: center;

                &:first-child {
                    @include media-breakpoint-up(md) {
                        justify-content: flex-start;
                    }
                }

                &:last-child {
                    @include media-breakpoint-up(md) {
                        justify-content: flex-end;
                    }
                }

                h1 {
                    font-weight: bold;
                }
            }
        }

        &__store-logo {
            max-width: 300px;
        }

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 1rem;
            border-left: 1px rgba(0, 0, 0, 0.05) solid;
            border-top: 1px rgba(0, 0, 0, 0.05) solid;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
        }

        &__form {
            width: 100%;
            padding: 0 3rem;

            label {
                display: block;
                text-transform: uppercase;
                width: 100%;
                text-align: left;
                font-size: 1.2rem;
            }

            input, .stripe-card {
                border: 1px rgba(229, 27, 128, 0.3) solid;
                border-radius: 0.75rem;
                width: 100%;
                padding: 0.5rem 1.5rem;
                margin-bottom: 2rem;
            }

            .stripe-card.complete {
              border-color: green;
            }
        }

        &__credit-cards {
            margin-bottom: 3rem;

            img {
                width: 60px;
                margin: 0 0.5rem;

                @include media-breakpoint-up(md) {
                    margin: 0 1rem;
                }
            }
        }

        &__submit-button {
            background-color: #D9017A;
            border: 0;
            padding: 0.75em;
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 1.7rem;
            line-height: 1.25em;
            font-weight: bold;
            width: 100%;
            border-radius: 0.75rem;
            text-decoration: none;
        }

        &__submit-comment {
            color: #666666;
            font-size: 1.1rem;
        }

        &__terms-statement {
            font-size: 1.3rem;

            a:hover {
                color: #D9017A;
            }
        }

        &__back-link {
            display: block;
            color: #D9017A;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            font-size: 1.2rem;
            text-decoration: none;

            &::before {
                content: '<';
                margin-right: 0.4rem;
            }
        }

        .c {
            color: red;
        }
    }
</style>
