<template>
    <VueSlickCarousel
            :arrows="true"
            :dots="false"
            :autoplay="true"
            :autoplaySpeed=6000
            lazyLoad="ondemand"
            class="slider"
            ref="carousel"
            v-if="slides.length > 0">
        <div class="slide" v-for="(slide, index) in slides" :key="index">
            <CaptionBox
                    :ratio=ratio
                    :image=slide.image
                    :caption=slide.caption
                    :link=slide.link
                    captionPosition="bottom-left"
                    captionMaxWidth="400px"
                    class="slide__captionbox"/>
        </div>
    </VueSlickCarousel>
    <div v-else class="slider-placeholder"></div>
</template>
<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: 'Slider',
        components: {VueSlickCarousel},
        props: {
            slides: Array,
            ratio: Number
        }
    }
</script>
<style lang="scss">
  .slider-placeholder {
    display: block;
    height: 100%;
    background: url(../assets/bayview-village-placeholder.png) no-repeat center center #F1F1F1;
    background-blend-mode: color-burn;
  }
</style>