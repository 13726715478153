<template>
    <b-container tag="section" class="pagination">
        <b-row>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
        </b-row>
    </b-container>
</template>
<script>
    export default {
        name: 'Pagination'
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';
</style>