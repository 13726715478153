<template>
  <main id="main" class="article" v-if="loaded">
    <b-container class="article__header mb-5 py-0">
      <div class="divider sidelines mb-5" v-if="postdata === false">
        <img :src="require('@/assets/heads/' + publicationGroup + '.png')"
             alt="Sketch of a woman's head.">
        <router-link class="h2" :to="'/' + publicationGroup" :title="blogName">
          {{ blogName }}
        </router-link>
      </div>
      <CaptionBox
          :ratio=45
          v-if="headerImage"
          :image="{src: headerImage.src, alt: headerImage.alt}"
          captionPosition="top-left"
          :caption="postdata !== false ? post.data.title : null"
          class="mb-5 article__header-image"/>
      <div class="article__info" v-if="postdata === false">
        <router-link class="h2" :to="'/' + publicationGroup" :title="blogName">
          {{ blogName }}
        </router-link>
        <h1>{{ post.data.title }}</h1>
        <div class="article__date">{{ post.data.published | moment('MMMM DD, YYYY') }}</div>
      </div>
      <div v-html="postBody"></div>
    </b-container>
    <b-container tag="section" class="article__author"
                 v-if="postdata === false && publicationGroup !== 'lifestyle-files' && post.data.author.first_name">
      <b-row>
        <b-col cols="12" md="4" class="article__author-image mb-5">
          <div class="square-image">
            <img :src="post.data.author.profile_image"
                 :alt="'Photo of ' + post.data.author.first_name + ' ' + post.data.author.last_name"/>
          </div>
        </b-col>
        <b-col cols="12" md="8" class="article__author-details">
          <h2 class="mb-4">{{ post.data.author.first_name }} {{ post.data.author.last_name }}</h2>
          <div class="article__author-bio hidden-xs"><p v-if="post.data.author.bio"
                                                        v-html="post.data.author.bio"></p></div>
        </b-col>
      </b-row>
    </b-container>
    <b-container tag="aside" class="article__related" v-if="relatedPosts.length > 0">
      <div class="divider sidelines mt-4 mb-5">
        <img src="@/assets/heads/ask-the-expert.png"
             alt="Sketch of a woman's head facing away wearing sunglasses on top of her hair.">
        <h2>More Haute Reads</h2>
      </div>
      <ArticleGallery :articles="relatedPosts"/>
    </b-container>
  </main>
  <Loader v-else/>
</template>
<script>
import {butter} from "@/buttercms"
import ArticleGallery from "@/components/home/ArticleGallery.vue";

export default {
  name: 'BlogPost',
  components: {ArticleGallery},
  data() {
    return {
      post: {},
      relatedPosts: [],
      loaded: false,
      blogNames: {
        'blog': 'BV Blog',
        'lifestyle-files': 'Lifestyle Files',
        'ask-the-expert': 'Ask the Expert'
      }
    }
  },
  props: {
    postdata: {
      type: [Object, Boolean],
      default() {
        return false
      }
    }
  },
  computed: {
    publicationGroup() {
      return this.$route.path.split('/')[1]
    },
    blogName() {
      return this.blogNames[this.publicationGroup]
    },
    headerImage() {
      if (this.post.data.body && this.post.data.body.slice(0, 7) === '<p><img') {
        const params = this.getTagParams(this.post.data.body, 'img')
        return params
      }
      return false
    },
    postBody() {
      if (this.post.data === undefined)
        return ''

      // Replace new lines
      let body = this.post.data.body.replace(/\r?\n|\r/g, '')

      // Find all tables that contain a caption tag where the caption tag contains only one word
      // Then, add that word in lower case as a class to the table tag

      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(body, "text/html");
      const tables = htmlDoc.querySelectorAll("table");

      tables.forEach(table => {
        const caption = table.querySelector("caption");
        if (caption) {
          const captionText = caption.textContent.trim();
          if (captionText.split(" ").length === 1) { // Only if the caption is a single word
            table.classList.add(captionText.toLowerCase() + '-table');
          }
        } else {
          // If there is no caption, set "gallery-table" class if the first cell of the first row contains an image
          const rows = table.getElementsByTagName("tr");
          if (rows && rows.length > 0) {
            const columns = rows[0].getElementsByTagName("td");
            if (columns && columns.length > 0) {
              const images = columns[0].getElementsByTagName("img");
              if (images && images.length === 1) {
                table.classList.add('gallery-table');
              }
            }
          }

        }


        // Get all images in the table
        const images = table.getElementsByTagName("img");

        // Loop through each image
        for (let i = 0; i < images.length; i++) {
          // Create a new div container
          const div = document.createElement("div");
          div.classList.add('image-wrapper')
          // Insert the div container before the image in the DOM
          images[i].parentNode.insertBefore(div, images[i]);
          div.appendChild(images[i]);
          // Add the image to the div container
        }
      });

      body = htmlDoc.documentElement.innerHTML

      // Remove the first paragraph if it starts with an image
      if (this.post.data.body.slice(0, 7) === '<p><img')
        body = body.slice(body.indexOf("</p>") + 4);

      return body
    }
  },
  methods: {
    getTagParams(htmlString, tagName) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(htmlString, "text/html");
      const tag = htmlDoc.getElementsByTagName(tagName)[0];
      if (!tag) return null;
      const params = {};
      for (let i = 0; i < tag.attributes.length; i++) {
        const attr = tag.attributes[i];
        params[attr.name] = attr.value;
      }
      return params;
    },
    async getPost(postSlug) {
      if (this.postdata !== false) {
        this.post = this.postdata
        this.loaded = true
      } else {
        this.post = (
            await butter.post.retrieve(postSlug)
        ).data
        document.title = this.post.data.title + ' | Bayview Village'
        this.loaded = true
      }
    },
    async getRelated(postSlug) {
      const response = (
          await butter.post.list({
            page: 1,
            page_size: 10
          })
      ).data
      let result = []
      for (let i = 0; i < response.data.length; i++) {
        const post = response.data[i]
        if (post.slug === postSlug || post.categories.length === 0)
          continue;
        let r = {}
        r.title = post.title
        r.caption = post.summary
        r.link = {
          route: '/' + post.categories[0].slug.replace('bv-blog', 'blog') + '/' + post.slug,
          title: post.categories[0].name + ': ' + post.title
        }
        r.image = {
          src: post.featured_image,
          alt: post.featured_image_alt
        }
        result.push(r)
      }

      let randomPosts = [];

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * result.length);
        randomPosts.push(result[randomIndex]);
        result.splice(randomIndex, 1);
      }
      this.relatedPosts = randomPosts
    },
  },
  mounted() {
    this.getPost(this.$route.params.slug)
    this.getRelated(this.$route.params.slug)
  },
  watch: {
    '$route.params.slug': function () {
      this.getPost(this.$route.params.slug)
      this.getRelated(this.$route.params.slug)
    }
  }
}

</script>
<style lang="scss" scoped>
.article {
  text-align: left;

  &__header-image {
    @media (max-width: 575px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__info {
    text-transform: uppercase;
    margin-bottom: 20px;

    a:hover {
      text-decoration: none;
    }

    h1 {
      font-size: 2.4rem;
      margin-bottom: 0;
    }

    h2, .h2 {
      color: #D9017A;
      font-size: 1.3rem;
      font-family: Montserrat, Arial, sans-serif;
    }

    .article__date {
      font-size: 1.3rem;
    }
  }

  ::v-deep img {
    max-width: 100%;
  }

  ::v-deep table {
    table-layout: fixed;
    margin: 30px 0;

    &.gallery-table,
    &.products-table,
    &.features-table {
      border: none;

      colgroup, caption {
        display: none;
      }

      tr {
        @media (min-width: 992px) {
          display: flex;
        }
        margin-left: -15px;
        margin-right: -15px;
      }

      td {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
        padding: 0 15px;
        border: none;

        h2, h3 {
          text-decoration: none;
          text-transform: none;
          font-size: 1.3rem;
          color: #070707;
          padding: 0 15px;
          margin-top: 5px;
        }

        h3 {
          text-transform: uppercase;
          color: #d9017a;
        }

        .image-wrapper {
          position: relative;
          padding-bottom: 100%;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    &.products-table td .image-wrapper img {
      object-fit: contain;
    }
    &.features-table td {
      h3 {
        font-size: 1.8rem;
      }
      sub {
        font-size: 1.4rem;
      }
      .image-wrapper {
        padding-bottom: 125%;
      }
    }
  }

  ::v-deep ul {
    font-family: Cardo, serif;
    margin-bottom: 15px;

    li {
      text-align: left;
    }
  }

  ::v-deep &__related {
    h2, p {
      text-align: center !important;
    }

    @media (min-width: 992px) {
      .article-gallery__article:nth-child(5),
      .article-gallery__article:nth-child(6) {
        display: none;
      }
    }
  }

  &__author {
    border-top: 1px solid #E5E5E5;
    padding-top: 60px;
    margin-top: 30px;

    &-details {
      h2 {
        font-size: 2rem;
      }
    }

    &-image {
      padding: 0 60px;
    }
  }
}
</style>
