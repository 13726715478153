<template>
  <b-container tag="main" id="main" class="retailer py-0" v-if="retailer">
    <b-row class="mb-5">
      <b-col cols="12" lg="5">
        <div class="square-image retailer__image">
          <ImageObject :image="retailer.image"/>
        </div>
      </b-col>
      <b-col cols="12" lg="7">
        <b-row>
          <b-col cols="5">
            <div class="retailer__logo">
              <ImageObject :image="retailer.logo"/>
            </div>
          </b-col>
          <b-col cols="12" sm="7" class="retailer__details mt-5 mt-lg-0">
            <h1>{{ retailer.name }}</h1>
            <b-row class="mt-5">
              <b-col>
                <h2>Location</h2>
                <p class="mb-0">{{ retailer.location }}</p>
              </b-col>
              <b-col>
                <a class="map-button" href="#map">See on map</a>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col>
                <h2>Hours</h2>
                <p class="mb-0" v-html="retailer.hours"></p>
              </b-col>
            </b-row>
            <b-row class="mt-5" v-if="retailer.phone_number.length > 0">
              <b-col>
                <h2>Phone</h2>
                <p class="mb-0"><a :href="'tel:+1-' + retailer.phone_number.replaceAll('.', '-')">{{ retailer.phone_number }}</a></p>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col v-if="retailer.website">
                <h2>Website</h2>
                <p class="website"><a :href="'https://' + retailer.website" target="_blank">{{ retailer.website }}</a>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div v-html="retailer.description" class="mx-sm-5 mx-lg-0 mt-5"></div>
      </b-col>
    </b-row>
<!--    <b-row class="related my-md-5 mt-5" v-if="retailerProducts.length > 0">
      <b-col>
        <h2 class="retailer__gastronomer-heading mb-3 text-left">Featured products on<br>
          <router-link to="/gastronomer" title="Gastronomer">
            <img src="@/assets/gastronomer-logo.jpg" alt="Gastronomer Logo"
                 class="retailer__gastronomer-logo"/>
          </router-link>
        </h2>
        <Products :productsPerRow=4 :products="retailerProducts" class="px-0"/>
      </b-col>
    </b-row>-->
    <b-row id="map">
      <b-col>
        <DirectoryMap v-if="retailers.length > 0" :activeStoreLocation="retailer.location" :retailers="retailers"/>
      </b-col>
    </b-row>
  </b-container>
  <Loader v-else/>
</template>
<script>
import DirectoryMap from '@/components/DirectoryMap'
import Products from '@/components/Products'
import {mapState} from "vuex";
import {butter} from "@/buttercms";

export default {
  name: 'Retailer',
  /*components: {DirectoryMap, Products},*/
  components: {DirectoryMap},
  data() {
    return {
      alias: false,
      retailers: [],
      retailerData: {}
    }
  },
  computed: {
    ...mapState({
      products: state => state.products
    }),
    retailer: function () {
      if (this.retailerData.slug !== undefined) {
        let retailer = JSON.parse(JSON.stringify(this.retailerData || false))

        retailer.logo = {
          src: retailer.logo,
          alt: retailer.name + ' logo'
        }
        retailer.image = {
          src: retailer.featured_image,
          alt: retailer.featured_image_alt
        }

        return retailer
      }
      return false;
    },
    /*retailerProducts: function () {
      if (!this.retailer || this.products.length === 0)
        return false;

      let filteredProducts = this.products.filter(product =>
        product.vendors ? product.vendors.some(vendor =>
            vendor.alias === this.retailer.slug
        ) : false
      )

      return filteredProducts.slice(0,8)
    }*/
  },
  methods: {
    async initializeDirectory() {
      var params = {
        order: 'name'
        /*  "preview": 1,
          "fields.genre": "Rock",
          "page": 1,
          "page_size": 10,
          "locale": "en",
          "levels": 2*/
      };

      const response = (
          await butter.content.retrieve(['retailer'], params)
      ).data
      this.retailers = this.retailers.concat(response.data.retailer)
    },
    async initializeRetailer(alias) {
      var params = {
        order: 'name',
        'fields.slug': alias,
        /*  "preview": 1,
          "page": 1,
          "page_size": 10,
          "locale": "en",
          "levels": 2*/
      };

      const response = (
          await butter.content.retrieve(['retailer'], params)
      ).data
      this.retailerData = response.data.retailer[0]
      //await this.$store.dispatch('LOAD_API', {name: 'products', url: 'products?showsubs=true'})
    },
   /* initializeRetailer(alias) {
      let closure = this
      if (this.$store.state.retailers[alias] !== undefined) {
        this.alias = alias
      } else {
        this.$store.dispatch('LOAD_API', {
          name: 'retailers',
          id: alias,
          url: 'profile/' + alias
        }).then(function () {
          closure.alias = alias
          if (closure.products.length === 0) {
            closure.$store.dispatch('LOAD_API', {name: 'products', url: 'products?showsubs=true'})
          }
        })
      }
    }*/
  },
  created() {
    this.initializeRetailer(this.$route.params.id)
    this.initializeDirectory()
  },
  watch: {
    '$route.params.id': function (alias) {
      this.initializeRetailer(alias)
    }
  }
}
</script>
<style lang="scss" scoped>
.retailer {
  &__image {
    @media (max-width: 992px) {
      padding-bottom: 50% !important;
    }
    @media (max-width: 575px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__logo {
    border: 1px #E5E5E5 solid;
    padding-bottom: calc(100% - 2px);
    background: #FFFFFF;
    position: relative;

    img {
      position: absolute;
      top: 30px;
      left: 30px;
      right: 30px;
      bottom: 30px;
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      object-fit: contain;
      object-position: center;
    }

    @media (max-width: 767px) {
      img {
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
      }
    }

    @media (max-width: 992px) {
      margin-top: -25%;
    }

    @media (max-width: 576px) {
      margin-top: -50%;
    }

  }

  &__details {
    text-align: left;

    h1 {
      text-transform: uppercase;
      font-size: 2.4rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    .website a {
      color: #070707;
    }

    .map-button {
      color: #D9017A;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }

  ::v-deep p {
    font-size: 1.6rem;
  }

  &__gastronomer-heading {
    font-size: 1.6rem;
  }
  &__gastronomer-logo {
    width: 250px;
  }
}
</style>
