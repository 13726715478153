<template>
    <div class="modal-wrapper" v-if="modalOpen">
        <div class="modal-mask"></div>
        <div class="modal-box">
            <div class="modal-box__inner">
                <h2>Wishing you Spring blessings and new beginnings.</h2>
                <img :src="require('@/assets/gastronomer-popup-pockets.jpg')" alt="Chinese New Year Pink Pockets"/>
                <p>To celebrate Lunar New Year, all purchases on Gastronomer will receive a complimentary package of
                    exclusive BV pink pockets, while supplies last.</p>
                <p><small><i>No minimum purchase required. Applies to purchase of any item, not exclusive to Lunar New
                    Year products. One package per order.</i></small></p>
                <a href="#" v-on:click.prevent="dismissModal">Close</a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'GastronomerModal',
        data: function () {
            return {
                modalOpen: false
            }
        },
        methods: {
            dismissModal() {
                this.modalOpen = false
                window.localStorage.setItem('gastronomer-modal-cny', true)
            }
        },
        mounted() {
            // if (!window.localStorage.getItem('gastronomer-modal-cny'))
            //    this.modalOpen = true
        }
    }
</script>
<style lang="scss" scoped>
    .modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-box {
        width: 90%;
        max-width: 790px;

        &__inner {
            margin: 20px;
            padding: 20px;
            height: auto;
            background: #FFFFFF;

            h2 {
                font-size: 2.2rem;
                margin-bottom: 30px;
            }

            p {
                font-size: 1.6rem;
                text-align: center;
            }

            a {
                display: block;
                font-size: 2rem;
                font-family: Cardo, serif;
                margin-top: 15px;
                font-style: italic;
                clear: both;
            }

            img {
                @media (max-width: 767px) {
                    display: none;
                }
                float: left;
                width: 120px;
                margin: 0 30px 50px 0;
                @media (min-width: 968px) {
                    margin: 0 50px 0 10px;
                }
            }
        }

        @media (min-width: 465px) {
            &__inner {
                margin: 30px;
                padding: 30px;
            }
        }

        @media (min-width: 768px) {
            width: 65%;
            &__inner {
                p {
                    text-align: left;
                }
            }
        }
        background-image: url(../../assets/gastronomer-modal-background.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: #FFFFFF;
        border: 1px solid #e5e5e5;
        box-shadow: 0 20px 25px 0 rgb(0 0 0 / 30%);

    }

    form {
        input[type=text] {
            padding: 10px;
            font-family: Montserrat, sans-serif;
            font-size: 12px;
            width: 60%;
        }

        input[type=submit] {
            padding: 12px;
            font-size: 12px;
            text-transform: uppercase;
            font-family: Montserrat, sans-serif;
            -webkit-appearance: button;
            cursor: pointer;
            background: #e11776;
            border: 0;
        }
    }
</style>