<template>
  <b-container tag="main" id="main" class="media-room py-0">
    <CaptionBox
        :ratio=50
        :image="{
                   src: 'https://cdn.buttercms.com/VqdzWauxRk6dO7cCg0B8',
                   alt: 'An espresso, a magazine, pink flowers, and two pastries are shown on a grey table.'}"
        caption="Media Room"
        captionPosition="bottom-left"
        class="mb-5"/>
    <div class="media-room__description">
      <p>If you're looking for what's "haute off the press", you've come to the right place. Read about BV in the news
        and find out what we've been up to.</p>
    </div>
    <b-row class="media-room__grid" v-if="listings.length > 0">
      <b-col
          v-for="(article, index) in listings"
          :key="index"
          cols="6"
          :md="4"
          :lg="3"
          class="mb-4 media-room__article"
          tag="NavLink"
          :link="article.link">
        <CaptionBox
            :ratio=100
            :image=article.image
        />
        <h2 v-if="article.publisher" v-html="article.publisher"></h2>
        <p class="text-center" v-html="article.title"></p>
        <p class="text-center">{{ article.date }}</p>
      </b-col>
    </b-row>
    <Loader v-else/>
    <button v-if="nextPage" class="btn load-more" v-on:click="loadMore">Load more!</button>
  </b-container>
</template>
<script>
import {butter} from "@/buttercms";
export default {
  name: 'MediaRoom',
  data() {
    return {
      articles: [],
      page: 1,
      limit: 20,
      nextPage: 2,
    }
  },
  computed: {
    listings() {
      let articles = this.articles
      if (articles.length > 0) {
        for (let i = 0; i < articles.length; i++) {
          let article = articles[i]

          article.date = this.$moment(article.publish_date).format('MMMM D, YYYY')
          article.image = {
            src: article.featured_image,
            alt: article.image_alt
          }

          if (article.attachment) {
            article.link = {
              route: '/media/' + article.meta.id,
              title: article.title
            }
          } else if (article.url.length > 0) {
            article.link = {
              href: article.url,
              title: article.title
            }
          }
        }
      }
      return articles
    }
  },
  methods: {
    async initializePage() {
      const response = (
          await butter.content.retrieve(['media_article'], {
            order: '-publish_date',
            page: this.page,
            page_size: this.limit,
          })
      ).data
      this.articles = this.articles.concat(response.data.media_article)
      this.nextPage = response.meta.next_page
    },
    loadMore() {
      this.page += 1
      this.initializePage()
    }
  },
  created() {
    this.initializePage()
  }
}
</script>
<style lang="scss" scoped>
.media-room {
  &__article {
    color: #070707;

    &:hover {
      text-decoration: none;
    }

    h2 {
      margin: 10px 0;
      font-size: 1.4rem;
    }

    p {
      font-size: 1.3rem;
      font-family: Cardo, serif;
      line-height: 1.2em;
      margin-bottom: 5px;
    }
  }
}

.load-more {
  background-color: #d9017a;
  border: 0;
  border-radius: 7px;
  padding: .75em 1.5em;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.25em;
  font-weight: 700;
}
</style>
