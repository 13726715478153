<template>
    <main id="main" class="product pt-3">
        <router-link to="/" title="Return to Gastronomer"><img src="@/assets/gastronomer-logo.jpg"
                                                               alt="Gastronomer Logo"
                                                               class="product__store-logo mb-5 order-4 d-md-none order-lg-1 px-5"/>
        </router-link>
        <StoreFilters class="mb-5"/>
        <b-container tag="section" class="mb-5"
                     v-if="Object.keys(this.product).length !== 0 || this.product.constructor !== Object">
            <b-row>
                <b-col md="4">
                    <div class="product__image-container square-image">
                        <img :src="product.image | newurl(400)" class="product__image" :alt="'Product image of ' + product.title"/>
                        <!--<img :src="product.image.src" :alt="product.image.alt" class="product__image"/>-->
                    </div>
                </b-col>
                <b-col md="6" class="mt-5 mt-lg-0">
                    <div v-if="selectedVariantPrice" class="product__price mb-5 float-right mr-5">
                      {{selectedVariantPrice | currency}}
                    </div>
                    <h1 class="product__title">{{product.title}}</h1>
                    <h2 class="product__vendor mb-5">
                        <span v-for="(vendor, index) in product.vendors" :key="index">
                            <span v-if="index > 0">&nbsp;&#8226;&nbsp;</span>{{vendor.title}}</span>
                    </h2>
                    <div class="product__description mb-5" v-html="product.content"></div>
                    <div class="product__variants mb-5 text-left" v-if="filteredVariants">
                        <label for="item_size" class="mr-3">Select an option:</label>
                        <select id="item_size" v-model="selectedVariantId">
                            <option v-for="subsidiary in filteredVariants" :key="subsidiary.id"
                                    :value="subsidiary.id">{{subsidiary.value}}
                            </option>
                        </select>
                    </div>
                </b-col>
                <b-col md="2" class="d-flex flex-column">
                    <div class="product__quantity mb-4 d-flex order-1 order-lg-3">
                        <span v-on:click="changeQty(-1)">-</span>
                        <input type="number" v-model.number="productQty" min="1" name="item_quantity" aria-label="Quantity"/>
                        <span v-on:click="changeQty(1)">+</span>
                    </div>
                    <button class="product__cart-button mb-5 order-2 order-lg-4" v-if="selectedVariantId"
                            v-on:click="handleAddToCart()">
                        Add to Cart
                    </button>
                    <router-link :to="'/store/' + vendor.alias" class="vendor__image-container mb-5 order-3 order-lg-2"
                         v-for="(vendor, index) in product.vendors" :key="index">
                        <div class="square-image">
                            <!--<img :src="product.vendor.image.src" :alt="product.vendor.image.alt"
                                 class="vendor__image"/>-->
                            <img :src="vendor.image" :alt="vendor.title + ' logo'" class="vendor__image"/>
                        </div>
                    </router-link>
                    <img src="@/assets/gastronomer-logo.jpg" alt="Gastronomer Logo"
                         class="product__store-logo mb-5 order-4 d-none d-md-block order-lg-1"/>
                    <router-link to="/gastronomer" class="product__back-link text-center order-5">Back to Shop
                    </router-link>
                </b-col>
            </b-row>
        </b-container>
        <b-container tag="section" class="related">
            <h2 class="related__title mb-5">You might also like</h2>
            <Products :productsPerRow=4 :products="suggestedProducts"/>
        </b-container>
    </main>
</template>
<script>
    import StoreFilters from '@/components/StoreFilters'
    import Products from '@/components/Products'
    import {mapState} from 'vuex'

    export default {
        name: 'Product',
        components: {
            StoreFilters, Products
        },
        data() {
            return {
                productQty: 1,
                variants: {},
                selectedVariantId: false
            }
        },
        computed: {
            ...mapState({
                products: state => state.products,
                vendors: state => state.vendors,
                product: state => state.activeProduct
            }),
            selectedVariantPrice() {
                if (Object.keys(this.product).length === 0)
                    return false;

                if (!this.selectedVariantId || this.selectedVariantId === this.product.id)
                    return this.product.item_price

                else if (this.products.length > 0) {
                    let productFilter = this.products.filter(obj => {
                        return obj.id === this.selectedVariantId
                    })
                    return productFilter[0].item_price
                } else
                    return false
            },
            filteredVariants() {
                if (Object.keys(this.product).length === 0 || !this.product.subsidiaries)
                    return false;

                let variants = JSON.parse(JSON.stringify(this.product.subsidiaries))
                variants.push(JSON.parse(JSON.stringify(this.product)))

                let filteredVariants = []
                for (let i = 0; i < variants.length; i++) {
                    let variant = variants[i]
                    let value = []
                    if (variant.item_size) value.push(variant.item_size)
                    if (variant.item_flavour) value.push(variant.item_flavour)
                    filteredVariants.push({
                        id: variant.id,
                        value: value.join(' - ')
                    })
                }
                return filteredVariants.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
            },
            suggestedProducts() {
                let suggested = []
                if (this.products.length > 0) {
                    let i = 0
                    let attempts = 0
                    let productsCopy = JSON.parse(JSON.stringify(this.products))
                    productsCopy = this.products.filter(product => product.is_subsidiary === undefined) // Filter out subsidiaries
                    while (i < 4 && attempts < 10) {
                        if (productsCopy.length === 0)
                            break;
                        let random = productsCopy.splice(Math.floor(Math.random() * productsCopy.length), 1)[0]
                        if (random.id !== this.product.id) {
                            suggested.push(random)
                            i++
                        }
                        attempts++
                    }
                }
                return suggested
            }
        },
        methods: {
            loadProduct(id) {
                let closure = this
                this.$store.dispatch('LOAD_API', {name: 'activeProduct', url: 'product/' + id}).then(function () {
                    closure.selectedVariantId = closure.product.id
                })
            },
            handleAddToCart() {
                this.$store.commit('ADD_TO_CART', {
                    value: this.productQty,
                    id: this.selectedVariantId,
                    increment: true
                })
                this.$store.commit('SET_STATE', {name: 'cartDrawerOpen', data: true});
                this.productQty = 1;
                if (this.$store.state.userNavigatingByTab === true)
                    document.getElementById('cart-close-button').focus();
            },
            changeQty(amount) {
                if (this.productQty + amount > 0) {
                    this.productQty = this.productQty + amount
                }
            }
        },
        created() {
            if ((Object.keys(this.product).length === 0 && this.product.constructor === Object)
                || this.product.id !== this.$route.params.id) {
                this.loadProduct(this.$route.params.id)
            }
            if (this.products.length === 0) {
                this.$store.dispatch('LOAD_API', {name: 'products', url: 'products?showsubs=true'})
            }
            if (this.vendors.length === 0) {
                this.$store.dispatch('LOAD_API', {name: 'vendors', url: 'vendors?filter=ecommerce&sort=title'});
            }
        },
        mounted() {
            if (Object.keys(this.product).length > 0)
                this.selectedVariantId = this.product.id
        },
        watch: {
            '$route.params.id': function (id) {
                this.loadProduct(id)
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .product {
        &__image {
            object-fit: cover;
        }

        &__title {
            text-align: left;
        }

        &__vendor {
            text-align: left;
            text-transform: uppercase;
        }

        &__description {
            font-size: 1.6rem;
            text-align: left;
        }

        &__variants {
            select {
                padding: 5px 10px;
                border-radius: 5px;
            }
        }

        &__price {
            text-align: left;
            font-size: 2rem;
        }

        &__quantity {
            width: 100%;
            border: 1px solid #E5E5E5;
            border-radius: 0.75rem;
            user-select: none;
            cursor: pointer;


            input {
                border: 0;
                font-size: 1.7rem;
                width: 33.33%;
                text-align: center;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            span {
                text-align: center;
                font-size: 3rem;
                color: #888888;
                width: 33.33%;
            }
        }

        &__cart-button {
            background-color: #D9017A;
            border: 0;
            padding: 0.75em;
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 1.7rem;
            line-height: 1.25em;
            font-weight: bold;
            width: 100%;
            border-radius: 0.75rem;
            user-select: none;
        }

        &__back-link {
            display: block;
            color: #D9017A;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            font-size: 1.2rem;
            text-decoration: none;

            &::before {
                content: '<';
                margin-right: 0.4rem;
            }
        }

        &__store-logo {
            max-width: 100%;
        }
    }

    .vendor {
        &__image-container {
            border: 1px solid #E5E5E5;
            padding: 1.5em;
        }

        &__image {
            object-fit: contain;
        }
    }

    .related {
        &__title {
            text-transform: uppercase;
            font-weight: bold;
        }
    }
</style>"