//import template from '@/config/template'
import Vue from 'vue'
import {updateField} from 'vuex-map-fields'
import getDefaultState from './state'

export default {
    updateField,
    SET_USER(state, user) {
        state.user = user
    },
    SET_TOKEN(state, token) {
        state.token = token
    },
    SET_STATE(state, payload) {
        if (payload.name !== undefined && payload.data !== undefined) {
            if (payload.id !== undefined) {
                if (!state[payload.name] || state[payload.name] === undefined) { // If the state has not been instantiated
                    Vue.set(state, payload.name,{}) // we instantiate the state.
                }
                Vue.set(state[payload.name], payload.id,payload.data)
            } else {
                Vue.set(state, payload.name,payload.data)
            }
        }
    },
    RESET_STATE(state, stateObject) {
        Object.assign(state[stateObject], getDefaultState()[stateObject])
    },
    RESET_FORM_STATE(state, formName) {
        Object.assign(state.formData[formName], getDefaultState().formData[formName])
    },
    /**
     * Set Form State (SET_FORM_STATE)
     * Set the state that is mapped to form values
     **/
    SET_FORM_STATE(state, payload) {
        let formState = {} // The state that will be set representing values on the form
        if (payload.name !== undefined) { // The payload must include the parameter "name", which is the name of the form in question
            this.commit('RESET_FORM_STATE', payload.name) // Reset form contents back to initial state
            if (payload.data !== undefined) { // If we are passing a data object directly
                formState = payload.data // place the data directly into the form state assuming it's already been vetted.
            } else if (payload.state !== undefined) { // However, if we are acquiring form values from an existing state
                for (let prop in state.formData[payload.name]) { // loop through default state elements for the form
                    if (Object.prototype.hasOwnProperty.call(state.formData[payload.name], prop)) {
                        if (payload.id !== undefined && payload.id.length > 0 && // If ID is set
                            state[payload.state][payload.id][prop] !== undefined) {  // and state elements are set
                            formState[prop] = state[payload.state][payload.id][prop] // set the state with the Id
                        } else if (state[payload.state][prop] !== undefined) { // Otherwise, check that the state element is set without Id
                            formState[prop] = state[payload.state][prop] // set the form state without Id
                        }
                        // TODO: Remove this and accommodate multiple selection text fields and return arrays properly
                        if (prop === 'categories' && Array.isArray(formState.categories)) { // If the field is "categories"
                            let Categories = []
                            for (let i = 0; i < formState.categories.length; i++) { // Loop categories
                                Categories.push(formState.categories[i].title) // extract titles and and merge them into a single comma separated string
                            }
                            formState.categories = Categories.join()
                        }
                    }
                }
            }
            let form = document.getElementById(payload.name)
            let stateValue = JSON.parse(JSON.stringify(formState)) // The form state object is a copy, not a reference, so the initial state is preserved.
            if (form.dataset.subject !== undefined && form.dataset.subject.length > 0) {
                if (state.formData[payload.name] === undefined) { // If the state for this form has not been instantiated
                    state.formData[payload.name] = {} // we instantiate the state.
                }
                state.formData[payload.name][payload.id] = stateValue
            } else {
                for (var prop in stateValue) {
                    if (Object.prototype.hasOwnProperty.call(stateValue, prop)) {
                        state.formData[payload.name][prop] = stateValue[prop]
                    }
                }
            }
        }
    },
    SET_FIELD_STATE(state, payload) {
        let d = {}
        if (payload.target) { // Is this an event
            d.form = payload.target.form.id
            d.field = payload.target.name
            d.value = payload.target.value
            if (payload.target.form.dataset.subject !== undefined && payload.target.form.dataset.subject.length > 0) {
                d.subject = payload.target.form.dataset.subject
            }
        } else { // Otherwise we're passing params as a simple object
            d = payload
        }
        if (d.form && d.field && d.value !== undefined) { // If Form Id, field name, and value are set
            if (state.formData[d.form] === undefined) {
                state.formData[d.form] = {}
            }
            if (d.subject) { // If a subject ID has been set
                if (state.formData[d.form][d.subject] === undefined) { // If the state for this form + ID has not been instantiated
                    state.formData[d.form][d.subject] = {} // we instantiate the state.
                }
                state.formData[d.form][d.subject][d.field] = d.value
            } else {
                state.formData[d.form][d.field] = d.value
            }
        }
    },
    ADD_TO_CART(state, payload) {
        if (payload.value === '')
            Vue.delete(state.cart.items, payload.id)
        else if (payload.increment !== undefined &&
            payload.increment === true && state.cart.items[payload.id] !== undefined)
            Vue.set(state.cart.items, payload.id, state.cart.items[payload.id] + payload.value)
        else
            Vue.set(state.cart.items, payload.id, payload.value)
        this.commit('UPDATE_CART')
        Vue.set(state.formData.orderForm, 'pickup_date', '')
        Vue.set(state.formData.orderForm, 'pickup_time', '')
    },
    ADD_TO_WISHLIST(state, payload) {
        state.wishlist.push(payload)
        console.log(state.wishlist)
    },
    REMOVE_FROM_WISHLIST(state, payload) {
        const indexToRemove = state.wishlist.findIndex(obj => {
            return obj.title === payload.title && obj.vendor === payload.vendor;
        });

        if (indexToRemove !== -1) {
            state.wishlist.splice(indexToRemove, 1);
        }
        console.log(state.wishlist)
    },
    SET_FILTER(state, payload) {
        if (payload.name !== undefined && payload.value !== undefined) {
            let stateName = 'shopFilters'
            if (payload.filter !== undefined) {
                stateName = payload.filter
            }
            if (Array.isArray(state[stateName][payload.name])) {
                let index = state[stateName][payload.name].indexOf(payload.value)
                if (index < 0) {
                    state[stateName][payload.name].push(payload.value)
                } else if (payload.toggle === true) {
                    state[stateName][payload.name].splice(index, 1)
                }
            } else {
                if (state[stateName][payload.name] !== payload.value) {
                    state[stateName][payload.name] = payload.value
                } else {
                    state[stateName][payload.name] = false
                }
            }
        }
    },
    RESET_FILTERS(state, payload) {
        let stateName = 'shopFilters'
        if (payload && payload.filter !== undefined) {
            stateName = payload.filter
        }
        this.commit('RESET_STATE', stateName)
    },
    FAVOURITE_ITEM(state, payload) {
        if (payload.value === '') {
            delete state.user_favourites[payload.id]
        } else if (payload.increment !== undefined &&
            payload.increment === true && state.user_favourites[payload.id] !== undefined) {
            state.user_favourites[payload.id] = state.user_favourites[payload.id] + payload.value
        } else {
            state.user_favourites[payload.id] = payload.value
        }
    },
    UPDATE_CART(state) {
        let computed = {}
        let itemsQuantity = 0
        let productsQuantity = 0
        let subtotal = 0
        let taxTotal = 0

        for (let i = 0; i < state.products.length; i++) {
            let product = state.products[i]
            if (state.cart.items[product.id] !== undefined) {
                productsQuantity++
                let pieces = parseFloat(state.cart.items[product.id])
                itemsQuantity += pieces
                let price = product.item_price
                subtotal += price * pieces
                taxTotal += parseFloat(product.item_tax) * pieces
            }
        }

        computed.itemsTotal = RoundCurrency(subtotal)
        computed.discountPercent = DiscountRate(state, itemsQuantity)
        computed.discountTotal = RoundCurrency(computed.itemsTotal * (computed.discountPercent / 100))
        computed.subtotal = RoundCurrency(computed.itemsTotal - computed.discountTotal)
        computed.taxTotal = RoundCurrency(taxTotal)
        // computed.taxTotal = RoundCurrency(computed.subtotal * (template.storeSettings.taxes[0].percent / 100))
        computed.itemsQuantity = itemsQuantity
        computed.productsQuantity = productsQuantity
        computed.totalValue = computed.subtotal + computed.taxTotal

        state.cart.totals = computed
        localStorage.setItem('cartItems', JSON.stringify(state.cart.items))
    },
    TOGGLE_ORDER_PREVIEW(state, active = null) {
        if (active !== null) {
            state.state.cart.totals.preview = active
        } else {
            state.state.cart.totals.preview = !state.state.cart.totals.preview
        }
    },
    RESET_ORDER_FORM() {
        this.commit('RESET_STATE', 'activeOrder')
    }
}

function RoundCurrency(value) {
    return parseFloat(Math.round(value * 100) / 100)
}

/* function findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i]
        }
    }
    return null
} */

function DiscountRate(state, quantity) {
    let DiscountRate = 0
    /*
        if (state.activeOrder.coupon !== false && state.activeOrder.coupon.type === 'discount' &&
            state.activeOrder.coupon.discount && state.activeOrder.coupon.threshold < quantity + 1) {
            DiscountRate = state.activeOrder.coupon.discount
        }
    */
    if (state.discounts && state.discounts.children !== undefined) {
        for (let i = 0; i < state.discounts.children.length; i++) {
            let discount = state.discounts.children[i]
            if (discount.alias === undefined && discount.threshold < quantity + 1 && discount.discount > DiscountRate) {
                DiscountRate = discount.discount
            }
        }
    }
    return DiscountRate
}
