<template>
  <b-container tag="main" id="main" class="giftguide">
    <b-row id="gallery">
      <b-col cols="12" class="mb-5">
        <CaptionBox
            :ratio="50"
            :image="{
                            src: require('@/assets/giftguide/for-'+ headerImage +'-header.jpg'),
                            alt: 'Holiday Gift Guide header image showing a holiday table setting'
                        }"
            captionTag="div"
            captionMaxWidth="50%"
            caption="<h1>Haute Holiday Gift Guide</h1><p>'Twas not long before Christmas and all through BV, the boutiques were full of goodies as haute as can be.  With gifts for her, for him, for littles and foodies; for home and especially lovers of beauty.  Our list is just the beginning, a curated collection; visit BV boutiques for an even wider selection. We’ve got gifts for all on your list, bottom to top; off to BV you go to shop, shop and shop!</p>"
        ></CaptionBox>
      </b-col>
    </b-row>
    <b-row tag="ul" class="filter__row mb-5" id="filters">
      <b-col tag="li" cols="12" sm="6" :md="true" class="filter__button"
             v-for="(page, alias) in pages" :key="alias">
        <router-link :to="'/giftguide/' + alias" :title="page.alt">{{ page.title }}</router-link>
      </b-col>
    </b-row>
    <b-row :class="['content-wrapper', alias, {'active': $route.params.filter === alias}]"
           v-for="(page, alias) in pages"
           :key="alias">
      <b-col cols="12" class="intro-text">
        <img :src="require('@/assets/giftguide/' + alias + '-title.png')"
             :alt="'A title in cursive for the '+ page.title + ' collection'"/>
        <p v-html="page.description"></p>
        <p class="scroll-notice"><i class="fa fa-arrows"></i><br>Swipe to view.</p>
      </b-col>
      <b-col cols="12" class="scrollable-container">
        <div class="scrollable">
          <div class="video">
            <img
                :src="require('@/assets/giftguide/' + alias + '.gif')"
                :alt="page.alt"/>
            <!--    <video playsinline autoplay loop muted>
                      <source :src="require('@/assets/giftguide/for-her-video.mp4')" type="video/mp4">
                      &lt;!&ndash; @/assets/giftguide/' + alias + '-video.mp4&ndash;&gt;
                      Your browser does not support the video tag.
                    </video>-->
          </div>
          <div class="overlay">
            <img
                :src="require('@/assets/giftguide/' + alias + '-transparent.png')"
                :alt="page.alt"
                class="page-img transparent"/>
            <!--          <img
                          :src="require('@/assets/giftguide/' + alias + '-static.png')"
                          :alt="page.alt"
                          class="page-img static"/>-->
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import holidayJSON from '@/temp/giftguide.json'

export default {
  name: 'GiftGuide',
  data() {
    return {
      pages: holidayJSON
    }
  },
  computed: {
    headerImage() {
      const date = new Date
      const day = date.getDate()
      let headerImage = 'home'
      if(day > 19) {
        headerImage = 'foodies'
      } else if (day > 12) {
        headerImage = 'beauty'
      }
      return headerImage
    }
  },
  watch: {
    $route() {
      this.ScrollToFilters()
    }
  },
  mounted() {
    if (!this.$route.params.filter) {
      this.$router.push('/giftguide/for-her')
    }
  },
  methods: {
    ScrollToFilters() {
      // Scroll to filters
      const filterBlock = document.getElementById('filters')
      window.scrollTo(0, filterBlock.offsetTop)
    }
  }
}

</script>
<style lang="scss" scoped>
@import '../utils/global.scss';

.giftguide {

  ::v-deep {
    @media (max-width: 767px) {
      .caption-box__image {
        position: relative;
      }
      .caption-box__inner {
        padding-bottom: 0 !important;
      }
    }

    .caption-box__caption {
      @media (max-width: 767px) {
        max-width: 100% !important;
        width: 100% !important;
        position: relative;
      }

      h1 {
        text-align: left;
        font-size: 1.6rem;
        @media (min-width: 768px) {
          font-size: 2rem;
        }
      }

      p {
        text-transform: none;
        text-align: left;
        font-size: 1.4rem;
        margin: 0;
      }

    }
  }

  .intro-text {
    padding-bottom: 2.5rem;

    img {
      margin: 0 auto 2rem;
      height: 75px;
    }

    p {
      text-align: center;
      margin: auto;
      @media (max-width: 991px) {
        ::v-deep br {
          display: none;
        }
      }
    }
  }

  .scroll-notice {
    margin-top: 2rem;
    font-weight: bold;
    display: none;
    text-align: center;
    @media (max-width: 991px) {
      display: block;
    }
  }

  .content-wrapper {
    position: relative;
    display: none;
    transition: .3s;

    &.active {
      display: block;
    }

    .scrollable-container {
      width: 100%;
      overflow-x: scroll;
      position: relative;

      .scrollable {
        position: relative;
        @media (max-width: 991px) {
          width: 125%;
          max-width: 125%;
        }

        @media (max-width: 767px) {
          width: 200%;
          max-width: 200%;
        }

        @media (max-width: 575px) {
          width: 250%;
          max-width: 250%;
        }
      }
    }

    .page-img {
      width: 100%;
    }

    .video {
      /*display: none;
      @media (min-width: 768px) {
        display: block;
      }*/
      margin: auto;
      position: relative;
      width: 53%;
      padding-bottom: 100%;

      video, img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    /*.overlay .static {
      display: block;
    }

    .overlay .transparent {
      display: none;
    }*/

    /*@media (min-width: 768px) {*/
    .overlay {
      /* .static {
         display: none;
       }

       .transparent {
         display: block;
       }*/

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /*}*/
  .for-littles .overlay {
    top: 2.5%;
  }

  .for-littles .video {
    margin-bottom: 2.5%;
  }

  .for-him .overlay {
    margin-top: 3%;
  }

  .for-foodies .overlay {
    margin-top: 3%;
  }

  .filter {
    &__row {
      list-style: none;
      padding: 0;
    }

    &__button {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: #070707;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 0.75em;
        text-decoration: none;

        @include media-breakpoint-only(xs) {
          &:not(:first-child) {
            border-top: none;
          }
        }

        @include media-breakpoint-only(sm) {
          &:not(:first-child):not(:nth-child(2)) {
            border-top: none;
          }
          &:nth-child(odd) {
            border-right: none;
          }
        }

        @include media-breakpoint-up(md) {
          font-size: 1.3rem;
          &:not(:first-child) {
            border-left: none;
          }
        }
      }

      &:hover a:not(.router-link-exact-active) {
        background: #F3F3F3;
      }

      .router-link-exact-active {
        background: #D9017A;
        color: #FFFFFF;
      }
    }
  }
}
</style>
