<template>
  <b-container tag="main" id="main" class="lookbook" v-if="page.fields !== undefined">
    <b-row class="mb-5">
      <b-col id="lookbook">
        <VueSlickCarousel
            :class="['slider',{zoomed: zoom}]"
            ref="carousel"
            :slidesPerRow="this.zoom ? 1 : 2"
            v-bind="settings"
            v-if="page.fields.image_list && page.fields.image_list.length > 0">
          <CaptionBox class="slide d-none d-lg-inline-block" :ratio="ratio" v-if="!zoom"/>
          <div class="slide" v-for="(image, index) in page.fields.image_list" :key="index">
            <CaptionBox :image="{ src: image.image, alt: image.image_alt }" :ratio="ratio"/>
          </div>
          <CaptionBox class="slide d-none d-lg-inline-block" :ratio="ratio" v-if="!zoom"/>
        </VueSlickCarousel>
        <div class="slider-controls d-none d-lg-block">
          <i :class="['fa', zoom ? 'fa-search-minus' : 'fa-search-plus']" v-on:click="zoom = !zoom"></i>
        </div>
      </b-col>
    </b-row>
    <h1 class="mb-3">{{ page.title }}</h1>
    <b-row class="mb-5">
      <b-col cols="6" sm="4" md="3" lg="2"
             v-for="(image, index) in page.fields.image_list"
             :key="index"
             class="thumbnail mb-5">
        <a href="#lookbook" v-on:click="goToPage(index + 1)"
           class="thumbnail__image-container mb-5"
           :title="'Go to page ' + (index+1)">
          <div :class="['square-image', {'ratio-160': ratio === 160}]">
            <ImageObject :image="{src: image.thumbnail, alt: image.image_alt}" class="product__image"/>
          </div>
        </a>
      </b-col>
    </b-row>
    <h1 class="mb-3">Lookbooks</h1>
    <b-row>
      <b-col cols="6" sm="4" md="3" lg="2"
             v-for="(lookbook, index) in archive"
             :key="index"
             class="thumbnail mb-5">
        <NavLink :link="{ route: '/magazine/' + lookbook.slug, title: lookbook.fields.seo.title }"
                 class="thumbnail__image-container mb-5">
          <div class="square-image">
            <ImageObject :image="{ src: lookbook.fields.thumbnail, alt: lookbook.fields.thumbnail_alt}"
                         class="product__image"/>
          </div>
          <h2>{{ lookbook.name }}</h2>
        </NavLink>
      </b-col>
    </b-row>
  </b-container>
  <Loader v-else/>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {butter} from "@/buttercms";

export default {
  name: 'Lookbook',
  components: {VueSlickCarousel},
  data() {
    return {
      archive: [],
      zoom: false,
      settings: {
        arrows: true,
        dots: true,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesPerRow: 1,
              dots: false,
            }
          }
        ]
      }
    }
  },
  computed: {
    ratio() {
      console.log(this.page.slug)

      return (this.page.slug && this.page.slug === 'jetsetters-guide') ? 160 : 130
    },
    page() {
      if (this.archive.length === 0)
        return {}

      if (this.$route.params.id) {
        return this.archive.find(obj => obj.slug === this.$route.params.id) || {}
      }

      return this.archive[0]
    }
  },
  methods: {
    async initializeArchive() {
      const response = (
          await butter.page.list('magazine', {levels: 1, order: '-publish_date', page_size: 50})
      ).data

      this.archive = response.data
    },
    goToPage(page) {
      let slide = !this.zoom ? Math.floor(page / 2) : page - 1
      this.$refs.carousel.goTo(slide)
    }
  },
  created() {
    this.initializeArchive()
  },
  watch: {
    '$route.params.id': function () {
      this.zoom = false
      this.goToPage(1)
    }
  }
}
</script>
<style lang="scss" scoped>

.lookbook {
  position: relative;

  h1 {
    text-align: left;
    text-transform: uppercase;
  }

  ::v-deep .slider.zoomed .slick-slide {
    overflow-y: scroll;
  }
}

.slider-controls {
  width: 50px;
  position: absolute;
  right: 15px;
  top: 0;
  background: #fff;
  z-index: 5;
  opacity: .3;
  text-align: center;
  transition: .5s;
  cursor: pointer;
  padding: 5px;

  i {
    font-size: 30px;
    margin: 5px 0;
  }
}

.thumbnail {
  /* Display 5 columns on >= lg to make 2 rows of 5 */
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  h2 {
    text-align: left;
    color: #000000;
    margin-top: 10px;
  }

  &__image-container {
    overflow: hidden;

    .square-image {
      transition: 0.2s;

      img {
        object-position: center top;
      }
    }

    &:hover {
      .square-image {
        z-index: 10;
        margin-top: -15%;
        padding-bottom: 130%;
        margin-bottom: -15%;
        transform: scale(1.1);
        box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.25);

        &.ratio-160 {
          margin-top: -30%;
          padding-bottom: 160%;
          margin-bottom: -30%;
        }
      }
    }
  }
}

</style>
