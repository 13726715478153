<template>
  <section class="list">
    <NavLink v-for="(item, index) in list.slice(0, limit)"
             :key="index"
             :link="item.link || null"
             class="row list__item">
      <b-col cols="4" class="list__thumbnail">
        <div class="square-image list__thumbnail-image">
          <ImageObject :image="item.image"/>
        </div>
      </b-col>
      <b-col cols="8" class="list__details text-left">
        <h2>{{ item.title }}</h2>
        <h3 v-if="item.start" class="list__date mb-5">{{ item.start }} <span v-if="item.end"> - {{ item.end }}</span>
        </h3>
        <h3 v-if="item.vendors" class="list__date mb-5">
          <router-link v-for="(vendor, index) in item.vendors" :to="'/store/' + vendor.alias"
                       :title="vendor.title" :key="index">{{ vendor.title }}
          </router-link>
        </h3>
        <p v-if="item.content" class="list__description d-none d-sm-block" v-html="item.content"></p>
        <p v-else-if="item.description"
           class="list__description d-none d-sm-block"
           v-html="item.description"
           @click="handleContainerClick"
        >
        </p>
        <div v-if="cta" class="list__cta d-none d-sm-block">{{ cta }}</div>
      </b-col>
    </NavLink>
    <button v-if="loadLimit && limit < list.length" class="btn load-more" v-on:click="loadMore">Load more!</button>
  </section>
</template>
<script>
export default {
  name: 'List',
  props: {
    list: Array,
    cta: String,
    loadLimit: Number
  },
  data() {
    return {
      limit: this.loadLimit || 9999
    }
  },
  methods: {
    loadMore() {
      this.limit += this.loadLimit
    },
    handleContainerClick(event) {
      const target = event.target
      if (target.tagName.toLowerCase() === 'a') {
        event.stopPropagation()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.list {

  &__item:hover {
    text-decoration: none;
  }

  &__item:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;

    @media (min-width: 768px) {
      padding-bottom: 48px;
      margin-bottom: 48px;
    }

    @media (min-width: 992px) {
      padding-bottom: 60px;
      margin-bottom: 60px;
    }
  }

  &__thumbnail {

    @media (min-width: 992px) {
      padding: 0 60px;
    }

    img {
      width: 100%;
    }
  }

  &__details {
    color: #070707;

    h2 {
      font-size: 1.9rem;
      @media (min-width: 768px) {
        font-size: 2.4rem;
      }
    }

    h3 {
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }

  &__cta {
    color: #D9017A;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
}

.load-more {
  background-color: #d9017a;
  border: 0;
  border-radius: 7px;
  padding: .75em 1.5em;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.25em;
  font-weight: 700;
}
</style>
