<template>
    <b-container tag="section" class="filters" id="filters">
        <b-row tag="ul" class="filter__row no-gutters">
            <b-col tag="li" cols="12" sm="6" :md="true" v-on:mouseover="showVendorSubmenu = true"
                   v-on:mouseleave="showVendorSubmenu = false" v-on:click="showVendorSubmenu = !showVendorSubmenu"
                   :class="['filter__button', 'vendor-button', {active: active === 'vendor'}]">
                <a href="#filters">Shop by Vendor</a>
                <b-container tag="ul" class="dropdown" v-show="showVendorSubmenu">
                    <b-row tag="li" v-for="(vendor) in vendors" :key="vendor.id" class="dropdown__item">
                      <b-col tag="router-link" :to="'/gastronomer/'+ (vendor.alias || vendor.id) + '/#filters'">
                        <b-row>
                          <b-col cols="3">
                              <div class="dropdown__image square-image">
                                  <!--<img :src="vendor.image.src" :alt="vendor.image.alt"/>-->
                                  <img :src="vendor.image" :alt="vendor.title + ' logo'"/>
                              </div>
                          </b-col>
                          <b-col cols="9" class="dropdown__description text-left">
                              {{vendor.title}}
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                </b-container>
            </b-col>
            <b-col tag="li" cols="12" sm="6" :md="true" v-on:mouseover="showThemeSubmenu = true"
                   v-on:mouseleave="showThemeSubmenu = false" v-on:click="showThemeSubmenu = !showThemeSubmenu"
                   :class="['filter__button', 'theme-button', {active: active === 'theme'}]">
                <a href="#filters">Shop by Theme</a>
                <b-container tag="ul" class="dropdown" v-show="showThemeSubmenu">
                    <b-row tag="li" v-for="taxonomy in sortedTaxonomies" :key="taxonomy.id" class="dropdown__item">
                        <b-col class="dropdown__description">
                            <router-link :to="'/gastronomer/' + taxonomy.alias + '/#filters'">{{taxonomy.title}}</router-link>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
            <b-col tag="li" cols="12" sm="6" :md="true"
                   :class="['filter__button', 'how-it-works-button', {active: active === 'how-it-works'}]">
                <router-link to="/gastronomer/how-it-works/#filters">How it Works</router-link>
            </b-col>
        </b-row>
        <!--<b-row tag="ul" class="filter__row filter__submenu no-gutters mt-5">
            <b-col tag="li" v-for="category in categories" :key="category"
                   :class="['filter__button', {active: active === category}]">
                <router-link :to="'/'+category">{{category}}</router-link>
            </b-col>
        </b-row>-->
    </b-container>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: 'StoreFilters',
        props: {
            active: {
                type: String,
                default: null
            }
        },
        data: function () {
            return {
                showVendorSubmenu: false,
                showThemeSubmenu: false
            }
        },
        computed: {
            ...mapState({
                vendors: state => state.vendors,
                allTaxonomies: state => state.product_taxonomies,
            }),
            sortedTaxonomies() {
                let taxonomies = this.allTaxonomies.filter(obj => {
                    return obj.id !== 'other'
                })
                taxonomies.push({
                    id: 'other',
                    alias: 'other',
                    title: 'Other'
                })
                return taxonomies
            }
        },
        created() {
            if (this.allTaxonomies.length === 0) {
                this.$store.dispatch('LOAD_API', {name: 'product_taxonomies', url: 'taxonomies/item'});
            }
        }
    }

</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .filter {
        &__row {
            list-style: none;
            padding: 0;
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            text-transform: uppercase;
            color: #070707;
            border: 1px solid #E5E5E5;
            cursor: pointer;

            @include media-breakpoint-only(xs) {
                &:not(:first-child) {
                    border-top: none;
                }
            }

            @include media-breakpoint-only(sm) {
                &:not(:first-child):not(:nth-child(2)) {
                    border-top: none;
                }
                &:nth-child(odd) {
                    border-right: none;
                }
            }

            @include media-breakpoint-up(md) {
                &:not(:first-child) {
                    border-left: none;
                }
            }

            &:not(.filter__search) {
                &:hover {
                    background: #F3F3F3;
                }

                &.active {
                    background: #E5E5E5;
                }
            }

            a {
                color: #070707;
                display: block;
                width: 100%;
                height: 100%;
                padding: 0.5em;
                text-decoration: none;
            }

            input {
                border: none;
                padding: 0.75em;
                text-transform: uppercase;
                text-align: center;
                height: 100%;
                width: 100%;
                outline: 0;
            }

            .dropdown {
                position: absolute;
                top: calc(100% + 1px);
                left: -1px;
                width: calc(100% + 2px);
                background: #FFFFFF;
                z-index: 3;

                &__item {
                    border: 1px solid #E5E5E5;
                    border-top: none;

                    &:hover {
                        background: #F3F3F3;
                    }

                    &.active {
                        background: #E5E5E5;
                    }
                }

                &__image {
                  background: #FFFFFF;
                  border-radius: 5px;
                  overflow: hidden;
                    width: 100%;

                    img {
                        object-fit: contain;
                        width: 100%;
                    }
                }

                &__description {
                  display: flex;
                  align-items: center;
                }
            }
        }

        &__submenu {
            .filter__button {
                a {
                    font-size: 1.3rem;
                }

                &:hover {
                    background: #F3F3F3;
                }

                &.active {
                    background: #E5E5E5;
                }
            }
        }
    }
</style>
