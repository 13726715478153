<template>
    <b-container tag="section" class="filters mb-5">
        <b-row tag="ul" class="filter__row no-gutters">
            <b-col tag="li" cols="12" sm="6" :md="true" class="filter__button"
                   :class="[{active: $route.path.split('/')[1] === 'directory' && !$route.params.filter}]">
                <router-link to="/directory">Shop A - Z</router-link>
            </b-col>
            <b-col tag="li" cols="12" sm="6" :md="true" class="filter__button"
                   :class="[{active: $route.params.filter}]">
                <router-link to="/directory/all">Shops by Category</router-link>
            </b-col>
            <b-col tag="li" cols="12" sm="6" :md="true" class="filter__button"
                   :class="[{active: $route.path.split('/')[1] === 'map'}]">
                <router-link to="/map">Shops by Map</router-link>
            </b-col>
            <b-col tag="li" cols="12" sm="6" :md="true" class="filter__button filter__search">
                <input type="text" v-model="directorySearch" v-on:keyup="searchFilter" placeholder="Search"/>
            </b-col>
        </b-row>
        <b-row tag="ul" class="filter__row filter__submenu no-gutters mt-5" v-if="$route.params.filter">
            <b-col cols="12" sm="6" :md="true" tag="li" class="filter__button"
                   v-for="(subcategory, alias) in subcategories" :key="alias">
                <router-link :to="'/directory/'+alias">{{subcategory}}</router-link>
                <div v-if="categories[alias]"
                     :class="['category-arrow-up', 'd-none', {'d-md-block':$route.params.filter === alias}]"></div>
            </b-col>
        </b-row>
        <b-row tag="ul" class="filter__subcategories no-gutters mt-5 p-5"
               v-if="$route.params.filter && categories[$route.params.filter]">
            <b-col cols="6" sm="4" md="2" tag="li" class="filter__subcategory pb-3"
                   v-for="(category, alias) in categories[$route.params.filter]" :key="alias">
                <router-link :to="'/directory/' + $route.params.filter + '/' + alias ">{{category}}</router-link>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    import {mapFields} from "vuex-map-fields";

    export default {
        name: 'DirectoryFilters',
        data() {
            return {
                active: false,
                subcategories: {
                    all: 'All',
                    food: 'Food',
                    fashion: 'Fashion',
                    'decor-and-gifts': 'Décor & Gifts',
                    'health-and-beauty': 'Health & Beauty',
                    'services': 'Services'
                }
            }
        },
        computed: {
            ...mapFields([
                'directorySearch'
            ]),
            // TODO: Burn this
            categories: function () {
                let result = {}
                if (this.$store.state.categories.length > 0) {
                    let categories = this.$store.state.categories
                    for (let i = 0; i < categories.length; i++) {
                        if (categories[i].alias !== 'other') {
                            result[categories[i].alias] = {}
                            if (categories[i].children) {
                                for (let ii = 0; ii < categories[i].children.length; ii++) {
                                    if (categories[i].children[ii]
                                        .children) {
                                        for (let iii = 0; iii < categories[i].children[ii].children.length; iii++) {
                                            result[categories[i].alias][categories[i].children[ii].children[iii].alias] =
                                                categories[i].children[ii].children[iii].title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return result
            }
        },
        methods: {
            searchFilter(event) {
                let path = this.$route.path.split('/')
                if (event.key !== 'Tab' && event.key !== 'Shift' && (path[1] !== 'directory' || path.length > 2)) {
                    this.$router.push('/directory')
                }
            }
        },
        watch: {
            '$route.path': function (path) {
                if (path !== '/directory') {
                    this.directorySearch = ''
                }
            }
        }

    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .filter {
        &__row {
            list-style: none;
            padding: 0;
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            text-transform: uppercase;
            color: #070707;
            border: 1px solid #E5E5E5;
            cursor: pointer;

            @include media-breakpoint-only(xs) {
                &:not(:first-child) {
                    border-top: none;
                }
            }

            @include media-breakpoint-only(sm) {
                &:not(:first-child):not(:nth-child(2)) {
                    border-top: none;
                }
                &:nth-child(odd) {
                    border-right: none;
                }
            }

            @include media-breakpoint-up(md) {
                &:not(:first-child) {
                    border-left: none;
                }
            }

            &:not(.filter__search) {
                &:hover {
                    background: #F3F3F3;
                }

                &.active {
                    background: #E5E5E5;
                }
            }

            a {
                color: #070707;
                display: block;
                width: 100%;
                height: 100%;
                padding: 0.75em;
                text-decoration: none;
            }

            input {
                border: none;
                padding: 0.75em;
                text-transform: uppercase;
                text-align: center;
                height: 100%;
                width: 100%;
                outline: 0;
            }

            .dropdown {
                position: absolute;
                top: calc(100% + 1px);
                left: -1px;
                width: calc(100% + 2px);
                background: #FFFFFF;
                z-index: 3;

                &__item {
                    border: 1px solid #E5E5E5;
                    border-top: none;

                    &:hover {
                        background: #F3F3F3;
                    }

                    &.active {
                        background: #E5E5E5;
                    }
                }

                &__image {
                    width: 100%;

                    img {
                        object-fit: contain;
                        width: 100%;
                    }
                }
            }
        }

        &__submenu {
            .filter__button {
                a {
                    font-size: 1.3rem;
                }

                &:hover {
                    background: #F3F3F3;
                }

                &.active {
                    background: #E5E5E5;
                }
            }

            .router-link-active {
                background-color: #D9017A;
                color: #FFFFFF;
            }
        }

        &__subcategories {
            border: 1px solid #E3E3E3;

            li {
                list-style: none;
                font-family: Cardo, serif;
                font-size: 1.5rem;

                a {
                    color: #070707;

                    &.router-link-active {
                        color: #D9017A;
                    }
                }
            }
        }
    }

    .category-arrow-up {
        z-index: 1;
        bottom: -31px;
        left: 50%;
        content: " ";
        height: 0px;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        border-color: rgba(204, 204, 192, 0);
        border-bottom-color: #E3E3E3;
        border-width: 18px;
        margin-left: -18px;
    }

    .category-arrow-up::after {
        z-index: 2;
        top: -17px;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0px;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(204, 204, 192, 0);
        border-bottom-color: #FFFFFF;
        border-width: 18px;
        margin-left: -18px;
    }

</style>