<template>
    <b-container tag="section">
        <b-row>
            <b-col cols="12" md="9" class="mb-5">
                <iframe v-if="page.alias === 'directions'" class="map-iframe" title="Google map of Bayview Village and surrounding area"
                        src="https://www.google.com/maps/embed/v1/place?q=Bayview%20Village%20Shopping%20Centre&key=AIzaSyARwsBiL0cZi1P1wtqTlisIfSpHwGQDWtM"></iframe>
                <CaptionBox
                        v-else
                        :ratio=65.5
                        :image="{
                           src: page.image,
                           alt: 'Header image for ' + page.title}"
                        :caption="page.title"
                        captionPosition="bottom-left"/>
            </b-col>
            <b-col cols="12" md="3">
                <b-row>
                    <b-col cols="6" md="12" class="mb-5">
                        <CaptionBox
                                :ratio=100
                                :image=linkBox1[page.alias].image
                                :caption=linkBox1[page.alias].caption
                                :link=linkBox1[page.alias].link
                                captionPosition="bottom-left"/>
                    </b-col>
                    <b-col cols="6" md="12" class="mb-5">
                        <CaptionBox
                                :ratio=100
                                :image=linkBox2.image
                                :caption=linkBox2.caption
                                :link=linkBox2.link
                                captionPosition="top-left"
                                class="above-fold__linkbox"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    export default {
        name: 'LinkBoxHeader',
        props: {
          page: Object
        },
        data() {
            return {
                alias: false,
                replace: {
                    community: 'community-relations',
                    services: 'guest-services'
                },
                linkBox1: {
                    directions: {
                        caption: 'Contact Us',
                        link: {
                            route: '/contact',
                            title: 'Contact Us'
                        },
                        image: {
                            src: 'https://cdn.buttercms.com/WJsVYtPHTlWjj0dqQPC2',
                            alt: 'Photo of laptop on table'
                        }
                    },
                    contact: {
                        caption: 'Visit Us',
                        link: {
                            route: '/directions',
                            title: 'Visit Us'
                        },
                        image: {
                            src: 'https://cdn.buttercms.com/oonX1hXdQMaQhAq1aQG0',
                            alt: 'Photo of map showing pin for mall'
                        }
                    }
                },
                linkBox2: {
                    caption: 'Centre Hours',
                    link: {
                        route: '/directions#hoursofoperation',
                        title: 'Directions - Hours of Operation'
                    },
                    image: {
                        src: 'https://cdn.buttercms.com/sL7ZB88iQaGikqPpAbWW',
                        alt: 'Photo of various products'
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .map-iframe {
        border: none;
        width: 100%;
        height: 100%;
        @media (max-width: 767px) {
            height: 50vh;
        }
    }
</style>
