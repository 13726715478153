<template>
    <aside :class="['cart-wrapper', {fixed: fixed}, {open: cartDrawerOpen}]">
        <div class="cart__mask" v-on:click="toggleCartDrawer"></div>
        <div class="cart">
            <header v-if="fixed">
                <button class="cart__close-button" id="cart-close-button" v-on:click="toggleCartDrawer"
                        aria-label="Close">X
                </button>
                <img src="@/assets/gastronomer-logo.jpg" alt="Gastronomer Logo"
                     class="cart__store-logo mb-5"/>
                <h2 class="cart__title mb-5">Shopping Cart</h2>
            </header>
            <div class="mobile-scroll">
                <b-container tag="section" class="cart__items">
                    <b-row v-for="product in cartItems" :key="product.id" class="cart__item">
                        <b-col cols="3">
                            <!--<img :src="product.image.src" :alt="product.image.alt" class="product__image"/>-->
                            <img :src="product.image" :alt="'Product image of ' + product.title" class="product__image"/>
                        </b-col>
                        <b-col cols="9" md="6">
                            <h3 class="product__title">{{product.title}}</h3>
                            <h4 class="product__vendor"><span v-for="(vendor, index) in product.vendors" :key="index">
                                    <span v-if="index > 0">&nbsp;&#8226;&nbsp;</span>{{vendor.title}}</span>
                            </h4>
                            <p class="product__variant"><span v-if="product.item_size">{{product.item_size}}</span>
                                <span v-if="product.item_size && product.item_flavour">&nbsp;-&nbsp;</span>
                                <span v-if="product.item_flavour">{{product.item_flavour}}</span></p>
                        </b-col>
                        <b-col cols="12" md="3" class="mt-3 mt-md-0">
                            <div class="product__price mb-3">
                                {{product.item_price | currency}}<span v-if="!fixed">&nbsp;x&nbsp;{{product.qty}}</span>
                                <br v-if="!fixed" class="d-none d-md-inline">
                                <b v-if="!fixed" class="ml-5 ml-md-0">{{product.item_price * product.qty |
                                    currency}}</b>
                            </div>
                            <div v-if="fixed" class="product__quantity d-flex">
                                <a href="#" v-on:click.prevent="changeQty(product.id,-1)">-</a>
                                <input type="number" :value="product.qty" min="1" :aria-label="'Quantity of ' + product.title" disabled/>
                                <a href="#" v-on:click.prevent="changeQty(product.id,1)">+</a>
                            </div>
                        </b-col>
                        <b-col cols="12" v-if="product.start && new Date(product.start).getTime()  >= now.getTime()"><small>Earliest pickup date for this product is {{ product.start.split('T')[0] }}</small></b-col>
                    </b-row>
                </b-container>
                <b-container v-if="cartItems.length === 0" class="cart__empty-message">
                    <b-row>
                        <b-col>
                            <h2>YOUR CART IS EMPTY!</h2>
                            <router-link to="/gastronomer" v-on:click.native="toggleCartDrawer">Start Browsing
                            </router-link>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container v-else-if="fixed" tag="section" class="cart__totals">
                    <b-row>
                        <!--<b-col class="pt-2">
                            <input type="input" placeholder="Promo Code" class="cart__promo-field" />
                                <input type="submit" value="Apply" class="cart__promo-button" />
                        </b-col>-->
                        <b-col cols="12">
                            <b-container>
                                <b-row>
                                    <b-col>Subtotal:</b-col>
                                    <b-col>{{cart.totals.subtotal | currency}}</b-col>
                                </b-row>
                                <b-row>
                                    <b-col>HST:</b-col>
                                    <b-col>{{cart.totals.taxTotal | currency}}</b-col>
                                </b-row>
                                <b-row class="mb-5">
                                    <b-col><b>TOTAL:</b></b-col>
                                    <b-col><b>{{cart.totals.totalValue | currency}}</b></b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <router-link to="/gastronomer/checkout" class="cart__checkout-button"
                                         v-on:click.native="toggleCartDrawer">
                                Check Out
                            </router-link>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>
    </aside>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: 'Cart',
        props: {
            fixed: {
                type: Boolean,
                default: true
            },
            open: {
                type: Boolean,
                default: false
            }
        },
        components: {},
        data() {
            return {
                productQty: 1,
                now: new Date()
            }
        },
        computed: {
            ...mapState({
                products: state => state.products,
                cart: state => state.cart,
                cartDrawerOpen: state => state.cartDrawerOpen,
                userNavigatingByTab: state => state.userNavigatingByTab
            }),
            cartItems() {
              let result = []
              if(this.products.length > 0)
                for (const item_id in this.cart.items) {
                  const product = this.products.filter(product => product.id === item_id)[0]
                  if(product) {
                    product.qty = this.cart.items[item_id]
                    result.push(product)
                  }
                }
              return result
            }
        },
        methods: {
            toggleCartDrawer() {
                this.$store.commit('SET_STATE', {name: 'cartDrawerOpen', data: !this.cartDrawerOpen})
                if (this.$store.state.userNavigatingByTab === true)
                    document.getElementById('nav-cart-icon').focus();
            },
            changeQty(productId, amount) {
                let newAmount = this.cart.items[productId] + amount
                if (newAmount <= 0) {
                    newAmount = ''
                }
                this.$store.commit('ADD_TO_CART', {value: newAmount, id: productId})
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .cart-wrapper {

        &.fixed .cart {
            position: fixed;
            top: 0;
            bottom: 0;
            right: -550px;
            box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            z-index: 10;

            &__mask {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.15);
                z-index: 9;
                visibility: hidden;
                opacity: 0;
                transition: .5s;
            }

            &__totals {
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                width: 100%;
                padding: 3rem;
                font-size: 1.5rem;
                text-align: right;

            }

        }

        &.open .cart {
            right: 0;

            &__mask {
                opacity: 1;
                visibility: visible;
            }
        }

        &.fixed .mobile-scroll {
            overflow: auto;
            height: calc(100vh - 150px);
            padding-bottom: 100px;
        }

        .cart {
            background-color: #FFFFFF;
            width: 100%;
            max-width: 500px;
            padding: 0 3rem;
            transition: .5s;
            z-index: 10;
            @include media-breakpoint-up(lg) {
                min-height: 582px;
            }

            &__close-button {
                position: absolute;
                top: 5px;
                right: 5px;
                background: transparent;
                font-size: 2rem;
                color: #555555;
                border: 0;
            }

            &__store-logo {
                max-width: 250px;
            }

            &__title {
                text-transform: uppercase;
                font-size: 2rem;
            }

            &__items {
                @include media-breakpoint-up(lg) {
                    overflow: auto;
                    max-height: calc(100vh - 350px);
                }
            }

            &__item {
                padding: 3rem 0;
                border-top: 1px rgba(229, 27, 128, 0.3) solid;

                &:last-child {
                    border-bottom: 1px rgba(229, 27, 128, 0.3) solid;
                }
            }

            .product {
                &__image {
                    max-width: 100%;
                    object-fit: contain;
                }

                &__title {
                    font-size: 1.5rem;
                    text-align: left;
                }

                &__vendor {
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    text-align: left;
                }

                &__variant {
                    font-size: 1.3rem;
                    text-align: left;
                    margin: 0;
                }

                &__price {
                    font-size: 1.4rem;
                    text-align: right;
                }

                &__quantity {
                    width: 100%;
                    border: 1px solid #E5E5E5;
                    border-radius: 0.75rem;
                    user-select: none;
                    cursor: pointer;

                    input {
                        border: 0;
                        font-size: 1.3rem;
                        width: 33.33%;
                        background: transparent;
                        color: #070707;
                        text-align: center;
                        -moz-appearance: textfield;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                    a {
                        text-align: center;
                        font-size: 1.5rem;
                        color: #888888;
                        width: 33.33%;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            &__promo-field {
                display: inline-block;
                border: 1px solid #D1D1D1;
                padding: calc(0.75em - 1px) 0.75em;
                text-transform: uppercase;
                font-size: 1.3rem;
                max-width: 140px;
                border-top-left-radius: 0.75rem;
                border-bottom-left-radius: 0.75rem;
            }

            &__promo-button {
                display: inline-block;
                background-color: #D9017A;
                border: 0;
                padding: 0.75em;
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 1.3rem;
                font-weight: bold;
                border-top-right-radius: 0.75rem;
                border-bottom-right-radius: 0.75rem;
            }

            &__checkout-button {
                display: block;
                text-align: center;
                text-decoration: none;
                background-color: #D9017A;
                border: 0;
                padding: 0.75em;
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 1.7rem;
                line-height: 1.25em;
                font-weight: bold;
                width: 100%;
                border-radius: 0.75rem;
            }

            &__empty-message {
                height: calc(100% - 250px);
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    color: #D9017A;
                    cursor: pointer;
                    text-decoration: none;

                    &::before {
                        content: '<';
                        margin-right: 0.4rem;
                    }
                }
            }

        }
    }
</style>
