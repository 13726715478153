<template>
  <main id="main" class="page" v-if="page">
    <LinkBoxHeader v-if="page.alias === 'directions' || page.alias === 'contact'" :page="page"/>
    <b-container v-else class="page__header mb-5 py-0">
      <CaptionBox
          :ratio=45
          :image="headerImage"
          :caption="headerCaption ? page.title : null"
          captionTag="h1"
          captionPosition="top-left"
          class="mb-5 page__header-image"/>
      <div class="page__info" v-if="!headerCaption">
        <h1>{{ page.title }}</h1>
      </div>
    </b-container>
    <ContentStack :contents="page.contents" class="page__content"/>
  </main>
  <Loader v-else/>
</template>
<script>
import ContentStack from '@/components/ContentStack.vue'
import LinkBoxHeader from '@/components/LinkBoxHeader.vue'

export default {
  name: 'Page',
  metaInfo() {
    return {
      title: (this.page ? this.page.title + ' | Bayview Village' : 'Bayview Village')
    }
  },
  components: {ContentStack, LinkBoxHeader},
  props: {
    headerCaption: {
      type: Boolean,
      default: true
    },
    alias: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    return {
      pageAlias: false
    }
  },
  computed: {
    page: function () {
      if (this.pageAlias) {
        return this.$store.state.pages[this.pageAlias] || false
      }
      return false;
    },
    headerImage: function () {
      if (this.page) {
        return {
          src: this.page.image,
          alt: 'Header image for ' + this.page.title
        }
      }
      return null;
    },
  },
  methods: {
    initializePage(alias) {
      let closure = this
      if (this.$store.state.pages[alias] !== undefined) {
        this.pageAlias = alias
      } else if (alias !== undefined) {
        this.$store.dispatch('LOAD_API', {
          name: 'pages',
          id: alias,
          url: '/page/' + alias
        }).then(function () {
          closure.pageAlias = alias
        })
      }
    }
  },
  created() {
    this.initializePage(this.alias || this.$route.path.split('/')[1])
  },
  watch: {
    '$route.path': function () {
      this.initializePage(this.alias || this.$route.path.split('/')[1])
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  text-align: left;

  &__header-image {
    @media (max-width: 575px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__info {
    text-transform: uppercase;

    a:hover {
      text-decoration: none;
    }

    h1 {
      font-size: 2.4rem;
      margin-bottom: 0;
    }

    h2 {
      color: #D9017A;
      font-size: 1.3rem;
    }

    .page__date {
      font-size: 1.3rem;
    }
  }

  ::v-deep {
    .caption-box {
      &__caption-text {
        font-size: 1.8rem;
      }
    }

    .page__content .simple-content {
      h2 {
        font-size: 2.4rem;
      }

      h3 {
        font-size: 1.8rem;
        text-transform: uppercase;
      }

      .simple-content {
        margin-bottom: 5rem;
      }
    }

    ul {
      font-family: Cardo, serif;
      margin-bottom: 15px;

      li {
        text-align: left;
      }
    }
  }
}
</style>
