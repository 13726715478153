<template>
    <router-link :to="'/gastronomer/product/'+item.id" class="item" v-if="item.title">
        <div :class="['item__image-container square-image mb-4', {'not-loaded': !showImg}]">
            <!--<img class="item__image"
                 v-show="typeof item.image.src != null && showImg"
                 :src="item.image.src"
                 :alt="item.image.alt"
                 @load="showImg=true"/>-->
            <img class="item__image"
                 v-show="typeof item.image != null && showImg"
                 :src="item.image | newurl(350)"
                 :alt="'Image of ' + item.title"
                 @load="showImg=true"/>
        </div>
        <div class="item__details">
            <b-row>
                <b-col cols="8">
                    <h2 class="item__title text-left" v-if="item.title">{{item.title}}</h2>
                </b-col>
                <b-col cols="4" class="d-flex align-items-start justify-content-end">
                    <span class="item__price" v-if="item.item_price">{{item.item_price | currency}}</span>
                </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <h3 class="item__vendor text-left"><span v-for="(vendor, index) in item.vendors" :key="index">
                                    <span v-if="index > 0">&nbsp;&#8226;&nbsp;</span>{{vendor.title}}</span>
                </h3>
              </b-col>
            </b-row>
        </div>
        <div class="item__flag sold-out" v-if="item.soldOut">Sold Out</div>
        <div class="item__flag" v-else-if="item.flag">{{item.flag}}</div>
    </router-link>
</template>
<script>
    export default {
        name: 'Item',
        props: {
            item: {
                type: Object
            }
        },
        data() {
            return {
                showImg: false
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .item {
        position: relative;
        color: #070707;
        display: block;
        text-decoration: none;

        &__image-container {
            width: 100%;

            &.not-loaded {
                background: url(../assets/bayview-village-placeholder.png) no-repeat center center #F1F1F1;
                background-blend-mode: color-burn;
            }
        }

        &__image {
            object-fit: cover;
        }

        &__title {
            font-size: 1.4rem;
            text-transform: none;
        }

        &__price {
            font-size: 1.3rem;
        }

        &__vendor {
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: bold;
        }

        &__flag {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 10%;
            right: 0;
            padding: 0.1rem 1rem;
            background-color: #D9017A;
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: bold;

            &:not(.sold-out)::before {
                content: "";
                position: absolute;
                right: 100%;
                top: 0;
                border-top: 1rem solid transparent;
                border-bottom: 1rem solid transparent;
                border-right: 1.7rem solid #D9017A;
            }

            &.sold-out {
                background-color: #FFFFFF;
                color: #070707;
                border: 2px solid #D9017A;
            }

        }
    }
</style>