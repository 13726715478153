<template>
    <div class="product-gallery row" v-if="products && newProducts.length > 0">
        <NavLink v-for="(product, index) in newProducts"
                 :key="index"
                 class="product-gallery__product col"
                 :link="product.link">
            <div class="product-gallery__image">
                <ImageObject :image="product.image"/>
            </div>
            <h2>{{product.title}}</h2>
            <h3 v-if="product.vendors[0].title">{{product.vendors[0].title}}</h3>
        </NavLink>
    </div>
</template>
<script>

    export default {
        name: 'ProductContentRow',
        props: {
            products: Array
        },
        computed: {
            newProducts: function () {
                let products = JSON.parse(JSON.stringify(this.products || []))
                for (let i = 0; i < products.length; i++) {
                    products[i].link = {
                        route: '/store/' + products[i].vendors[0].alias,
                        title: products[i].title + ' from ' + products[i].vendors[0].title
                    }
                    products[i].image = {
                        src: products[i].image,
                        alt: 'Image of ' + products[i].title
                    }
                }
                return products
            }
        }
    }
</script>
<style lang="scss" scoped>
    .product-gallery {
        &__image {
            position: relative;
            padding-bottom: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        &__product {

            text-align: center;

            &:hover {
                text-decoration: none;
            }

            h2 {
                text-decoration: none;
                text-transform: none;
                font-size: 1.3rem;
                color: #070707;
                padding: 0 15px;
                margin-top: 15px;
            }

            h3 {
                text-decoration: none;
                text-transform: uppercase;
                color: #D9017A;
                font-size: 1.3rem;
                padding: 0 15px;
            }
        }
    }
</style>