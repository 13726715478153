<template>
    <b-row class="article-gallery" v-if="articles && articles.length > 0">
        <b-col cols="12" :md="featured !== undefined ? 5 : 12" order="2" order-md="1">
            <b-row>
                <b-col
                        v-for="(article, index) in articles"
                        :key="index"
                        cols="6"
                        :md="featured === undefined ? 4 : 6"
                        :lg="featured === undefined ? true : 6"
                        class="mb-4 article-gallery__article"
                        tag="NavLink"
                        :link="article.link"
                        v-if="article !== undefined">
                    <CaptionBox
                            :ratio=100
                            :image="article.image"
                    />
                    <h3>{{article.title | truncate(40, " . . .") }}</h3>
                    <p class="d-none d-sm-block">{{article.caption | truncate((article.title.length > 20 ? 65
                        : 90), " .&nbsp;.&nbsp;.") }}</p>
                </b-col>
            </b-row>
        </b-col>
        <b-col v-if="featured !== undefined" cols="12" md="7" class="mb-4 article-gallery__feature" tag="a" order="1" order-md="2"
               :href="featured.link.route"
               :title="featured.link.title">
            <CaptionBox
                    :ratio=82
                    :image="featured.image"
                    :caption=featureCaption
                    captionPosition="top-left"
                    v-if="featured"/>
            <h2>{{featured.title}}</h2>
            <p>{{featured.caption | truncate(200, " .&nbsp;.&nbsp;.") }}</p>
        </b-col>
    </b-row>
</template>
<script>
    export default {
        name: 'ArticleGallery',
        props: {
            articles: Array,
            featured: Object,
            featureCaption: String
        },
        created() {
          console.log('articles -> ', this.articles)
        }
    }
</script>
<style lang="scss" scoped>
    .article-gallery {
        &__article,
        &__feature {
            color: #070707;
            &:hover {
                text-decoration: none;
            }

            h2, h3 {
                text-transform: uppercase;
                text-align: center;
                margin: 10px 0;
                font-size: 1.4rem;
            }

            p {
                font-size: 1.4rem;
                font-family: Cardo, serif;
                line-height: 1.2em;
                margin-bottom: 0;
            }
        }
    }
</style>
