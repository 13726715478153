<template>
    <aside :class="['cart-wrapper', {fixed: fixed}, {open: wishListDrawerOpen}]">
        <div class="cart__mask" v-on:click="toggleWishListDrawer"></div>
        <div class="cart">
            <header v-if="fixed">
                <button class="cart__close-button" id="cart-close-button" v-on:click="toggleWishListDrawer"
                        aria-label="Close">X
                </button>
                <h2 class="cart__title my-5">Wish List</h2>
            </header>
            <div class="mobile-scroll">
                <b-container tag="section" class="cart__items">
                    <b-row v-for="(product, index) in wishlist" :key="index" class="cart__item">
                        <b-col cols="3">
                            <img :src="product.featured_image"
                                 :alt="'Product image of ' + product.featured_image_alt"
                                 class="product__image"/>
                        </b-col>
                        <b-col cols="9" md="6">
                            <h3 class="product__title">{{product.name || product.description.replace(/\r?\n|\r/g, '')}}</h3>

                            <h4 class="product__vendor">{{product.retailer[0].name}}</h4>

                        </b-col>
                        <b-col cols="12" md="3" class="mt-3 mt-md-0">
                            <div v-if="fixed" class="d-flex">
                                <a href="#" v-on:click.prevent="removeFromWishList(product)" title="Remove">Remove</a>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container v-if="wishlist.length === 0" class="cart__empty-message">
                    <b-row>
                        <b-col>
                            <h2>YOUR WISH LIST IS EMPTY!</h2>
                            <router-link to="/holiday-gift-guide" v-on:click.native="toggleWishListDrawer">Start Browsing
                            </router-link>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>
    </aside>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: 'Cart',
        props: {
            fixed: {
                type: Boolean,
                default: true
            },
            open: {
                type: Boolean,
                default: false
            }
        },
        components: {},
        data() {
            return {
                productQty: 1,
                now: new Date()
            }
        },
        computed: {
            ...mapState({
                wishlist: state => state.wishlist,
                wishListDrawerOpen: state => state.wishListDrawerOpen,
                userNavigatingByTab: state => state.userNavigatingByTab
            }),
        },
        methods: {
            toggleWishListDrawer() {
                this.$store.commit('SET_STATE', {name: 'wishListDrawerOpen', data: !this.wishListDrawerOpen})
                if (this.$store.state.userNavigatingByTab === true)
                    document.getElementById('nav-cart-icon').focus();
            },
            removeFromWishList(product) {
                this.$store.commit('REMOVE_FROM_WISHLIST', product)
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .cart-wrapper {

        &.fixed .cart {
            position: fixed;
            top: 0;
            bottom: 0;
            right: -550px;
            box-shadow: -10px 0 15px rgba(0, 0, 0, 0.2);
            z-index: 10;

            &__mask {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.15);
                z-index: 9;
                visibility: hidden;
                opacity: 0;
                transition: .5s;
            }

            &__totals {
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                width: 100%;
                padding: 3rem;
                font-size: 1.5rem;
                text-align: right;

            }

        }

        &.open .cart {
            right: 0;

            &__mask {
                opacity: 1;
                visibility: visible;
            }
        }

        &.fixed .mobile-scroll {
            overflow: auto;
            height: calc(100vh - 150px);
            padding-bottom: 100px;
        }

        .cart {
            background-color: #FFFFFF;
            width: 100%;
            max-width: 500px;
            padding: 0 3rem;
            transition: .5s;
            z-index: 10;
            @include media-breakpoint-up(lg) {
                min-height: 582px;
            }

            &__close-button {
                position: absolute;
                top: 5px;
                right: 5px;
                background: transparent;
                font-size: 2rem;
                color: #555555;
                border: 0;
            }

            &__store-logo {
                max-width: 250px;
            }

            &__title {
                text-transform: uppercase;
                font-size: 2rem;
            }

            &__items {
                @include media-breakpoint-up(lg) {
                    overflow: auto;
                    max-height: calc(100vh - 350px);
                }
            }

            &__item {
                padding: 3rem 0;
                border-top: 1px rgba(229, 27, 128, 0.3) solid;

                &:last-child {
                    border-bottom: 1px rgba(229, 27, 128, 0.3) solid;
                }
            }

            .product {
                &__image {
                    max-width: 100%;
                    object-fit: contain;
                }

                &__title {
                    font-size: 1.5rem;
                    text-align: left;
                }

                &__vendor {
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    text-align: left;
                }

                &__variant {
                    font-size: 1.3rem;
                    text-align: left;
                    margin: 0;
                }

                &__price {
                    font-size: 1.4rem;
                    text-align: right;
                }

                &__quantity {
                    width: 100%;
                    border: 1px solid #E5E5E5;
                    border-radius: 0.75rem;
                    user-select: none;
                    cursor: pointer;

                    input {
                        border: 0;
                        font-size: 1.3rem;
                        width: 33.33%;
                        background: transparent;
                        color: #070707;
                        text-align: center;
                        -moz-appearance: textfield;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                    a {
                        text-align: center;
                        font-size: 1.5rem;
                        color: #888888;
                        width: 33.33%;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            &__promo-field {
                display: inline-block;
                border: 1px solid #D1D1D1;
                padding: calc(0.75em - 1px) 0.75em;
                text-transform: uppercase;
                font-size: 1.3rem;
                max-width: 140px;
                border-top-left-radius: 0.75rem;
                border-bottom-left-radius: 0.75rem;
            }

            &__promo-button {
                display: inline-block;
                background-color: #D9017A;
                border: 0;
                padding: 0.75em;
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 1.3rem;
                font-weight: bold;
                border-top-right-radius: 0.75rem;
                border-bottom-right-radius: 0.75rem;
            }

            &__checkout-button {
                display: block;
                text-align: center;
                text-decoration: none;
                background-color: #D9017A;
                border: 0;
                padding: 0.75em;
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 1.7rem;
                line-height: 1.25em;
                font-weight: bold;
                width: 100%;
                border-radius: 0.75rem;
            }

            &__empty-message {
                height: calc(100% - 250px);
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    color: #D9017A;
                    cursor: pointer;
                    text-decoration: none;

                    &::before {
                        content: '<';
                        margin-right: 0.4rem;
                    }
                }
            }

        }
    }
</style>
