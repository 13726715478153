<template>
  <main id="main" class="directory" v-if="directory">
    <DirectoryFilters/>
    <DirectoryMap v-if="$route.path.split('/')[1] === 'map' && retailers.length > 0" :retailers="retailers"/>
    <b-container v-else tag="section" class="directory__listing">
      <b-row>
        <!--        <b-col cols="6" sm="4" md="3" lg="2"
                       v-for="(retailer, index) in directory"
                       :key="index"
                       class="retailer"
                       v-show="!retailer.hide">
                  <div class="retailer__image-container mb-5 order-3 order-lg-2">
                    <div>
                      <NavLink :route="'/store/' + retailer.slug" :title="retailer.name" class="">
                        <ImageObject :image="retailer.image" class="retailer__image"/>
                      </NavLink>
                    </div>
                  </div>
                </b-col>-->
        <b-col cols="6" sm="4" md="3" lg="2"
               v-for="(retailer, index) in directory"
               :key="index"
               class="retailer"
               v-show="!retailer.hide">
          <div class="retailer__image-container mb-5 order-3 order-lg-2">
            <div class="square-image">
              <NavLink :route="'/store/' + retailer.slug" :title="retailer.name" class="retailer__link">
                <ImageObject :image="retailer.image" class="retailer__image"/>
              </NavLink>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="no-results"
             v-if="directory.filter(retailer => { return !retailer.hide}).length === 0">
        <img src="@/assets/heads/ask-the-expert.png"
             alt="Sketch of a woman's head." class="mb-3">
        <p>Sorry . . . nothing matching that search!</p>
      </b-row>
    </b-container>
  </main>
  <Loader v-else/>
</template>
<script>
import { mapState } from 'vuex'
import DirectoryFilters from "@/components/DirectoryFilters";
import DirectoryMap from "@/components/DirectoryMap";
import { butter } from "@/buttercms";

export default {
  name: 'Directory',
  data() {
    return {
      retailers: []
    }
  },
  components: {DirectoryFilters, DirectoryMap},
  computed: {
    ...mapState({
      directorySearch: state => state.directorySearch
    }),
    directory: function () {

      if (this.retailers.length === 0)
        return false;

      let retailers = JSON.parse(JSON.stringify(this.retailers))
      retailers = retailers.filter(obj => {
        return obj.slug !== 'bayview-village'
      })

      for (let i = 0; i < retailers.length; i++) {
        retailers[i].titleLC = retailers[i].name.toLowerCase()
        retailers[i].image = {
          src: retailers[i].logo,
          alt: retailers[i].name + ' logo'
        }
        retailers[i].link = {
          route: '/store/' + retailers[i].slug,
          title: retailers[i].name
        }

        if (this.isFilteredOut(retailers[i])) {
          retailers[i].hide = true
        }
      }
      return retailers
    }
  },
  methods: {
    /* if (this.$store.state.directory.length === 0) {
         this.$store.dispatch('LOAD_API', {
             name: 'directory',
             url: 'vendors?sort=title'
         })
     }*/
    async initializeDirectory() {
      const params = {
        order: 'name'
        /*  "preview": 1,
          "fields.genre": "Rock",
          "page": 1,
          "page_size": 10,
          "locale": "en",
          "levels": 2*/
      };

      const response = (
          await butter.content.retrieve(['retailer'], params)
      ).data
      this.retailers = this.retailers.concat(response.data.retailer)
    },
    isFilteredOut(retailer) {
      if (this.directorySearch.length > 0) {
        return retailer.titleLC.indexOf(this.directorySearch.toLowerCase()) === -1
      } else if (this.$route.params.filter && this.$route.params.filter !== 'all') {
        if (!retailer.categories)
          return true

        let categoryFilter = retailer.categories.filter(obj => {
          return obj.slug === this.$route.params.filter
        })

        let subCategoryFilter = []
        if (this.$route.params.subfilter) {
          subCategoryFilter = retailer.categories.filter(obj => {
            return obj.slug === this.$route.params.subfilter
          })
        }

        if ((categoryFilter.length === 0 || this.$route.params.subfilter) && subCategoryFilter.length === 0) {
          return true
        }
      }
      return false
    }
  },
  created() {
    this.initializeDirectory()
  }
}
</script>
<style lang="scss" scoped>
.retailer {
  /* Display 5 columns on >= lg to make 2 rows of 5 */
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image-container {
    border: 1px solid #E5E5E5;
    padding: 40px;
    transition: 0.3s;
    @media (min-width: 992px) {
      // filter: grayscale(1);
    }

    &:hover {
      transform: scale(1.05);
      @media (min-width: 992px) {
        // filter: grayscale(0);
      }
    }

    .square-image img {
      object-fit: contain;
    }
  }
}

.no-results {
  min-height: 300px;
  max-height: 100%;
  font-size: 1.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
