<template>
    <b-container tag="section" class="vendors">
        <b-row tag="ul">
            <b-col tag="li" v-for="vendor in vendors" :key="vendor.id" cols="6" :sm="4"
                   lg="3" class="mb-5">
                <Vendor :vendor="vendor"/>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    import Vendor from '@/components/Vendor'

    export default {
        name: 'vendors',
        components: {
            Vendor,
        },
        props: {
            vendors: [Array, Object]
        },
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .vendors {
        > ul {
            list-style: none;
            padding: 0;
        }

        .row {
            margin-left: -50px;
            margin-right: -50px;
        }

        [class*='col-'] {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
</style>