<template>
    <div id="app">
        <header class="mb-3">
            <TopNavigation/>
            <SubNavigation/>
        </header>
        <router-view/>
        <Footer/>
        <!--<Cart v-if="$route.path !== '/checkout'" :open="cartDrawerOpen"/>-->
        <WishList :open="wishListDrawerOpen"/>
<!--        <CondoModal />-->
        <NewsletterModal></NewsletterModal>
    </div>
</template>

<script>
    import TopNavigation from '@/components/TopNavigation'
    import SubNavigation from '@/components/SubNavigation'
    /*import Cart from '@/components/Cart'*/
    import WishList from '@/components/WishList'
    //import Cart from '@/components/CartNew'
    import NewsletterModal from '@/components/global/NewsletterModal'
    /*import CondoModal from '@/components/global/CondoModal.vue'*/
    import Footer from '@/components/Footer'
    import {mapState} from 'vuex'
    import { butter } from "@/buttercms";

    export default {
        name: 'App',
        components: {
            TopNavigation, SubNavigation, /*Cart,*/ Footer, /*CondoModal */NewsletterModal, WishList
        },
        computed: {
            ...mapState({
                cartDrawerOpen: state => state.cartDrawerOpen,
                wishListDrawerOpen: state => state.wishListDrawerOpen
            })
        },
        methods: {
          buildNestedStructure(flatCategories) {
              const categoriesById = {};
              const rootCategories = [];

              // First pass to create a dictionary (for quick access) and to identify root categories
              flatCategories.forEach(category => {
                const { id } = category.meta;
                const parentId = category.parent.meta ? category.parent.meta.id : null;

                // Basic category structure
                categoriesById[id] = {
                  id: category.slug,
                  alias: category.slug,
                  title: category.name,
                  children: []
                };

                if (!parentId) {
                  rootCategories.push(categoriesById[id]);
                }
              });

              // Second pass to assign children to their parents
              flatCategories.forEach(category => {
                const { id } = category.meta;
                const parentId = category.parent.meta ? category.parent.meta.id : null;

                if (parentId) {
                  categoriesById[parentId].children.push(categoriesById[id]);
                }
              });

              return rootCategories;
            },
            async loadCategories() {
              const response = (
                  await butter.content.retrieve(['retail_categories'], {
                    order: ['name']
                  })
              ).data

              const nestedStructure = this.buildNestedStructure(response.data.retail_categories);
              this.$store.commit('SET_STATE', {name: 'categories', data: nestedStructure})
            },
            /*loadCategories() {
                if (this.$store.state.categories.length === 0)
                    this.$store.dispatch('LOAD_API', {
                        name: 'categories',
                        url: 'taxonomies/vendor?recursive=true'
                    })
            },*/
            /*loadOccupancy() {
                this.$store.dispatch('LOAD_API', 'occupancy')
            },*/
            HandleKeyDown(event) {
                if (event.key === "Tab" && this.$store.state.userNavigatingByTab === false)
                    this.$store.commit('SET_STATE', {name: 'userNavigatingByTab', data: true});
            }
        },
        created() {
            this.loadCategories()
            /*this.loadOccupancy()*/
            window.addEventListener('keydown', this.HandleKeyDown)
        }
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Cardo|Montserrat:500,400');
    @import 'utils/global.scss';

    html {
        font-size: 62.5%;
    }

    body {
        font-size: 1.6rem;
        white-space: pre-wrap;
    }

    #app {
        font-family: Montserrat, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #070707;
        overflow: hidden;
    }

    p {
        text-align: left;
    }

    main {
        min-height: calc(100vh - 250px);
    }

    .square-image {
        position: relative;
        padding-bottom: 100%;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .sidelines {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:before,
        &:after {
            content: '';
            border-top: 1px solid #CCCCCC;
            margin: 0 20px 0 0;
            flex: 1 0 20px;
        }

        &:after {
            margin: 0 0 0 20px;
        }
    }
</style>
