<template>
  <b-row>
    <b-col cols="12" sm="6" :lg="4" class="vignette"
           v-for="(vignette, i) in vignettes" :key="i"
    >
      <!--      <h2 class="vignette__header text-center mt-3" v-html="vignette.fields.title"></h2>-->
      <div
          class="vignette__video"
          @click="handleVideo($event, 'video' + i, '/' + path + '/' + vignette.fields.slug)"
          @mouseenter="handleVideo($event, 'video' + i)"
          @mouseleave="handleVideo($event, 'video' + i)">
        <video
            :id="'video' + i"
            loop muted playsinline>
          <source :src="require('@/assets/vignettes/' + vignette.fields.vignette_video)" type="video/mp4">
        </video>

        <div class="tap-icon" v-if="isTouchDevice() && currentVideo !== 'video' + i">
          Tap <i class="fa fa-play ml-2"></i>
        </div>
        <a v-if="vignette.fields.instagram"
           target="_blank"
           :href="'https://www.instagram.com/' + vignette.fields.instagram"
           class="vignette__byline"
           @click.stop="">@{{ vignette.fields.instagram }}</a>
      </div>
      <router-link
          :to="'/' + path + '/' + vignette.fields.slug"
          class="vignette__button">
        <h2 class="vignette__header text-center mt-3" v-html="vignette.fields.title"></h2>
      </router-link>
    </b-col>
  </b-row>
</template>
<script>
export default {
  data() {
    return {
      currentVideo: '',
      path: ''
    }
  },
  props: {
    vignettes: Array
  },
  methods: {
    isTouchDevice() {
      return 'ontouchstart' in window || 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    },
    playVideo(index) {
      document.getElementById(index).play()
      setTimeout(function () {
        document.getElementById(index + 'poster').style.opacity = 0
      }, this.isTouchDevice() ? 500 : 0)
      this.currentVideo = index
    },
    pauseVideo(index) {
      this.currentVideo = ''
      if (index === undefined) {
        document.getElementsByTagName('video').forEach(video => video.pause())
      } else {
        document.getElementById(index).pause()
      }
    },
    handleVideo(event, video_id, link) {

      switch (event.type) {

          // Actions to start video
        case 'mouseenter':
          if (!this.isTouchDevice()) {
            this.playVideo(video_id)
          }
          break;

          // Actions to stop video
        case 'mouseleave':
          if (!this.isTouchDevice()) {
            this.pauseVideo(video_id)
          }
          break;

          // Actions to navigate
        case 'click':
          if (this.isTouchDevice()) {
            const playVideo = document.getElementById(video_id).paused
            this.pauseVideo()
            if (playVideo) {
              this.playVideo(video_id)
            }
          } else {
            this.$router.push(link)
          }
          break;
      }
    }
  },
  created() {
    this.path = this.$route.path.split('/')[1]
  }
}
</script>

<style lang="scss" scoped>
.vignette {
  padding: 2.5rem;

  &__header {
    color: #D9017A;
    font-size: 2.1rem;
    font-style: italic;
    text-transform: none !important;
    font-family: Cardo, serif;
  }

  &__byline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-transform: none;
    color: #FFFFFF;
    font-family: Cardo, serif;
    font-size: 1.7rem;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(50, 50, 50, 0.5);
  }

  a {
    display: block;
    text-decoration: none;
  }

  &__video {
    position: relative;
    width: 100%;
    padding-bottom: 180%;
    overflow: hidden;
    cursor: pointer;

    video {
      position: absolute;
      top: -1%;
      left: -1%;
      width: 102%;
      height: 102%;
      object-fit: cover;
    }

    .tap-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0.4;
      @media (min-width: 992px) {
        display: none;
      }
    }
  }

  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: opacity .3s;
  }

  &__button {
    color: #555555;
    text-transform: uppercase;
    padding: 1.5rem;
    border: 1px solid #CCCCCC;
    border-top: none;
    transition: .3s;
  }

  &:hover {
    h2.vignette__header,
    h3.vignette__byline {
      color: #FFFFFF;
    }

    .vignette__button {
      background: #D9017A;
    }
  }
}
</style>
