<template>
    <div class="sub-navigation d-none d-xl-block">
        <b-container v-on:mouseleave="hideDropdown()">
            <b-navbar toggleable="lg" type="light" class="d-none d-xl-block">
                <b-navbar-toggle target="nav-collapse">Directory</b-navbar-toggle>
                <b-collapse is-nav>
                    <b-navbar-nav align="center">
                        <b-nav-item tag="router-link" to="/directory">Directory</b-nav-item>
                        <b-nav-item tag="router-link" v-on:mouseover="showDropdown('food')"
                                    v-on:click="hideDropdown(true)"
                                    to="/directory/food">Gourmet Food
                        </b-nav-item>
                        <b-nav-item tag="router-link" v-on:mouseover="showDropdown('fashion')"
                                    v-on:click="hideDropdown(true)"
                                    to="/directory/fashion">Haute Fashion
                        </b-nav-item>
                        <b-nav-item tag="router-link" v-on:mouseover="showDropdown('decor-and-gifts')"
                                    v-on:click="hideDropdown(true)"
                                    to="/directory/decor-and-gifts">Glam Décor & Gifts
                        </b-nav-item>
                        <b-nav-item tag="router-link" v-on:mouseover="showDropdown('health-and-beauty')"
                                    v-on:click="hideDropdown(true)"
                                    to="/directory/health-and-beauty">Gorgeous Healthy Self
                        </b-nav-item>
                        <b-nav-item tag="router-link" v-on:mouseover="showDropdown('services')"
                                    v-on:click="hideDropdown(true)"
                                    to="/directory/services">Chic Services
                        </b-nav-item>
                        <b-nav-item v-on:mouseover="showDropdown('other')"
                                    v-on:click="toggleDropdown('other')">Haute Stuff
                        </b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </b-container>
        <div v-if="this.dropdownAlias" class="submenu-dropdown" v-on:mouseover="showDropdown()"
             v-on:mouseleave="hideDropdown()">
            <b-container class="mb-5 pt-3">
                <b-row>
                    <b-col cols="3" offset="2">
                        <ImageObject :src="require('@/assets/dropdownimages/' + dropdownAlias + '.jpg')"/>
                    </b-col>
                    <b-col cols="7">
                        <ul v-for="(category, index) in categories[dropdownAlias]" :key="index">
                            <li v-for="(subcategory, index) in category.children" :key="index"
                                v-on:click="hideDropdown(true)">
                                <router-link
                                        :to="subcategory.alias ? '/directory/' + dropdownAlias + '/' + subcategory.alias : subcategory.route">
                                    {{subcategory.title}}
                                </router-link>
                            </li>
                        </ul>
                        <b-nav-item v-on:click="hideDropdown(true)" class="submenu-dropdown__close" v-if="dropdownOpenedByClick">Close</b-nav-item>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SubNavigation',
        data() {
            return {
                dropdownAlias: false,
                dropdownOpenedByClick: false,
                timeout: false
            }
        },
        computed: {
            categories: function () {
                let result = {
                    other: [
                        {
                            children: [
                                {
                                    route: '/ask-the-expert',
                                    title: 'Ask the Expert'
                                },
                                {
                                    route: '/blog',
                                    title: 'BV Blog'
                                },
                                {
                                    route: '/lifestyle-files',
                                    title: 'Lifestyle Files'
                                }
                            ]
                        },
                        {
                            children: [
                                {
                                    route: '/hautelisted',
                                    title: 'Hautelisted'
                                },
                                {
                                    route: '/magazine',
                                    title: 'Magazine'
                                }
                            ]
                        },
                        {
                            children: [
                                {
                                    route: '/events',
                                    title: 'Events & Promotions'
                                },
                                {
                                    route: '/media-room',
                                    title: 'Media Room'
                                },
                                {
                                    route: '/hautecast',
                                    title: 'The Hautecast'
                                }
                            ]
                        }
                    ]
                }
                if (this.$store.state.categories.length > 0) {
                    this.$store.state.categories.forEach(item => {
                        if (item.alias !== 'other') {
                            result[item.alias] = item.children
                        }
                    })
                }
                return result
            }
        },
        methods: {
            showDropdown(menuAlias) {
                clearTimeout(this.timeout)
                this.timeout = false
                if (menuAlias) {
                    this.dropdownAlias = menuAlias
                    this.dropdownOpenedByClick = false
                }
            },
            hideDropdown(immediately) {
                let closure = this
                if (immediately === true) {
                    closure.dropdownAlias = false
                    closure.dropdownOpenedByClick = false
                } else {
                    this.timeout = setTimeout(function () {
                        closure.dropdownAlias = false
                        closure.dropdownOpenedByClick = false
                    }, 500)
                }
            },
            toggleDropdown(menuAlias) {
                if (menuAlias &&
                    (this.dropdownAlias === false || this.dropdownAlias !== menuAlias)) {
                    this.dropdownAlias = menuAlias
                    this.dropdownOpenedByClick = true
                }
                else {
                    this.dropdownAlias = false
                    this.dropdownOpenedByClick = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../utils/global.scss';

    .sub-navigation {
        position: relative;
    }

    .navbar {
        padding: 15px 0;
    }

    .navbar-light .navbar-nav a.nav-link {
        color: #070707;
        text-transform: uppercase;
        font-size: 1.3rem;
    }

    .navbar-nav {
        width: 100%;

        .nav-item {

            a.nav-link {
                padding: 0 1.2em;
            }

            &:first-child {
                padding-right: 2em;

                a.nav-link {
                    position: relative;
                    background-color: #E5E5E5;
                    padding: 0.1rem 1rem;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 100%;
                        top: 0;
                        border-top: 1rem solid transparent;
                        border-bottom: 1rem solid transparent;
                        border-left: 1.7rem solid #E5E5E5;
                    }
                }
            }

            &:last-child {
                padding-left: 3rem;
                margin-left: 2rem;
                border-left: 2px solid #000000;
            }
        }
    }

    .submenu-dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        background: #FFFFFF;
        min-height: 200px;
        z-index: 100;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, .2);
        transition: 0.3s;

        ul {
            float: left;
            padding: 0 15px;
            margin: 0;
        }

        li {
            list-style: none;
            text-align: left;

            a {
                color: #070707;
                font-family: Cardo, serif;

                &:hover {
                    text-decoration: none;
                }
            }
        }
        &__close {
            float: right;
            margin: 0;
            padding: 0;
        }
    }

</style>
