<template>
  <main id="main" class="page" v-if="page">
    <template v-for="(section, index) in sections">
      <FeaturedImageBoxes v-if="section.type === 'featured_image_boxes'"
                          :section="section.fields"
                          :key="index"/>
      <div v-else-if="section.type === 'image_grid'"
           class="container"
           :key="index">
        <ImageContentRowNew
            :images="section.fields.image_list"
            :columnsDesktop="section.fields.number_columns_desktop"
            :columnsMobile="section.fields.number_columns_mobile"
        />
      </div>
      <div v-else-if="section.type === 'content'"
           class="content-wrapper py-0 page__content container"
           :key="index">
        <div class="simple-content"
             v-html="section.fields.content"/>
      </div>
    </template>
  </main>
  <Loader v-else/>
</template>
<script>
import {butter} from "@/buttercms";
import FeaturedImageBoxes from '@/components/global/FeaturedImageBoxes.vue'
import ImageContentRowNew from "@/components/global/ImageContentRowNew.vue";

export default {
  name: 'Page',
  metaInfo() {
    return {
      title: (this.page.name ? this.page.name + ' | Bayview Village' : 'Bayview Village')
    }
  },
  components: {ImageContentRowNew, FeaturedImageBoxes},
  props: {
    headerCaption: {
      type: Boolean,
      default: true
    },
    alias: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    return {
      page: {}
    }
  },
  computed: {
    sections() {
      if (this.page.fields === undefined || this.page.fields.content === undefined)
        return []
      let sections = this.page.fields.content
      sections.forEach((section) => {
        if (section.type === 'content' && section.fields.content) {
          section.fields.content = section.fields.content.replace(/\r?\n|\r/g, '')
        } else if (section.type === 'featured_image_boxes') {
          section.fields.title = this.page.name
          if (this.page.slug === 'directions') {
            section.fields.featured_image = 'https://www.google.com/maps/embed/v1/place?q=Bayview%20Village%20Shopping%20Centre&key=AIzaSyARwsBiL0cZi1P1wtqTlisIfSpHwGQDWtM'
          }
        }
      })
      return sections
    },
  },
  methods: {
    async initializePage(slug) {
      const response = (
          await butter.page.retrieve('general_page', slug, {'levels': 2})
      ).data

      this.page = response.data
    }
  },
  created() {
    this.initializePage(this.alias || this.$route.path.split('/')[1])
  },
  watch: {
    '$route.path': function () {
      this.initializePage(this.alias || this.$route.path.split('/')[1])
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  text-align: left;

  &__header-image {
    @media (max-width: 575px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__info {
    text-transform: uppercase;

    a:hover {
      text-decoration: none;
    }

    h1 {
      font-size: 2.4rem;
      margin-bottom: 0;
    }

    h2 {
      color: #D9017A;
      font-size: 1.3rem;
    }

    .page__date {
      font-size: 1.3rem;
    }
  }

  ::v-deep {
    .caption-box {
      &__caption-text {
        font-size: 1.8rem;
      }
    }

    .page__content .simple-content {
      h2 {
        font-size: 2.4rem;
        margin-bottom: 2rem;

        &:not(:first-child) {
          margin-top: 5rem;
        }
      }

      h3 {
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-top: 3rem;
        margin-bottom: 1rem;
      }

      .simple-content {
        margin-bottom: 5rem;
      }
    }

    ul {
      font-family: Cardo, serif;
      margin-bottom: 15px;

      li {
        text-align: left;
      }
    }
  }

  .image-gallery,
  .product-gallery {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>
