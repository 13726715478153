import { render, staticRenderFns } from "./VignetteDetail.vue?vue&type=template&id=5e79cbb5&scoped=true"
import script from "./VignetteDetail.vue?vue&type=script&lang=js"
export * from "./VignetteDetail.vue?vue&type=script&lang=js"
import style0 from "./VignetteDetail.vue?vue&type=style&index=0&id=5e79cbb5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e79cbb5",
  null
  
)

export default component.exports