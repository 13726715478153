import { render, staticRenderFns } from "./BlogPost.vue?vue&type=template&id=19bf82cc&scoped=true"
import script from "./BlogPost.vue?vue&type=script&lang=js"
export * from "./BlogPost.vue?vue&type=script&lang=js"
import style0 from "./BlogPost.vue?vue&type=style&index=0&id=19bf82cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19bf82cc",
  null
  
)

export default component.exports