<template>
    <b-container tag="section" class="directory-map">
        <StoreMap id="directory-map-svg"/>
        <div class="map-store-name" v-if="active"
             :style="{ top: mapStoreNamePosition.top + 'px', left: mapStoreNamePosition.left + 'px'}">
            {{active}}
        </div>
        <div v-if="selected" class="shop-popup"
             :style="{ top: mapInfoBoxPosition.top + 'px', left: mapInfoBoxPosition.left + 'px'}">
            <div v-on:click="closeBox" class="close-directory-popup">
                <span>x</span>
            </div>
            <b-row>
                <b-col cols="4">
                    <ImageObject class="shop-photo"
                                 :src="selected.featured_image"
                                 :alt="selected.featured_image_alt"/>
                    <router-link :to="'/store/' + selected.slug" class="shop-popup-see-profile">
                        see profile
                    </router-link>
                </b-col>
                <b-col cols="8">
                    <h2 class="store-title font-title">{{selected.name}}</h2>
                    <h3>Hours</h3>
                    <p class="store-hours" v-html="selected.hours"></p>
                    <h3 v-if="selected.website">Website</h3>
                    <p v-if="selected.website"><a
                            :href="selected.website.slice(4) === 'http' ? selected.website : 'http://' + selected.website"
                            target="_blank">{{selected.website.replace('http://','').replace('https://','').split('/')[0]}}
                    </a></p>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
    /*import {mapState} from 'vuex'*/
    import StoreMap from '@/assets/newmap.svg'

    export default {
        props: ['activeStoreLocation', 'retailers'],
        components: {
            StoreMap
        },
        data: function () {
            return {
                active: false,
                selected: false,
                mapStoreNamePosition: {
                    top: 0,
                    left: 0
                },
                mapInfoBoxPosition: {
                    top: 0,
                    left: 0
                }
            }
        },
        methods: {
            getLocationId: function (className) {
                return className.split('_').splice(-1)[0]
            },
            getStoreFromLocation: function (location) {
                let filterResult = this.retailers.filter(function (elem) {
                    if (elem.location === location) return elem
                })

                return filterResult.length === 1 ? filterResult[0] : false
            },
            handleMouseOver: function (locationId) {
                if (locationId) {
                    let store = this.getStoreFromLocation(locationId)
                    this.active = store && (!this.selected || this.selected.name !== store.name) ? store.name : false
                } else {
                    this.active = false
                }
            },
            handleMouseOut: function () {
                this.active = false
            },
            handleMouseMove: function (event) {
                let svg = document.getElementById('directory-map-svg').getBoundingClientRect()
                this.mapStoreNamePosition.left = event.pageX - svg.left + 15
                this.mapStoreNamePosition.top = event.pageY - svg.top - window.pageYOffset - 10
            },
            handleItemClick: function (locationId) {
                if (locationId) {
                    this.active = false
                    this.selected = this.getStoreFromLocation(locationId)
                } else {
                    this.selected = false
                }
            },
            closeBox: function () {
                this.selected = false
            },
            bindStores: function (svg) {
                let closure = this
                svg.querySelectorAll("[id^='Group-'] rect, [id^='Group-'] polygon, [id^='Group-'] path")
                    .forEach(item => {
                        item.addEventListener('mouseover', event => {
                            closure.handleMouseOver(closure.getLocationId(event.target.id))
                        })
                        item.addEventListener('mouseout', event => {
                            closure.handleMouseOut(event)
                        })
                        item.addEventListener('mousemove', event => {
                            closure.handleMouseMove(event)
                        })
                        item.addEventListener('click', event => {
                            closure.setMapInfoBoxPosition(event.target)
                            closure.handleItemClick(closure.getLocationId(event.target.id))
                        })
                    })
            },
            setMapInfoBoxPosition: function (target) {
                let popupWidth = 425
                let unit = target.getBoundingClientRect()
                let svg = document.getElementById('directory-map-svg').getBoundingClientRect()

                this.mapInfoBoxPosition.left = unit.left + unit.width / 2 - svg.left + 10
                this.mapInfoBoxPosition.top = unit.top + unit.height / 2 - svg.top - 10

                // Check if there is room to display the popup on the left side of the item.
                if (this.mapInfoBoxPosition.left > svg.width / 2) {
                    this.mapInfoBoxPosition.left = this.mapInfoBoxPosition.left - popupWidth - 20
                }
            },
            setActiveLocation(id) {
                let allLocations = document.querySelectorAll("[id^='Group-'] rect, [id^='Group-'] polygon, [id^='Group-'] path")
                if (allLocations) {
                    allLocations.forEach(item => {
                        item.classList.remove('active-profile')
                    })
                }
                let activeStore = document.getElementById(id)
                if (activeStore) {
                    activeStore.classList.add('active-profile')
                }
            },
            prepareMap() {
              this.closeBox()
              this.bindStores(document.getElementById('directory-map-svg'))
              if (this.activeStoreLocation) {
                this.setActiveLocation(this.activeStoreLocation)
              }
            }
        },
        mounted() {
            // let closure = this
            // if (this.$store.state.directory.length === 0) {
            //   this.$store.dispatch('LOAD_API', {
            //     name: 'directory',
            //     url: 'vendors?sort=title'
            //   }).then(function() {
            //     closure.prepareMap();
            //   })
            // }
            // else
              this.prepareMap();
        },
        watch: {
            activeStoreLocation: function (newVal) {
                this.closeBox()
                if (newVal && newVal.length > 0) {
                    this.setActiveLocation(newVal)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

    svg#directory-map-svg {
        g[id^='Group-'] {
            rect, polygon, path:not([id*='-Label']) {
                pointer-events: fill;
                cursor: pointer;

                &:hover,
                &.active-profile {
                    fill: #D9017A;
                }
            }

            path[id*='-Label'] {
              pointer-events: none;
            }

            text {
                pointer-events: none;
            }
        }
    }

    .directory-map {
        position: relative;
        overflow: auto;

        .map-store-name {
            padding: 4px 8px;
            position: absolute;
            margin-bottom: 15px;
            margin-left: 10px;
            background: #FFFFFF;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            box-shadow: 2px 2px 5px;
            pointer-events: none;
        }

        .map-store-name:after, .map-store-name:before {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        .map-store-name:after {
            border-color: rgba(213, 0, 0, 0);
            border-right-color: white;
            border-width: 4px;
            margin-top: -4px;
        }

        .map-store-name:before {
            border-color: rgba(0, 0, 0, 0);
            border-right-color: #070707;
            border-width: 5px;
            margin-top: -5px;
        }

        .shop-popup {
            position: absolute;
            top: 0;
            left: 0;
            margin: auto;
            @media (max-width: 991px) {
              position: fixed;
              left: 0 !important;
            }
            min-height: 185px;
            width: 100%;
            max-width: 425px;
            padding: 15px 5px 15px 15px;
            float: left;
            background: #FFFFFF;
            color: #070707;
            box-shadow: 2px 2px 5px;
            z-index: 999999;

            .photo-container {
            }

            .shop-photo {
                height: 105px;
                width: 105px;
                object-fit: contain;
                object-position: center;
            }

            .shop-popup-see-profile {
                text-transform: uppercase;
                font-size: 1.4rem;
            }

            h2, h3 {
                font-size: 1.3rem !important;
                text-transform: uppercase;
                text-align: left;
                line-height: 110%;
                margin-top: 10px;
                margin-bottom: 2px;
                color: #070707;
            }

            h2 {
                font-size: 1.7rem !important;
            }

            .store-title {
                margin-top: 0;
                margin-bottom: 10px;
            }

            p {
                font-size: 1.3rem;
                line-height: 1.4em;
                margin: 0;
                text-align: left;
            }

            .close-directory-popup {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                text-align: center;
                font-size: 13px;
                line-height: 10px;
                padding: 6px;
                z-index: 10;
                background: #FFFFFF;
                border-bottom: 1px solid #AAAAAA;
                border-left: 1px solid #AAAAAA;
            }

            .close-directory-popup:hover {
                background-color: #AAAAAA;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 991px) {
        svg#directory-map-svg {
            width: 150%;
        }
    }

    @media (max-width: 767px) {
        svg#directory-map-svg {
            width: 200%;
        }
    }

    @media (max-width: 575px) {
        svg#directory-map-svg {
            width: 250%;
        }
    }
</style>
