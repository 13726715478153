<template>
  <div class="success-message" v-if="successMessage">Thank you!</div>
  <form v-else class="newsletter-form" v-on:submit.prevent="handleSubmit" action="/aislelabs">
    <input type="email" v-model="email" name="email" placeholder="Enter email address . . ." aria-label="Email address" required>
    <input type="hidden" name="referral_url" :value="referralURL">
    <input type="submit" :value="buttonText" class="btn-primary" :disabled="!submitEnabled">
    <div class="mt-4 form-messages">
      <div v-if="errorMessage">
        <div class="py-2 px-3 alert-danger">{{errorMessage}}</div>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: 'Sign Up'
    }
  },
  data() {
    return {
      email: '',
      submitEnabled: true,
      successMessage: false,
      errorMessage: false,
      referralURL: window.location.href
    }
  },
  methods: {
    showFormMessage(message) {
      if (message === true) {
        this.errorMessage = false
        this.successMessage = true
        let closure = this
        setTimeout(function () {
          closure.successMessage = false
        }, 2000)
      } else
        this.errorMessage = message

    },
    handleSubmit(event) {
      const closure = this
      this.submitEnabled = false
      this.$store.dispatch('POST_TO_API', event).then(function (data) {
        if (!data || !data.type) {
          closure.showFormMessage('There was a problem submitting the form. Please try again.')
          closure.submitEnabled = true
        }
        else if (data.type === 'error') {
          closure.showFormMessage(data.message)
          closure.submitEnabled = true
        }
        else if (data.type === 'success') {
          closure.showFormMessage(true)
          closure.$emit('success', true)
          setTimeout(function () {
            closure.email = ''
            closure.submitEnabled = true
          }, 2000)
        }
      }).catch(function (message) {
        closure.showFormMessage(message)
        closure.submitEnabled = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.newsletter-form {
  input[type=email] {
    padding: 10px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    width: 60%;
  }

  input[type=submit] {
    padding: 12px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    -webkit-appearance: button;
    cursor: pointer;
    background: #e11776;
    border: 0;
  }
}

.success-message {
  min-height: 42px;
  color: #e11776;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
}
</style>
