<template>
    <b-container tag="section" class="top-navigation px-0 px-xl-4">
        <a href="#main" class="skip-to-content">Skip to content</a>
        <b-navbar toggleable="xl" type="light">
            <b-navbar-brand tag="router-link" to="/" class="d-xl-none">
                <img src="../assets/bayview-village-logo-pink.png" alt="Bayview Village logo"/>
            </b-navbar-brand>
<!--            <div class="icon__mobile d-xl-none">
                <b-icon v-on:click="openCart()" class="px-0 d-xl-none" icon="cart2"/>
                <span class="cart__quantity" v-show="cart.totals.itemsQuantity > 0">{{cart.totals.itemsQuantity}}</span>
            </div>-->
            <b-navbar-toggle class="nav-toggle" target="nav-collapse" v-on:click="showMenu = !showMenu"></b-navbar-toggle>
            <b-collapse is-nav :class="[{show: showMenu}]">
                <b-navbar-nav align="right" v-on:click="showMenu = false">
<!--                    <li v-on:click="openCart()" class="nav-item cart__button px-0 d-none d-xl-block">
                        <a href="#" class="nav-link" id="nav-cart-icon" title="Open cart">
                            <b-icon class="cart__icon" icon="cart2" alt="Cart icon"/>
                            <span class="cart__quantity" v-show="cart.totals.itemsQuantity > 0">{{cart.totals.itemsQuantity}}</span>
                        </a>
                    </li>-->
                    <b-nav-item tag="router-link" to="/directory">Directory</b-nav-item>
<!--                    <b-nav-item tag="router-link" to="/gastronomer">Gastronomer</b-nav-item>-->
                    <b-nav-item tag="router-link" to="/directions">Visit Us</b-nav-item>
<!--                    <b-nav-item tag="router-link" to="/occupancy">
                        Capacity
                        <div class="menu-capacity" v-if="occupancyPercent"><span class="menu-capacity__bullet"></span>{{occupancyPercent}}%
                            <span class="menu-capacity__live">LIVE</span></div>
                    </b-nav-item>-->
                    <b-nav-item tag="router-link" to="/services">Guest Services</b-nav-item>
                </b-navbar-nav>
                <b-navbar-brand tag="router-link" to="/" class="d-none d-xl-inline-block" v-on:click="showMenu = false">
                    <img src="../assets/bayview-village-logo-pink.png" alt="Bayview Village logo"/>
                </b-navbar-brand>
                <b-navbar-nav align="left" v-on:click="showMenu = false">
                    <b-nav-item tag="router-link" to="/leasing">Leasing</b-nav-item>
                    <b-nav-item tag="router-link" to="/employment">Employment</b-nav-item>
                    <b-nav-item href="http://www.futureofbvshops.com" target="_blank">Future of BV</b-nav-item>
<!--                  <li v-on:click="openWishList()" class="nav-item cart__button pl-3 d-none d-xl-block">
                    <a href="#" class="nav-link" id="nav-cart-icon" title="Open Wish List">
                      <b-icon class="cart__icon" icon="gift" alt="Gift icon"/>
                    </a>
                  </li>-->
                </b-navbar-nav>
                <b-navbar-nav align="left" class="d-xl-none" v-on:click="showMenu = false">
                    <b-nav-item tag="router-link" to="/Magazine">Magazine</b-nav-item>
                    <b-nav-item tag="router-link" to="/hautelisted">Hautelisted</b-nav-item>
                    <b-nav-item tag="router-link" to="/blog">BV Blog</b-nav-item>
                    <b-nav-item tag="router-link" to="/ask-the-expert">Ask the Expert</b-nav-item>
                    <b-nav-item tag="router-link" to="/lifestyle-files">Lifestyle Files</b-nav-item>
                    <b-nav-item tag="router-link" to="/hautecast">The Hautecast</b-nav-item>
                    <b-nav-item tag="router-link" to="/media-room">Media Room</b-nav-item>
                    <b-nav-item tag="router-link" to="/events">Events & Promotions</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </b-container>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'TopNavigation',
        data() {
            return {
                showMenu: false
            }
        },
        methods: {
            openCart() {
                if (this.$router.currentRoute.path === '/checkout') {
                    this.$router.push({path: '/'})
                }
                this.$store.commit('SET_STATE', {name: 'cartDrawerOpen', data: true});
                if (this.$store.state.userNavigatingByTab === true)
                    document.getElementById('cart-close-button').focus();
            },
            openWishList() {
              this.$store.commit('SET_STATE', {name: 'wishListDrawerOpen', data: true});
              if (this.$store.state.userNavigatingByTab === true)
                document.getElementById('cart-close-button').focus();
            }
        },
        computed: {
            ...mapState({
                cart: state => state.cart
            /*,    occupancy: state => state.occupancy,*/
                /*capacity: state => state.capacity*/
            })/*,
            occupancyPercent() {
                if (!this.occupancy.occupancy)
                    return false;

                let occupancyPercent = this.occupancy.occupancy / this.capacity * 100

                return Math.round(occupancyPercent)
            }*/
        }
    }
</script>

<style>
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
</style>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .navbar {
        padding: 0;
    }

    .navbar-light .navbar-nav a.nav-link {
        color: #070707;
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    .navbar-nav {
        width: 38%;
        justify-content: space-between !important;

        .nav-item {
            position: relative;


            a.nav-link {
                padding: 0 1.3em;
            }
        }
    }

    .navbar-toggler {
        border: 0;

        .navbar-toggler-icon {
            color: #070707;
        }

        font-size: 25px;
        font-weight: bold;
    }

    @include media-breakpoint-down(lg) {
        .navbar-nav {
            width: 100%;
            display: block;

            &:first-child {
                padding-top: 15px;
            }

            &:last-child {
                border-bottom: 1px solid #888888
            }
        }
        .nav-item {
            display: block;
            width: 100%;
            padding: 15px;
            border-top: 1px solid #888888;
        }
    }

    .navbar-brand {
        width: 55%;
        @include media-breakpoint-up(md) {
            width: 24%;
        }
        padding: 0 1em;
        margin: 0;

        img {
            width: 100%;
        }
    }

    .navbar-collapse {
        max-height: calc(100vh - 200px);
        overflow: auto;
        &.show {
            display: block !important;
        }
    }

    .cart {
        &__button {
            position: relative;
            margin-top: -5px;

            > a.nav-link {
                padding: 0 !important;
                font-size: 1.6rem !important;
            }
        }

        &__quantity {
            position: absolute;
            top: -0.8rem;
            right: -0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #D9017A;
            color: #FFFFFF;
            font-size: 1.1rem;
            width: 1.7rem;
            height: 1.7rem;
            line-height: 1em;
            font-weight: bold;
            border-radius: 50%;
        }

    }

    .icon__mobile {
        position: relative;
    }

    .skip-to-content {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        border: 2px solid #D9017A;
        padding: 8px;
        outline: none;
    }

    .skip-to-content:focus {
        transform: translateX(0%);
    }

    .menu-capacity {

      position: absolute;
      right: 5%;
      top: 50%;
      margin-top: -0.9rem;

      @media (min-width: 992px) {
        top: 100%;
        left: 0;
        right: unset;
        width: 100%;
        margin-top: 0;
      }

      font-size: 1.1rem;

        &__bullet {
            display: inline-block;
            background: #28b646;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            margin-right: 5px;
        }

        &__live {
            background: #D9017A;
            border-radius: 3px;
            padding: 2px 5px;
            color: #FFFFFF;
            font-weight: bold;
        }

    }

</style>
