<template>
  <b-container tag="section">
    <b-row>
      <b-col
          cols="12"
          :md="LinkBoxPosition === 'none' ? 12 : 9"
          class="mb-5"
          :order="LinkBoxPosition === 'left' ? 2 : 1">
        <iframe v-if="section.featured_image.includes('www.google.com/maps')" class="map-iframe"
                title="Google map of Bayview Village and surrounding area"
                src="https://www.google.com/maps/embed/v1/place?q=Bayview%20Village%20Shopping%20Centre&key=AIzaSyARwsBiL0cZi1P1wtqTlisIfSpHwGQDWtM"></iframe>
        <CaptionBox
            v-else
            :ratio="LinkBoxPosition === 'none' ? 45 : 65.5"
            :image="{
                           src: section.featured_image,
                           alt: section.featured_image_alt
                         }"
            :caption="section.title"
            captionPosition="bottom-left"/>
      </b-col>
      <b-col
          v-if="LinkBoxPosition !== 'none'"
          cols="12"
          md="3"
          :order="LinkBoxPosition === 'left' ? 1 : 2">
        <b-row>
          <b-col cols="6" md="12" class="mb-5">
            <CaptionBox
                :ratio=100
                :image="{
                          src: section.top_image,
                          alt: section.top_image_alt
                        }"
                :caption="section.top_image_label"
                :link="{
                  route: section.top_image_link,
                  title: section.top_image_label
                }"
                captionPosition="bottom-left"/>
          </b-col>
          <b-col cols="6" md="12" class="mb-5">
            <CaptionBox
                :ratio=100
                :image="{
                          src: section.bottom_image,
                          alt: section.bottom_image_alt
                        }"
                :caption="section.bottom_image_label"
                :link="{
                   route: section.bottom_image_link,
                   title: section.bottom_image_label
                 }"
                captionPosition="top-left"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'FeaturedImageBoxes',
  props: {
    section: Object
  },
  computed: {
    LinkBoxPosition() {

      if(this.section.top_image.length > 0 && this.section.bottom_image.length > 0) {
        if (this.section.link_box_position) {
          return this.section.link_box_position
        }
        return 'right'
      }
      return 'none'
    }
  }
}
</script>
<style lang="scss" scoped>
.map-iframe {
  border: none;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    height: 50vh;
  }
}
</style>
