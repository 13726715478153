<template>
  <main id="main" class="condo">
    <b-container tag="section" class="condo__header mb-5">
      <b-row>
        <b-col cols="12">
          <h1>Haute Lives Here.</h1>
        </b-col>
      </b-row>
    </b-container>
    <form id="condo-form">
      <div class="condo__container mb-5 py-5 px-md-4">
        <b-container tag="form" class="condo__form">
          <b-row>
            <b-col cols="12" md="6">
              <label for="condo_firstname">First Name</label>
              <input type="text" id="condo_firstname" v-model="form.firstname" required/>
            </b-col>
            <b-col cols="12" md="6">
              <label for="condo_lastname">Last Name</label>
              <input type="text" id="condo_lastname" v-model="form.lastname" required/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <label for="condo_email">Email</label>
              <input type="email" id="condo_email" v-model="form.email" required/>
            </b-col>
            <b-col cols="12" md="6">
              <label for="condo_phone">Phone Number</label>
              <input type="tel" id="condo_phone" v-model="form.phone" required/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <label for="condo_address">Address</label>
              <input type="text" id="condo_address" v-model="form.address" required/>
            </b-col>
            <b-col cols="12" md="6">
              <label for="condo_address">Apartment/Suite Number</label>
              <input type="text" id="condo_address" v-model="form.address2"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <label for="condo_city">City</label>
              <input type="text" id="condo_city" v-model="form.city" required/>
            </b-col>
            <b-col cols="12" md="3">
              <label for="condo_province">Province</label>
              <select name="province" id="condo_province" v-model="form.province" required>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NT">Northwest Territories</option>
                <option value="NS">Nova Scotia</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
              </select>
            </b-col>
            <b-col cols="12" md="3">
              <label for="condo_postal">Postal Code</label>
              <input type="text" id="condo_postal" v-model="form.postal" required/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="6">
              <label for="condo_referrer">Where did you hear about us?</label>
              <select id="condo_referrer" v-model="form.referrer" required>
                <option value="agent">Agent/Broker</option>
                <option value="mail">Direct Mail</option>
                <option value="social">Facebook/Twitter/Instagram</option>
                <option value="online">Internet/Online Search</option>
                <option value="mls">MLS</option>
                <option value="newspaper">Newspaper</option>
                <option value="radio">Radio</option>
                <option value="referral">Referral</option>
                <option value="other">Other</option>
              </select>
            </b-col>
            <b-col cols="12" sm="6">
              <label>Are you a Broker or Agent?</label>
              <div class="radio-fields">
                <label for="condo_agent_yes">Yes</label>
                <input type="radio" id="condo_agent_yes" value="Yes" v-model="form.agent"/>
                <label for="condo_agent_no">No</label>
                <input type="radio" id="condo_agent_no" value="No" v-model="form.agent"/>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <button @click.prevent="submitForm" class="submit-button" type="submit">Submit</button>
            </b-col>
            <b-col cols="12">
              <p v-if="errorMessage.length > 0" v-html="errorMessage" aria-label="Error message"
                 class="error_message text-center"></p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </form>
  </main>
</template>
<script>
import axios from 'axios'

export default {
  name: 'CondoForm',
  data() {
    return {
      formId: '1FAIpQLSd7Ng4-4W-mMOKobxylIYT_iDjipmZ534fYbJEd4ANdZoQdrg',
      errorMessage: '',
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        address2: '',
        city: '',
        province: '',
        postal: '',
        referrer: '',
        agent: '',
        submit: 'Submit'
      },
      googleFields: {
        firstname: 'entry.1964194482',
        lastname: 'entry.345065524',
        email: 'entry.1000952425',
        phone: 'entry.2093093137',
        address: 'entry.820004955',
        address2: 'entry.392069454',
        city: 'entry.791809699',
        province: 'entry.1271783971',
        postal: 'entry.1483364616',
        referrer: 'entry.1192716583',
        agent: 'entry.834306461',
        submit: 'submit'
      }
    }
  },
  methods: {
    formatFields(fields) {
      let result = {}
      Object.keys(fields).forEach(key => {
        const value = fields[key]
        if (this.googleFields[key])
          result[this.googleFields[key]] = value
      });
      return result
    },
    SerializeForURL(obj) {
      let s = "";
      for (var key in obj) {
        if (s !== "") {
          s += "&";
        }
        s += (key + "=" + encodeURIComponent(obj[key]));
      }
      return s
    },
    handleSuccess() {
      this.errorMessage = 'Thank you for your interest! You now will be redirected.'
      document.getElementById("condo-form").reset();
      setTimeout(() => {
        this.$router.push('/')
        this.errorMessage = ''
      },2500)
    },
    /*handleFail(error) {
      this.errorMessage = 'There was an error submitting the form.'
      console.error('Form submission failed', error);
    },*/
    submitForm() {
      const formUrl = 'https://docs.google.com/forms/d/e/' + this.formId + '/formResponse';
      const fields = this.formatFields(this.form)
      this.errorMessage = ''
      //axios.post(formUrl, fields)
      axios.get(formUrl + '?' + this.SerializeForURL(fields))
          .then((response) => {
            this.handleSuccess(response)
          })
          .catch(error => {
            this.handleSuccess(error)
            //this.handleFail(error)
          });
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../utils/global.scss';

.condo {
  h1 {
    text-transform: uppercase;
    font-size: 3.5rem;
    font-weight: 300;
  }

  &__header {
    margin-top: 75px;
  }

  &__container {
    display: flex;
    max-width: 800px;
    margin: auto;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    border-left: 1px rgba(0, 0, 0, 0.05) solid;
    border-top: 1px rgba(0, 0, 0, 0.05) solid;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  }

  &__form {
    width: 100%;

    label:not([for^="condo_agent"]) {
      display: block;
      text-transform: uppercase;
      width: 100%;
      text-align: left;
      font-size: 1.2rem;
    }

    input, select {
      border: 1px rgba(229, 27, 128, 0.3) solid;
      border-radius: 0.75rem;
      width: 100%;
      padding: 0.5rem 1.5rem;
      margin-bottom: 2rem;
    }
  }

  .submit-button {
    background-color: #D9017A;
    border: 0;
    padding: 0.75em;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 1.7rem;
    line-height: 1.25em;
    font-weight: bold;
    width: max-content;
    border-radius: 0.75rem;
    text-decoration: none;
    margin: 15px auto;
  }

  &__submit-comment {
    color: #666666;
    font-size: 1.1rem;
  }

  .radio-fields {
    text-align: left;

    input, label {
      display: inline-block;
      width: fit-content;
      margin-right: 5px;
    }

    input {
      margin-right: 20px;
    }
  }

  .error_message {
    color: #D9017A;
    font-weight: bold;
  }
}
</style>
