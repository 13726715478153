<template>
  <b-container tag="main" id="main" class="hautelisted" v-if="page.fields !== undefined">
    <b-row id="gallery">
      <b-col cols="12" :md="$route.params.filter === undefined ? 12 : 6" class="mb-5">
        <CaptionBox
            :ratio="$route.params.filter === undefined ? 50 : 100"
            :image="featuredImage"
            captionTag="div"
            :caption="'<h1>' + (category ? category.name : page.name) +
            '</h1><p>' + (category ? category.description: page.fields.description) + '</p>'"
        ></CaptionBox>
      </b-col>
      <b-col v-if="$route.params.filter !== undefined" cols="12" md="6" class="hautelisted__slider mb-5">
        <ProductSlider :products="products" :slides="1" v-if="products.length > 0" ref="slider" :key="sliderIndex"
                       :showDescription="true"/>
      </b-col>
    </b-row>
    <b-row tag="ul" class="filter__row mb-5">
      <b-col tag="li" cols="12" sm="6" :md="true" class="filter__button">
        <router-link to="/hautelisted" title="Filter none">All</router-link>
      </b-col>
      <b-col
          v-for="category in page.fields.categories"
          :key="category.meta.id"
          tag="li" cols="12" sm="6" :md="true"
          class="filter__button">
        <router-link :to="'/hautelisted/'+ category.slug"
                     :title="'Filter ' + category.name + ' only'">
          {{ category.name }}
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" sm="4" md="3" lg="2"
             v-for="(product, index) in products"
             :key="index"
             class="product">
        <div class="product__image-container mb-5 order-3 order-lg-2">
          <div class="square-image">
            <a
                :href="'/hautelisted/' +
                (product.product_categories.length > 0? product.product_categories[0].slug : '')"
                :title="'Show ' + product.name"
                @click.prevent="showSlide(product)">
              <ImageObject :image="product.image" class="product__image"/>
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import ProductSlider from '@/components/NewProductSlider'
import {butter} from "@/buttercms";

export default {
  name: 'HauteListed',
  components: {ProductSlider},
  data() {
    return {
      sliderIndex: 1,
      page: {}
    }
  },
  computed: {
    category() {
      if (this.$route.params.filter) {
        let response = this.page.fields.categories.filter(category =>
            category.slug === this.$route.params.filter
        )
        return response[0]
      }
      return false
    },
    products() {
      if (this.page.fields === undefined)
        return []
      let products = JSON.parse(JSON.stringify(this.page.fields.products[0].fields.product_list || []))
      for (let i = 0; i < products.length; i++) {
        let product = products[i]
        if (product.retailer) {
          product.link = {
            route: '/store/' + product.retailer.slug,
            title: product.retailer.name + ' retailer profile',
            content: 'Go to Store'
          }
        }
        product.image = {
          src: product.featured_image,
          alt: product.featured_image_alt
        }
      }

      if (this.$route.params.filter && this.category) {
        return products.filter(product =>
                product.product_categories && product.product_categories.some(category =>
                    category.slug === this.$route.params.filter
                )
        )
      }
      return products
    },
    featuredImage() {
      if (this.$route.params.filter && this.category.featured_image) {
        return {
          src: this.category.featured_image,
          alt: this.category.featured_image_alt
        }
      }
      return {
        src: this.page.fields.featured_image,
        alt: this.page.fields.featured_image_alt
      }
    }
  },
  methods: {
    async initializeListing() {
      const response = (
          await butter.page.retrieve('*', 'hautelisted', {'levels': 3})
      ).data

      this.page = response.data
    },
    async showSlide(product) {
      const newPath = '/hautelisted/'+ product.product_categories[0].slug
      if(this.$route.path !== newPath)
        await this.$router.push(newPath)
      let slide = 1
      // Find clicked product in filtered products
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].meta.id === product.meta.id) {
          slide = i + 1
          break
        }
      }
      document.getElementById('gallery').scrollIntoView({behavior: "smooth"})
      if (this.$refs.slider) {
        this.$refs.slider.goToSlide(slide)
      }
    }
  },
  created() {
    this.initializeListing()
  },
  watch: {
    /*'products': function () {
      this.sliderIndex += 1
      if (document.getElementById('gallery'))
        this.showSlide(1)
    }*/
  }
}
</script>
<style lang="scss" scoped>
@import '../utils/global.scss';


.hautelisted {
  &__slider
  ::v-deep .product-slider {
    &__image {
      padding-bottom: 50%;
      width: 50%;
      margin: 30px auto;
    }

    &__description {
      margin-top: 15px;

      p {
        text-align: center;
        font-size: 1.4rem;
      }
    }

    &__cta-link {
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }

  ::v-deep .caption-box {
    @media (max-width: 575px) {
      margin-bottom: 30%;
    }

    &__inner {
      @media (max-width: 575px) {
        padding-bottom: 100% !important;
      }
    }

    &__caption {
      @media (max-width: 575px) {
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .15);
        left: 50%;
        margin-left: -42.5%;
        bottom: -40%;
      }

      @media (max-width: 575px) and (min-width: 400px) {
        bottom: -20% !important;
      }

      @media (max-width: 767px) and (min-width: 575px) {
        max-width: 50% !important;
      }

      @media (min-width: 992px) {
        max-width: 50% !important;
      }

      text-align: left;

      h1 {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.4rem;
        text-transform: none;
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .product {
    /* Display 5 columns on >= lg to make 2 rows of 5 */
    @media (min-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .square-image {
      border: #E5E5E5 solid 1px;
      transition: .1s;

      a {
        cursor: pointer;
      }

      img {
        top: 15px;
        left: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        object-fit: contain;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .filter {
    &__row {
      list-style: none;
      padding: 0;
    }

    &__button {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #070707;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 0.75em;
        text-decoration: none;

        @include media-breakpoint-only(xs) {
          &:not(:first-child) {
            border-top: none;
          }
        }

        @include media-breakpoint-only(sm) {
          &:not(:first-child):not(:nth-child(2)) {
            border-top: none;
          }
          &:nth-child(odd) {
            border-right: none;
          }
        }

        @include media-breakpoint-up(md) {
          &:not(:first-child) {
            border-left: none;
          }
        }
      }

      &:hover a:not(.router-link-exact-active) {
        background: #F3F3F3;
      }

      .router-link-exact-active {
        background: #D9017A;
        color: #FFFFFF;
      }
    }
  }
}
</style>
