<template>
  <b-container v-if="gallery.fields !== undefined">
    <b-row>
      <b-col v-if="$route.params.id !== undefined">
        <VignetteDetail :vignette="vignette($route.params.id)"/>
      </b-col>
      <b-col cols="12" class="my-2" v-else>
        <h1 v-if="slug === 'uptown-girls'" class="gallery_header gallery_header_uptown mb-5"><span>Uptown Girls</span><br><i>Spend a day in the life with us</i></h1>
        <h1 v-else-if="slug === 'holiday-gift-guide'" class="gallery_header gallery_header_uptown"><i>Season's Greetings From</i><br><span>Bayview Village</span></h1>
        <h1 v-else class="gallery_header"><i>Breakfast</i> at <span>Bayview</span></h1>
        <div v-if="slug === 'uptown-girls'" class="main_video">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/4RJ9aC2bYlE?si=cJN2BlqH75w67PhQ"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>
        </div>
      </b-col>
    </b-row>
    <h2 v-if="slug === 'uptown-girls'" class="gallery_header gallery_header_uptown my-5"><span><i>Get to know the Uptown Girls</i></span></h2>
    <VignetteGallery v-if="gallery.fields" :vignettes="gallery.fields.vignettes"/>
    <div class="gallery_description" v-if="slug === 'holiday-gift-guide'">
      <p>Click above for all the gift ideas you’re going to need this holiday season!</p>
    </div>
  </b-container>
</template>

<script>
import {butter} from "@/buttercms";
import VignetteDetail from "@/components/VignetteDetail.vue"
import VignetteGallery from "@/components/VignetteGallery.vue"

export default {
  name: "VideoCardView",
  components: {
    VignetteDetail, VignetteGallery
  },
  data() {
    return {
      gallery: {}
    }
  },
  computed: {
    slug() {
      return this.$route.path.split('/')[1]
    }
  },
  methods: {
    async getGallery(slug) {

      const response = (
          await butter.page.retrieve('vignette_gallery', slug)
      ).data
      this.gallery = response.data
    },
    vignette(slug) {
      return this.gallery.fields.vignettes.filter(vignette => vignette.fields.slug === slug)[0].fields
    }
  },
  created() {
    this.getGallery(this.$route.path.split('/')[1])
  }
};
</script>

<style lang="scss" scoped>
.gallery_header {
  color: #D9017A;
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: 100;

  i {
    font-size: 4rem;
    font-family: Cardo, serif;
    display: block;
    text-transform: none;
    margin: 0.25rem;
  }

  span {
    font-size: 4.5rem;
    display: block;
    letter-spacing: 1rem;
    margin: 0.25rem;
  }

  &_uptown {
    i {
      display: inline-block;
    }

    span {
      display: inline-block;
      letter-spacing: 0rem;
    }
  }
}

.gallery_description p {
  font-size: 32px;
  text-align: center;
  margin: 2rem 0 5rem 0;
  color: #d9017a;
}

.main_video {
  width: calc(100% - 2rem);
  padding-bottom: 56.25%;
  position: relative;
  margin-top: 2rem;
  margin-left: 1rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
