<template>
  <b-container tag="main" id="main" class="page py-0">
    <CaptionBox
        :ratio=50
        :image="{
          src: 'https://cdn.buttercms.com/WoqRte6UTbuBmzf5BkCC',
          alt: 'Several pairs of colourful running shoes facing each other'
        }"
        caption="Employment Opportunity"
        captionTag="h2"
        captionPosition="top-left"
        class="mb-5"/>
    <div v-if="posting && posting.title">
      <div class="page__info mb-5">
        <h1>{{ posting.title }}</h1>
        <h2>Employer:
          <router-link
              v-if="posting.retailer"
              :to="'/store/' + posting.retailer.slug"
              :title="posting.retailer.name">
            {{ posting.retailer.name }}
          </router-link>
        </h2>
        <div class="page__date">{{ posting.publish_date | date }}</div>
      </div>
      <div class="page__content" v-html="posting.description"></div>
    </div>
    <Loader v-else/>
  </b-container>
</template>
<script>
import {butter} from "@/buttercms";

export default {
  name: 'SimplePage',
  data() {
    return {
      posting: {}
    }
  },
  methods: {
    async getPosting(id) {
      const response = (
          await butter.content.retrieve(['job_posting[_id=' + id + ']'])
      ).data
      this.posting = response.data.job_posting[0]
    }
  },
  created() {
    this.getPosting(this.$route.params.id)
  }
}
</script>
<style lang="scss" scoped>
.page {
  &__info {
    text-transform: uppercase;
    text-align: left;

    a:hover {
      text-decoration: none;
    }

    h1 {
      font-size: 2.4rem;
      margin-bottom: 0;
    }

    h2 {
      color: #D9017A;
      font-size: 1.3rem;
    }

    .page__date {
      font-size: 1.3rem;
    }
  }

  &__content {
    ::v-deep h1 {
      text-align: left;
      font-size: 2.4rem;
      text-transform: uppercase;
    }

    ::v-deep h2, ::v-deep h3 {
      text-align: left;
    }

    ::v-deep ul {
      font-family: Cardo, serif;
      margin-bottom: 15px;

      li {
        text-align: left;
      }
    }
  }
}
</style>
