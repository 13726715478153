// Vue
import Vue from 'vue'

Vue.config.productionTip = false

// Utilities
import '@/utils'

// Global Components
import '@/utils/components.js'

// Configuration
//import config from '@/config'
Vue.use(require('vue-moment'));

Vue.use(require('vue-cookies'))

// BootstrapVue
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Truncate
var VueTruncate = require('vue-truncate-filter')
Vue.use(VueTruncate)

// YouTube
import VueYoutube from 'vue-youtube';
Vue.use(VueYoutube);


// Vue Router
import VueRouter from 'vue-router'
import routes from '@/router'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        return savedPosition || {x: 0, y: 0}
    }
})

// Vuex
import store from '@/store'

// Restore cart on refresh
let localCartString = localStorage.getItem('cartItems')
if (localCartString !== null) {
    let localCartItems = JSON.parse(localCartString)
    if (store.state.cart.items !== localCartItems) {
        store.commit('SET_STATE', {name: 'cart', id: 'items', data: localCartItems})
    }
}

// Change page title based on router meta
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title + ' | Bayview Village' : 'Bayview Village'
    next()
})

// Vue Meta
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

// Google Analytics gTag
import VueGtag from "vue-gtag";

if (location.host.indexOf('localhost') === -1 && location.host.indexOf('appspot') === -1) {
    Vue.use(VueGtag, {
            config: {id: "G-0Y6FEB7BXY"}
        }, router
    );
}

// Render
import AppView from '@/App'

new Vue({
    router,
    store: store,
    render: h => h(AppView)
}).$mount('#app')
