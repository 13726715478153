<template>
    <b-container tag="section" class="products">
        <b-row v-show="routerSection">
            <b-col class="mb-5">
                <h2>{{vendorFilter.name}}</h2>
            </b-col>
        </b-row>
        <b-row tag="ul">
            <b-col tag="li" v-for="product in filteredProducts" :key="product.id" cols="12"
                   :sm="itemsPerRow[productsPerRow].sm"
                   :lg="itemsPerRow[productsPerRow].lg"
                   :class="['mb-5',
                   {'d-none': vendorFilter && product.vendorAliasArray.indexOf(vendorFilter.alias) === -1 && product.vendorIdArray.indexOf(vendorFilter.id) === -1},
                   {'d-none': themeFilter && product.taxonomyArray.indexOf(themeFilter) === -1}]">
                <Item :item="product"/>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    import Item from '@/components/Item'
    //import Item from '@/components/ItemNew'

    export default {
        name: 'Products',
        components: {
            Item
        },
        props: {
            products: [Array, Object],
            vendors: [Array, Object],
            productsPerRow: {
                type: Number,
                default: 3
            }
        },
        data() {
            return {
                itemsPerRow: {
                    3: {
                        sm: 6,
                        lg: 4
                    },
                    4: {
                        sm: 6,
                        lg: 3
                    }
                }
            }
        },
        computed: {
            routerSection() {
                return this.$route.params.section
            },
            filteredProducts() {
                let filteredProducts = this.products.filter(product => product.is_subsidiary === undefined) // Filter out subsidiaries
                filteredProducts.forEach((product, index) => {
                    filteredProducts[index].vendorAliasArray = []
                    filteredProducts[index].vendorIdArray = []
                    filteredProducts[index].taxonomyArray = []
                    if (product.vendors)
                        product.vendors.forEach(vendor => {
                            filteredProducts[index].vendorAliasArray.push(vendor.alias)
                            filteredProducts[index].vendorIdArray.push(vendor.id)
                        })
                    if (product.taxonomies)
                        product.taxonomies.forEach(taxonomy => {
                            filteredProducts[index].taxonomyArray.push(taxonomy.id)
                        })
                })
                return filteredProducts
            },
            vendorFilter() {
                if (!this.routerSection) {
                    return false;
                } else if (!this.getVendorFromId(this.routerSection)) {
                    return false;
                }
                return this.getVendorFromId(this.routerSection);
            },
            themeFilter() {
                if (!this.routerSection) {
                    return false;
                } else if (!this.getVendorFromId(this.routerSection)) {
                    return this.routerSection
                } else {
                    return false;
                }
            }
        },
        methods: {
            getVendorFromId(Id) {
                for (let i = 0; i < this.vendors.length; i++) {
                    if (this.vendors[i].id === Id || this.vendors[i].alias === Id) {
                        return this.vendors[i]
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .products {
        > ul {
            list-style: none;
            padding: 0;
        }

        .row {
            margin-left: -30px;
            margin-right: -30px;
        }

        [class*='col-'] {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
</style>
