import Vue from 'vue'

// Caption Box
Vue.component('CaptionBox',
    () => import('@/components/global/CaptionBox')
)

// Links
Vue.component('NavLink',
    () => import('@/components/global/NavLink')
)

// Images
Vue.component('ImageObject',
    () => import('@/components/global/ImageObject')
)

// Loader
Vue.component('Loader',
    () => import('@/components/global/Loader')
)