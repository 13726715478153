<template>
  <b-container tag="main" id="main" class="blog-roll">
    <div class="divider sidelines mb-5">
      <img :src="require('@/assets/heads/' + publicationGroup + '.png')"
           alt="Sketch of a woman's head.">
      <h2>{{ blogNames[publicationGroup] }}</h2>
    </div>
    <section class="list">
      <router-link v-for="post in posts"
                   :key="post.slug"
                   :to="{ name: publicationGroup + '-post', params: { slug: post.slug } }"
                   class="row list__item">
        <b-col cols="4" class="list__thumbnail">
          <div class="square-image list__thumbnail-image">
            <ImageObject :image="{src: post.featured_image, alt: post.featured_image_alt}"/>
          </div>
        </b-col>
        <b-col cols="8" class="list__details text-left">
          <h2>{{ post.title }}</h2>
          <p v-if="post.summary" class="list__description" v-html="post.summary"></p>
          <div class="list__cta d-none d-sm-block">Read More</div>
        </b-col>
      </router-link>
    </section>
    <button class="btn load-more" v-on:click="loadMore">Load more!</button>
  </b-container>
</template>
<script>
import {butter} from "@/buttercms"

export default {
  name: "BlogHome",
  data() {
    return {
      posts: [],
      page: 1,
      blogNames: {
        'blog': 'BV Blog',
        'lifestyle-files': 'Lifestyle Files',
        'ask-the-expert': 'Ask the Expert'
      }
    }
  },
  computed: {
    publicationGroup() {
      const blogSlug = this.$route.path.split('/')[1]
      if(this.blogNames[blogSlug]===undefined)
        return 'blog'
      return blogSlug
    }
  },
  methods: {
    async getBlog(reset) {
      if(reset === true) {
        this.page = 1
      }
      const response = (
          await butter.post.list({
            page: this.page,
            page_size: 10,
            category_slug: this.publicationGroup === 'blog' ? 'bv-blog' : this.publicationGroup
          })
      ).data
      if(reset === true) {
        this.posts = response.data
      } else {
        this.posts = this.posts.concat(response.data)
      }
    },
    loadMore() {
      this.page += 1
      this.getBlog()
    }
  },
  mounted() {
    this.getBlog()
  },
  watch: {
    '$route.path': function() {
      this.getBlog(true)
    }
  }
}

</script>
<style lang="scss" scoped>
.list {

  &__item:hover {
    text-decoration: none;
  }

  &__item:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;

    @media (min-width: 768px) {
      padding-bottom: 48px;
      margin-bottom: 48px;
    }

    @media (min-width: 992px) {
      padding-bottom: 60px;
      margin-bottom: 60px;
    }
  }

  &__thumbnail {

    @media (min-width: 992px) {
      padding: 0 60px;
    }

    img {
      width: 100%;
    }
  }

  &__details {
    color: #070707;

    h2 {
      font-size: 1.9rem;
      @media (min-width: 768px) {
        font-size: 2.4rem;
      }
    }
  }

  &__cta {
    color: #D9017A;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
}
.load-more {
  background-color: #d9017a;
  border: 0;
  border-radius: 7px;
  padding: .75em 1.5em;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.25em;
  font-weight: 700;
}


</style>
