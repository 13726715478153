<template>
    <div class="image-gallery row" v-if="images && newImages.length > 0">
        <div v-for="(image, index) in newImages" :key="index" class="col">
            <div :class="['section-content__inner', {singleImage: newImages.length < 2}]">
                <ImageObject v-if="image.src" :image="image"/>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'ImageContentRow',
        props: {
            images: Array,
        },
        computed: {
            newImages: function () {
                let images = JSON.parse(JSON.stringify(this.images || []))
                for (let i = 0; i < images.length; i++) {
                    images[i].src = 'https://v1.bayviewvillageshops.com' + images[i].content
                }
                return images
            }
        }
    }
</script>
<style lang="scss" scoped>
    .section-content {
        position: relative;

        &__inner {
            background: #E3E3E3;
            padding-bottom: 100%;
            position: relative;

            &.singleImage {
                padding-bottom: 50%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
</style>
