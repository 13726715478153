<template>
  <div class="simple-content" v-if="article && article.content">
    <h2 v-if="article.title && article.title !== ''" v-html="article.title"></h2>
    <div v-html="article.content"></div>
  </div>
</template>
<script>

  export default {
    name: 'SimpleContentRow',
    props: {
      article: Object
    }
  }
</script>
