<template>
  <div class="cookieconsent" v-if="modalOpen"><p>This website uses cookies to give you the best user experience, for analytics and to
    show you offers tailored to your interests on our site and third party sites. Please click the Privacy Policy
    link if you would like to learn more about the cookies used on this website. If you wish to
    disable cookies, please follow the instructions from your browser to make a change. Functional and technical
    cookies are necessary to operate the website and cannot be turned off.</p>
    <a v-on:click="dismissModal(0)" class="btn_continue">Agree</a>
    <a class="btn_privacy" href="/privacypolicy">Privacy Policy</a></div>
</template>
<script>
    export default {
        name: 'CookieModal',
        data: function () {
            return {
                modalOpen: false
            }
        },
        methods: {
            dismissModal(delay) {
              const closure = this
              if(!delay)
                delay = 0
              setTimeout(function () {
                closure.modalOpen = false
              }, delay)
              window.localStorage.setItem('cookie-modal', true)
            }
        },
        mounted() {
            if (!window.localStorage.getItem('cookie-modal'))
               this.modalOpen = true
        }
    }
</script>
<style lang="scss" scoped>

.cookieconsent {
  position: fixed;
  padding: 10px 30px;
  left: 0;
  bottom: 0;
  border: 4px solid rgb(217, 1, 122);
  background: #FFFFFF;
  color: #000000;
  text-align: center;
  width: 100%;
  font-size: 14px;
  z-index: 99999;
  font-family: sans-serif;
  line-height: 1.5em;
  box-sizing: border-box
}

.cookieconsent h1 {
  font-size: 2rem;
}

.cookieconsent p {
  max-width: 950px;
  margin: 10px auto
}

.cookieconsent a {
  display: inline-block;
  padding: 10px 30px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  margin: 5px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
}
</style>
