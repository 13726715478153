<template>
  <b-container tag="main" id="main" class="list-page py-0">
    <div class="divider sidelines my-5">
      <img src="@/assets/heads/lifestyle-files.png"
           alt="Sketch of a woman's head.">
      <h2>Events & Promotions</h2>
    </div>
    <List v-if="events.length > 0" :list="listings" class="pt-5"></List>
    <div v-else>
      Nothing at the moment!
    </div>
  </b-container>
</template>
<script>
import List from "@/components/List.vue"
import {butter} from "@/buttercms";

export default {
  name: 'Events',
  components: {List},
  data() {
    return {
      events: []
    }
  },
  computed: {
    listings() {
      //let events = this.events
      let events = this.events.filter(event => !this.$moment(event.end_date).isBefore(this.$moment()));

      if(events.length > 0) {
        for(let i=0;i<events.length;i++) {
          let event = events[i]

          event.start = this.$moment(event.start_date).format('MMMM D, YYYY')
          if(event.end_date !== event.start_date) {
            event.end = this.$moment(event.end_date).format('MMMM D, YYYY')
            if (event.end === event.start) {
              event.start = this.$moment(event.start_date).format('MMMM D, YYYY  h:mma')
              event.end = this.$moment(event.end_date).format('h:mma')
            }
          }
          event.vendors = []
          event.retailer.forEach((retailer) => {
            event.vendors.push({
              title: retailer.name,
              alias: retailer.slug
            })
          })

          if(event.vendors.length === 0) {
            event.vendors.push({
              title: 'Bayview Village',
              alias: 'bayview-village'
            })
          }


          if (event.image.length > 0) {
            event.image = {
              src: event.image,
              alt: event.image_alt
            }
          } else if(event.retailer.length > 0) {
            event.image = {
              src: event.retailer[0].featured_image,
              alt: event.retailer[0].featured_image_alt
            }
          }

          event.link = {
            route: '/store/' + event.vendors[0].alias,
            title: event.vendors[0].title
          }
        }
      }
      return events
    }
  },
  methods: {
    async initializePage() {
      const response = (
          await butter.content.retrieve(['event'], {order: 'start_date'})
      ).data
      this.events = response.data.event
    }
  },
  created() {
    this.initializePage()
  }
}
</script>
<style lang="scss" scoped>
.page {
  &__content {
    ::v-deep h1 {
      text-align: left;
      font-size: 2.4rem;
      text-transform: uppercase;
    }

    ::v-deep h2, ::v-deep h3 {
      text-align: left;
    }

    ::v-deep ul {
      font-family: Cardo, serif;
      margin-bottom: 15px;

      li {
        text-align: left;
      }
    }

  }
}
</style>
