<template>
  <b-container tag="main" id="main" class="media-item" v-if="mediaItem">
    <div class="text-left">
      <router-link to="/hautecast">Back to Hautecast</router-link>
    </div>
    <h1 v-html="mediaItem.title" class="text-left"></h1>
    <div class="podcast-showcase">
      <iframe :src="mediaItem.url" style="width:100%;height: 300px"
              :title="'Podcast viewer: ' + mediaItem.title"/>
    </div>
    <div v-if="mediaItem.description" v-html="mediaItem.description"></div>
  </b-container>
  <Loader v-else/>
</template>
<script>
import {butter} from "@/buttercms";

export default {
  name: 'HautecastItem',
  data() {
    return {
      mediaItem: {},
      mime: ''
    }
  },
  methods: {
    async getMediaArticle(id) {
      const response = (
          await butter.content.retrieve(['podcast'], {
            'fields.slug': id
          })
      ).data
      this.mediaItem = response.data.podcast[0]
    }
  },
  created() {
    this.getMediaArticle(this.$route.params.id)
  }
}
</script>
<style lang="scss" scoped>
.podcast-showcase {
  max-width: 100%;
}
</style>
