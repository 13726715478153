<template>
  <footer>
    <b-container class="mt-5 py-5">
      <b-row class="newsletter py-5">
        <b-col cols="12">
          <h2 class="text-center">Get the latest news and offers!</h2>
          <NewsletterForm buttonText="Go!" id="footer-newsletter-form"/>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="footer-wrapper">
      <b-row class="row">
        <b-col cols="6" md="3" class="footer-info mx-auto">
          <div><h2 class="margin-bottom-half">COMPANY</h2>
            <ul>
              <li class="active title">
                <router-link to="/about">About Us</router-link>
              </li>
              <li class="active title">
                <router-link to="/community">Community Relations</router-link>
              </li>
              <li class="active title">
                <router-link to="/employment">Employment</router-link>
              </li>
              <li class="active title">
                <router-link to="/leasing">Leasing</router-link>
              </li>
              <li class="active title">
                <router-link to="/media-room">Media Room</router-link>
              </li>
              <li class="active title">
                <router-link to="/contact">Contact Us</router-link>
              </li>
              <li class="active title">
                <router-link to="/privacypolicy">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col cols="6" md="3" class="footer-info mx-auto">
          <div><h2 class="margin-bottom-half">INFORMATION</h2>
            <ul>
              <li class="active title">
                <router-link to="/services">Guest Services</router-link>
              </li>
              <li class="active title">
                <router-link to="/services#valetparking">Valet Parking</router-link>
              </li>
              <li class="active title">
                <router-link to="/directions#hoursofoperation">Hours</router-link>
              </li>
              <li class="active title">
                <router-link to="/directory">Directory</router-link>
              </li>
              <li class="active title">
                <router-link to="/directions">Directions</router-link>
              </li>
              <li class="active title">
                <router-link to="/services#giftcards">Gift Cards</router-link>
              </li>
              <li class="active title">
                <router-link to="/events">Events & Promotions</router-link>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col cols="6" md="3" class="connect-mobile-footer mx-auto">
          <div><h2 class="margin-bottom-half">CONNECT</h2>
            <ul>
              <li class="active title"><a href="http://instagram.com/bvshops/" target="_blank" title="BV Instagram"><i
                  class="fa fa-instagram"></i>Instagram</a>
              </li>
              <li class="active title">
                <a href="http://www.pinterest.com/bvshops/" title="BV Pinterest" target="_blank">
                  <i class="fa fa-pinterest"></i>Pinterest
                </a>
              </li>
              <li class="active title"><a href="http://twitter.com/bvshops" title="BV Twitter" target="_blank"><i
                  class="fa fa-twitter"></i>Twitter</a></li>
              <li class="active title"><a href="http://www.facebook.com/BVShops" title="BV Facebook" target="_blank"><i
                  class="fa fa-facebook"></i>Facebook</a></li>
              <li class="active title"><a href="http://www.youtube.com/BVShops" title="BV YouTube" target="_blank"><i
                  class="fa fa-youtube"></i>YouTube</a></li>
            </ul>
          </div>
        </b-col>
        <b-col cols="12" class="connect-mobile-footer mx-auto">
          <div class="row text-center social-icons"><a href="http://instagram.com/bvshops/" title="BV Instagram"><i
              class="fa fa-instagram"></i></a><a
              href="http://www.pinterest.com/bvshops/" title="BV Pinterest"><i class="fa fa-pinterest"></i></a><a
              href="http://twitter.com/bvshops" title="BV Twitter"><i class="fa fa-twitter"></i></a><a
              href="http://www.facebook.com/BVShops" title="BV Facebook"><i class="fa fa-facebook"></i></a><a
              href="http://www.youtube.com/BVShops" title="BV YouTube"><i class="fa fa-youtube"></i></a></div>
        </b-col>
        <b-col cols="12" lg="3" class="visit-bv mx-auto"><h2>VISIT BV</h2>
          <div class="row">
            <b-col v-if="settings.footer_address"
                   cols="12"
                   class="visit-info px-4"
                   v-html="settings.footer_address">
            </b-col>
          </div>
        </b-col>
      </b-row>
      <div class="quadreal-logo mt-5 pt-5"><p style="margin-bottom: 10px;">Proudly managed by QuadReal Property Group,
        Brokerage</p><a href="http://www.quadreal.com" target="_blank" title="QuadReal Property Group"><img
          src="../assets/logo-quadreal.png" alt="QuadReal Logo"></a>
      </div>
      <CookieModal />
    </b-container>
  </footer>
</template>
<script>
import NewsletterForm from '@/components/global/NewsletterForm'
import CookieModal from '@/components/global/CookieModal'
import {butter} from "@/buttercms";

export default {
  name: 'Footer',
  components: {NewsletterForm, CookieModal},
  data() {
    return {
      settings: {}
    }
  },
  methods: {
    async getSettings() {
      const response = (
          await butter.page.retrieve('*', 'global-settings')
      ).data

      this.processObject(response.data.fields)
      this.settings = response.data.fields
    },
    removeNewLines(str) {
      return str.replace(/[\n\r]/g, '');
    },
    processObject(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = this.removeNewLines(obj[key]);
        } else if (typeof obj[key] === 'object') {
          this.processObject(obj[key]);
        }
      }
    }
  },
  created() {
    this.getSettings()
  }
}
</script>
<style lang="scss" scoped>
@import '../utils/global.scss';

footer ul {
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none
}

footer .footer-wrapper {
  padding-top: 60px;
  border-top: 1px solid #e5e5e5
}

@media (max-width: 767px) {
  footer {
    margin-top: 40px;
    padding-top: 40px
  }
}
footer .visit-info {
  padding: 0;
  &::v-deep p {
    text-align: center;
  }
}
@media (min-width: 992px) {
  footer .visit-info {
    padding: 0;
    &::v-deep p {
        text-align: left;
    }
  }
  footer::v-deep .visit-bv h1 {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 25px
  }
  footer .connect-mobile-footer {
    display: none
  }
}

footer ul li a {
  text-decoration: none;
  color: #070707;
  float: none;
  font-size: 16px
}

footer ul li a:hover {
  color: #D9017A;
  text-decoration: none
}

footer a {
  font-size: 16rem;
}

footer a, footer a:hover {
  text-decoration: none
}

footer p, footer a {
  font-family: Cardo, serif;
  margin: 0 0 5px;
  font-size: 1.6rem;
}

footer h2 {
  margin-top: 0;
  text-align: left;
  font-size: 2.2rem;
}

footer .fa {
  width: 25px
}

footer .bv-map {
  position: relative;
  height: 100px;
  overflow: hidden;
  margin-bottom: 30px
}

footer::v-deep .visit-bv a {
  text-decoration: none;
  color: #D9017A;
  font-size: 1.6rem;
}

footer .col-md-3.connect-mobile-footer {
  display: inherit
}

footer .col-xs-12.connect-mobile-footer {
  display: none
}

footer::v-deep .visit-info p {
  margin-bottom: 5px
}

footer .quadreal-logo {
  text-align: center;
  margin-bottom: 25px
}

footer .quadreal-logo p {
  margin-bottom: 8px;
  text-align: center;
}

footer .quadreal-logo a > img {
  max-width: 210px
}

@media (max-width: 991px) {
  footer .col-md-3.connect-mobile-footer {
    display: none
  }
  footer .col-xs-12.connect-mobile-footer {
    display: inherit
  }
  footer .footer-info h2, footer .footer-info li {
    text-align: center
  }
  footer .footer-info li a {
  }
  footer .connect-mobile-footer {
    margin: 20px 0
  }
  footer .bv-map {
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover
  }
  footer::v-deep .visit-bv h2 {
    text-align: center
  }
  footer::v-deep .visit-bv p {
    margin-bottom: 10px
  }
  footer .quadreal-logo p {
    font-size: 12px
  }
  footer .quadreal-logo a > img {
    max-width: 130px
  }
  footer .social-icons {
    padding: 15px 0;
    justify-content: center;

    a {
      font-size: 3rem;
      padding: 0 10px;
    }
  }
}

.connect-mobile-footer h2 {
  text-transform: uppercase;
  display: block;
}

.newsletter {

  &::v-deep {

    label {
      font-family: Cardo, serif;
      font-size: 2.2rem;
      padding-bottom: 10px;
    }

    .newsletter-form {
      display: flex;
      max-width: 500px;
      margin: auto;
    }
    .newsletter-form input[type=email] {
      flex: 1;
      padding: 11px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      border: 1px solid #e11776;
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
    }

    .newsletter-form input[type=submit] {
      flex: 0;
      padding: 12px 24px;
      font-size: 14px;
      text-transform: uppercase;
      font-family: Montserrat, sans-serif;
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
    }

    .btn-primary {
      background: #e11776;
      border: 0
    }

    .btn-primary:hover {
      background: #ca156a
    }

    .btn-primary:active:hover {
      background: #b3125e
    }
  }
}
</style>
