<template>
  <div class="modal-wrapper" v-if="modalOpen">
    <div class="modal-mask"></div>
    <div class="modal-box">
      <div class="modal-box__inner">
        <h2>Hello, Gorgeous!</h2>
        <p>Sign up to receive love notes from us.</p>
        <NewsletterForm buttonText="Sign Up" v-on:success="dismissModal(1500)" id="modal-newsletter-form"/>
        <a href="#" class="dismiss_button" v-on:click.prevent="dismissModal">No thanks. Take me to the site.</a>

        <p class="modal-box__disclaimer">By submitting your email, you agree to receive promotional and marketing emails
          from Bayview Village, 2901 Bayview Avenue, Toronto, Ontario, M2K 1E6. You may unsubscribe at any time. Your privacy
          is important to us. Learn more in our <a href="/privacypolicy">Privacy Policy</a>.</p>
      </div>
    </div>
  </div>
</template>
<script>
import NewsletterForm from '@/components/global/NewsletterForm'

export default {
  name: 'NewsletterModal',
  components: {NewsletterForm},
  data: function () {
    return {
      modalOpen: false
    }
  },
  methods: {
    dismissModal(delay) {
      const closure = this
      if (!delay)
        delay = 0
      setTimeout(function () {
        closure.modalOpen = false
      }, delay)
      window.localStorage.setItem('newsletter-modal', true)
    }
  },
  mounted() {
    if (!window.localStorage.getItem('newsletter-modal'))
      this.modalOpen = true
  }
}
</script>
<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  width: 90%;
  max-width: 600px;

  &__inner {
    width: 100%;
    padding: 60px 0 30px 0;

    h2 {
      font-size: 2.4rem;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.3rem;
      text-align: center;

      &.modal-box__disclaimer {
        display: block;
        padding: 2rem 4rem 0 3rem;
        margin: 0;
        text-align: left;
        color: #555555;
        font-size: 1.2rem;
        line-height: 1.3em;
      }
    }

    a.dismiss_button {
      display: block;
      font-size: 1.4rem;
      font-family: Cardo, serif;
      margin-top: 15px;
      font-style: italic;
    }
  }

  @media (min-width: 565px) {
    background-image: url(../../assets/newsletter-girl.png);
    background-repeat: no-repeat;
    background-position: right 10% bottom -30px;
    &__inner {
      width: 75%;
    }
  }
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  border: 1px solid #e5e5e5;
  box-shadow: 0 20px 25px 0 rgb(0 0 0 / 30%);

}
</style>
