import Vue from 'vue'

Vue.filter('currency', function (value) {

  /*
   const value = parseInt(originalValue)
  if (isNaN(value)) {
    return originalValue;
  }
   */
  if (typeof value !== "number") {
    return value;
  }
  let formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('date', function (value) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let date = new Date(value)
  return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
});

Vue.filter('newurl', function (oldurl, rendition) {
  if(oldurl === undefined)
    return oldurl

  const value = oldurl.src || oldurl

  let newurl = value
  if(value.indexOf('https://api.bayviewvillageshops.com/image/') === -1)
  {
    const url = value.replace('https://storage.googleapis.com/bayview-village/', '')
    let urlParts = url.split('.')
    const extension = urlParts.pop()
    urlParts.push(rendition)
    urlParts.push(extension)
    newurl = 'https://api.bayviewvillageshops.com/image/' + urlParts.join('.')
  }
  if(oldurl.src) {
    oldurl.src = newurl
    return oldurl
  } else {
    return newurl
  }

});

