<template>
    <b-row class="above-fold">
        <b-col cols="12" md="9" class="mb-5 above-fold__sliderbox">
            <Slider :ratio=65.5 :slides="slides"/>
        </b-col>
        <b-col cols="12" md="3">
            <b-row>
                <b-col cols="6" md="12" class="mb-5 above-fold__linkbox linkbox1">
                    <CaptionBox
                            :ratio=100
                            :image=linkBox1.image
                            :caption=linkBox1.caption
                            :link=linkBox1.link
                            captionPosition="bottom-left"/>
                </b-col>
                <b-col cols="6" md="12" class="mb-5 above-fold__linkbox linkbox2">
                    <CaptionBox
                            :ratio=100
                            :image=linkBox2.image
                            :caption=linkBox2.caption
                            :link=linkBox2.link
                            captionPosition="top-left"/>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>
<script>
    import Slider from '@/components/Slider'

    export default {
        name: 'AboveFold',
        components: {Slider},
        props: {
            slides: Array,
            linkBox1: Object,
            linkBox2: Object,
        }
    }
</script>
<style lang="scss" scoped>
    @media (max-width: 767px) {
        ::v-deep .linkbox2 .caption-box__caption {
            bottom: 10% !important;
            top: unset !important;
        }
    }
</style>
