<template>
  <b-container tag="main" id="main" :class="['hautelisted', {'over-six': page.fields.categories.length > 6}]"
               v-if="page.fields !== undefined">
    <b-row>
      <b-col cols="12" class="my-5">
        <a :href="'/' + parent" class="gallery_header_link">
          <h1 v-if="parent === 'holiday-gift-guide'" class="gallery_header mb-5"><i>Season's Greetings From</i><br><span>Bayview Village</span></h1>
          <h1 v-else class="gallery_header mb-5"><span>Uptown Girls</span><br><i>Spend a day in the life with us</i></h1>
        </a>
      </b-col>
    </b-row>
    <b-row id="gallery" class="mb-5">
      <b-col cols="12" md="6" class="mb-4">
        <div :class="{'video-wrapper': true, 'vertical': parent==='holiday-gift-guide'}" v-if="videoVisible && $route.params.filter === undefined">
          <video
              id="video"
              :key="'video'+ slug"
              muted playsinline autoplay
              :loop="parent==='holiday-gift-guide'"
              v-on:ended="videoVisible=(parent==='holiday-gift-guide')">
            <source :src="require('@/assets/vignettes/' + (parent === 'holiday-gift-guide' ? 'holiday' : 'uptown') + '-' + slug + '.mp4')"
                    type="video/mp4">
          </video>
        </div>
        <CaptionBox
            v-else
            :ratio="100"
            :image="featuredImage"
            captionTag="div"
            :caption="category.name && category.name.split(' - ')[1]"
        ></CaptionBox>
      </b-col>
      <b-col v-if="parent !== 'holiday-gift-guide' && $route.params.filter === undefined" cols="12" md="6" class="gallery__wrapper">
        <ul class="category__thumbs" :style="parent === 'holiday-gift-guide' ? 'grid-template-columns: repeat(2, 1fr)' : ''">
          <li
              v-for="category in page.fields.categories"
              :key="category.meta.id"
              class="">
            <router-link :to="'/' + parent + '/'  + $route.params.id + '/' + category.slug"
                         :title="'Filter ' + category.name + ' only'">
              <div class="category__image">
                <img :src="category.featured_image" :alt="category.featured_image_alt"/>
              </div>
              <h3 class="category__title">{{ category.name.split(' - ')[1] }} <span class="category__instructions">Details ></span>
              </h3>
            </router-link>
          </li>
        </ul>
      </b-col>
      <b-col v-if="parent === 'holiday-gift-guide' || $route.params.filter !== undefined" cols="12" md="6" class="hautelisted__slider mb-5 mt-5 pt-5">
        <ProductSlider :products="products" :slides="1" v-if="products.length > 0" ref="slider" :key="sliderIndex"
                       :showDescription="true" class="mt-5"/>
      </b-col>
    </b-row>
    <b-row tag="ul" class="filter__row mb-5" v-if="$route.params.filter !== undefined">
      <b-col tag="li" cols="12" sm="6" :md="true" class="filter__button">
        <router-link :to="'/' + parent + '/' + $route.params.id" title="Filter none">All</router-link>
      </b-col>
      <b-col
          v-for="category in page.fields.categories"
          :key="category.meta.id"
          tag="li" cols="12" sm="6" :md="true"
          class="filter__button">
        <router-link :to="'/' + parent + '/'  + $route.params.id + '/' + category.slug"
                     :title="'Filter ' + category.name + ' only'">
          {{ category.name.split(' - ')[1] }}
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" sm="4" md="3" lg="2"
             v-for="(product, index) in products"
             :key="index"
             class="product">
        <div class="product__image-container mb-5 order-3 order-lg-2">
          <div class="square-image">
            <a
                :href="'/' + parent + '/' + $route.params.id + '/' +
                (product.product_categories.length > 0? product.product_categories[0].slug : '')"
                :title="'Show ' + product.name"
                @click.prevent="showSlide(product)">
              <ImageObject :image="product.image" class="product__image"/>
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
    <VignetteGallery v-if="vignettes.length > 0" :vignettes="vignettes"/>
  </b-container>
</template>
<script>
import ProductSlider from '@/components/NewProductSlider'
import {butter} from "@/buttercms";
import VignetteGallery from "@/components/VignetteGallery.vue"

export default {
  name: 'HauteListed',
  components: {ProductSlider, VignetteGallery},
  data() {
    return {
      sliderIndex: 1,
      page: {},
      videoVisible: true,
      vignettes: []
    }
  },
  computed: {

    parent() {
      return this.$route.path.split('/')[1]
    },
    slug() {
      return this.$route.params.id
    },
    category() {
      if (this.$route.params.filter) {
        let response = this.page.fields.categories.filter(category =>
            category.slug === this.$route.params.filter
        )
        return response[0]
      }
      return false
    },
    products() {
      if (this.page.fields === undefined)
        return []
      let products = JSON.parse(JSON.stringify(this.page.fields.products[0].fields.product_list || []))
      for (let i = 0; i < products.length; i++) {
        let product = products[i]
        product.id = i
        if (product.retailer[0]) {
          product.link = {
            route: '/store/' + product.retailer[0].slug,
            title: product.retailer[0].name + ' retailer profile',
            content: product.retailer[0].name
          }
        }
        product.image = {
          src: product.featured_image,
          alt: product.featured_image_alt
        }
      }

      if (this.$route.params.filter && this.category) {
        return products.filter(product =>
                product.product_categories && product.product_categories.some(category =>
                    category.slug === this.$route.params.filter
                )
        )
      }
      return products
    },
    featuredImage() {
      if (this.$route.params.filter && this.category.featured_image) {
        return {
          src: this.category.featured_image,
          alt: this.category.featured_image_alt
        }
      }
      return {
        src: this.page.fields.featured_image,
        alt: this.page.fields.featured_image_alt
      }
    }
  },
  methods: {
    async initializeListing() {
      const response = (
          await butter.page.retrieve('*', this.$route.params.id, {'levels': 3})
      ).data

      this.page = response.data
    },
    async initializeVignettes() {
      const response = (
          await butter.page.retrieve('vignette_gallery', this.parent)
      ).data
      this.vignettes = response.data.fields.vignettes
    },
    async showSlide(product) {
      if(this.parent !== 'holiday-gift-guide' && product.product_categories[0] !== undefined) {
        const newPath = '/' + this.parent + '/' + this.$route.params.id + '/' + product.product_categories[0].slug
        if (this.$route.path !== newPath)
          await this.$router.push(newPath)
      }
      let slide = 1
      // Find clicked product in filtered products
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === product.id) {
          slide = i + 1
          break
        }
      }
      document.getElementById('gallery').scrollIntoView({behavior: "smooth"})
      if (this.$refs.slider) {
        this.$refs.slider.goToSlide(slide)
      }
    },
    hideVideo() {
      this.videoVisible = false;
    },
  },
  created() {
    this.initializeListing()
    this.initializeVignettes()
  },
  watch: {
    '$route.params': function () {
      this.initializeListing()
    }
    /*'products': function () {
      this.sliderIndex += 1
      if (document.getElementById('gallery'))
        this.showSlide(1)
    }*/
  }
}
</script>
<style lang="scss" scoped>
@import '../utils/global.scss';


.hautelisted {
  &__slider
  ::v-deep .product-slider {
    &__image {
      padding-bottom: 50%;
      width: 50%;
      margin: 30px auto;
    }

    &__description {
      margin-top: 15px;

      p {
        text-align: center;
        font-size: 1.4rem;
      }
    }

    &__cta-link {
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }

  ::v-deep .caption-box {
    @media (max-width: 575px) {
      margin-bottom: 30%;
    }

    &__inner {
      @media (max-width: 575px) {
        padding-bottom: 100% !important;
      }
    }

    &__caption {
      @media (max-width: 575px) {
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .15);
        left: 50%;
        margin-left: -42.5%;
        bottom: -40%;
      }

      @media (max-width: 575px) and (min-width: 400px) {
        bottom: -20% !important;
      }

      @media (max-width: 767px) and (min-width: 575px) {
        max-width: 50% !important;
      }

      @media (min-width: 992px) {
        max-width: 50% !important;
      }

      text-align: left;

      h1 {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.4rem;
        text-transform: none;
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .product {
    /* Display 5 columns on >= lg to make 2 rows of 5 */
    @media (min-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .square-image {
      border: #E5E5E5 solid 1px;
      transition: .1s;

      a {
        cursor: pointer;
      }

      img {
        top: 15px;
        left: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        object-fit: contain;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &.over-six .filter__row {
    @media (min-width: 768px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  .filter {
    &__row {
      list-style: none;
      padding: 0;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      }
    }

    &__button {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #070707;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 0.75em;
        text-decoration: none;

        @include media-breakpoint-only(xs) {
          &:not(:first-child) {
            border-top: none;
          }
        }

        @include media-breakpoint-only(sm) {
          &:not(:first-child):not(:nth-child(2)) {
            border-top: none;
          }
          &:nth-child(odd) {
            border-right: none;
          }
        }

        @include media-breakpoint-up(md) {
          &:not(:first-child) {
            border-left: none;
          }
        }
      }

      &:hover a:not(.router-link-exact-active) {
        background: #F3F3F3;
      }

      .router-link-exact-active {
        background: #D9017A;
        color: #FFFFFF;
      }
    }
  }

  .category {
    &__thumbs {
      list-style: none;
      padding: 0;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
      @media (min-width: 768px) {
        padding-left: 1rem;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    li {
      display: block;

      a {
        text-decoration: none !important;
      }
    }

    &__image {
      position: relative;
      padding-bottom: 150%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      text-transform: uppercase;
      margin-top: 1rem;
      color: #000000;
      font-size: 1.4rem;
    }
  }

  &.over-six .category__thumbs {
    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .category__instructions {
    color: #D9017A;
    text-transform: none;
  }

  #gallery::v-deep .caption-box__image {
    object-position: center 15%;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;

    #video {
      position: absolute;
      top: -15%;
      left: -1%;
      height: 117%;
      width: 102%;
      object-fit: cover;
      object-position: center 10%;
    }

    &.vertical {
      margin-left: 5%;
      margin-right: 5%;
      padding-bottom: 140%;
      @media (min-width: 575px) {
        margin-left: 10%;
        margin-right: 10%;
        padding-bottom: 125%;
      }
      #video {
        top: -1%;
        @media (min-width: 575px) {
          height: 102%;
        }
      }
    }

  }

  .gallery_header_link {
    text-decoration: none;
  }

  h1.gallery_header {
    color: #D9017A;
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight: 100;

    i {
      font-size: 4rem;
      font-family: Cardo, serif;
      display: inline-block;
      text-transform: none;
      margin: 0.25rem;
    }

    span {
      font-size: 4.5rem;
      display: inline-block;
      letter-spacing: 0rem;
      margin: 0.25rem;
    }
  }

  .gallery__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
