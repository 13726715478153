<template>
  <div class="image-gallery row" v-if="images && newImages.length > 0">
    <div v-for="(image, index) in newImages" :key="index"
         :class="classNames">
      <div :class="['section-content__inner', {singleImage: newImages.length < 2}]">
        <ImageObject v-if="image.src" :image="image"/>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ImageContentRowNew',
  props: {
    images: Array,
    columnsMobile: Number,
    columnsDesktop: Number
  },
  computed: {
    newImages() {
      let images = JSON.parse(JSON.stringify(this.images || []))
      for (let i = 0; i < images.length; i++) {
        if(images[i].image)
          images[i].src = images[i].image
        else if(images[i].url)
          images[i].src = images[i].url
        if (images[i].image_alt)
          images[i].alt = images[i].image_alt
        else if (images[i].content)
          images[i].alt = images[i].content
      }
      return images
    },
    classNames() {

      let classArray = ['col mb-5']

      if(this.columnsMobile) {
        const mobileColumnSize = Math.floor(12 / this.columnsMobile);
        classArray.push(`col-${mobileColumnSize}`);
      }
      if(this.columnsDesktop) {
        if (this.columnsDesktop === 5) {
          classArray.push('col-5ths');
        } else {
          const desktopColumnSize = Math.floor(12 / this.columnsDesktop);
          classArray.push(`col-md-${desktopColumnSize}`);
        }
      }

      return classArray
    }
  }
}
</script>
<style lang="scss" scoped>
.section-content {
  position: relative;

  &__inner {
    background: #E3E3E3;
    padding-bottom: 100%;
    position: relative;

    &.singleImage {
      padding-bottom: 50%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
@media (min-width: 768px) {
  .col-5ths {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

</style>
