<template>
  <b-container tag="main" id="main" class="media-item" v-if="mediaItem">
    <div class="text-left">
      <router-link to="/media-room">Back to Media Room</router-link>
    </div>
    <h1 v-html="mediaItem.title" class="text-left"></h1>
    <template v-if="mime.length > 0">
      <div class="image-showcase" v-if="mime === 'application/pdf'">
        <iframe :src="mediaItem.attachment" style="width:100%;height: 1200px"
                :title="'PDF viewer: ' + mediaItem.title"/>
      </div>
      <video width="100%" controls class="my-5" :key="mediaItem.meta.id"
             v-else-if="mime.split('/')[0]==='video'"
             :title="'Video player: ' + mediaItem.title">
        <source :src="mediaItem.attachment" type="video/webm">
        <track label="English" kind="subtitles" srclang="en" :src="'/captions/' + mediaItem.meta.id + '.vtt'" default>
        Your browser does not support the video tag.
      </video>
      <div class="image-showcase"
           v-else-if="mime.split('/')[0]==='image'">
        <img :src="mediaItem.attachment" :alt="'Image of the article ' + mediaItem.title "/>
      </div>
      <audio controls class="my-5" :key="mediaItem.meta.id"
             v-else-if="mime.split('/')[0]==='audio'"
             :title="'Audio player:' + mediaItem.title">
        <source :src="mediaItem.attachment" type="audio/mpeg">
        <track label="English" kind="subtitles" srclang="en" :src="'/captions/' + mediaItem.meta.id + '.vtt'" default>
        Your browser does not support the audio tag.
      </audio>
    </template>
    <div v-if="mediaItem.description" v-html="mediaItem.description"></div>
  </b-container>
  <Loader v-else/>
</template>
<script>
import {butter} from "@/buttercms";

export default {
  name: 'MediaItem',
  data() {
    return {
      mediaItem: {},
      mime: ''
    }
  },
  methods: {
    async getMediaArticle(id) {
      const response = (
          await butter.content.retrieve(['media_article[_id=' + id + ']'])
      ).data
      this.mediaItem = response.data.media_article[0]

      if (this.mediaItem.attachment) {
        this.preloadAndCheckMimeType(this.mediaItem.attachment)
            .then(mimeType => {
              this.mime = mimeType
            })
            .catch(error => {
              console.error(`Error: ${error.message}`);
            })
      }
    },
    preloadAndCheckMimeType(url) {
      return new Promise((resolve, reject) => {
        fetch(url, {method: 'HEAD'}) // Use the 'HEAD' method to get only headers
            .then(response => {
              if (!response.ok) {
                throw new Error(`Error fetching the file: ${response.statusText}`);
              }
              // Get the 'Content-Type' header
              const mimeType = response.headers.get('Content-Type');
              if (mimeType) {
                resolve(mimeType);
              } else {
                reject(new Error('MIME type not found'));
              }
            })
            .catch(error => {
              reject(error);
            });
      });
    }
  },
  created() {
    this.getMediaArticle(this.$route.params.id)
  }
}
</script>
<style lang="scss" scoped>
.image-showcase {
  img {
    max-width: 100%;
  }
}
</style>
