<template>
    <b-container tag="section" class="content-wrapper py-0">
        <template v-for="(entity, index) in contents">
            <ImageContentRow v-if="entity.type==='images'" :images="entity.contents" :key="index"/>
            <ProductContentRow v-else-if="entity.type==='products'" :products="entity.contents" :key="index"/>
            <ImageContentRow v-else-if="entity.type==='largeImage'" :images="[entity]" :key="index"/>
            <ImageContentRowNew
                v-else-if="entity.type==='publication' && entity.taxonomy && entity.taxonomy.id==='page-image-list'"
                :images="entity.list_assignments"
                :key="index"/>
            <ProductContentRow
                v-else-if="entity.type==='publication' && entity.taxonomy && entity.taxonomy.id==='page-item-list'"
                :products="entity.list_assignments"
                :key="index"/>
            <SimpleContentRow v-else :article="entity" :key="index"/>
        </template>
    </b-container>
</template>
<script>
    import ImageContentRow from "@/components/global/ImageContentRow";
    import ImageContentRowNew from "@/components/global/ImageContentRowNew";
    import ProductContentRow from "@/components/global/ProductContentRow";
    import SimpleContentRow from "@/components/global/SimpleContentRow";

    export default {
        name: 'ContentStack',
        props: {
            contents: Array
        },
        components: {ImageContentRow, ImageContentRowNew, ProductContentRow, SimpleContentRow}
    }
</script>
<style lang="scss" scoped>
    .simple-content {
        margin-bottom: 15px;
    }

    .image-gallery,
    .product-gallery {
        margin-top: 30px;
        margin-bottom: 30px;
    }
</style>