<template>
  <main id="main" class="home">
    <h1>Bayview Village</h1>
    <b-container>
      <AboveFold :slides="slides" :linkBox1="linkBox1" :linkBox2="linkBox2"/>
      <div class="divider sidelines my-2 instagram-header">
        <img src="@/assets/heads/blog.png"
             alt="Sketch of a woman's head facing left wearing sunglasses.">
        <a href="https://www.instagram.com/bvshops" title="Instagram - Bayview Village Shops">
          <h2>Follow @bvshops on Instagram</h2>
        </a>
      </div>
      <Instagram :posts="instagram"/>
      <!--      <RetailerList :retailers="retailers"/>-->
      <!--      <FireworkCarousel />-->
<!--      <ArticleGallery v-if="featurePosts.length > 0" :articles="featurePosts.slice(-4)" :featured="featurePosts[0]"
                      :featureCaption="featurePosts[0].taxonomy"/>-->
      <div v-if="featuredProducts.title"
           class="divider sidelines mt-4 mb-5">
        <img v-if="featuredProducts.featured_image"
             :src="featuredProducts.featured_image"
             :alt="featuredProducts.featured_image_alt || 'Image description coming soon.'">
        <h2>{{ featuredProducts.title }}</h2>
      </div>
      <ProductSlider
          v-if="products.length > 0"
          :products="products"
          :slides="featuredProducts.products_per_slide"
          class="mb-5"/>
      <div class="divider sidelines my-5 instagram-header">
        <img src="@/assets/heads/lifestyle-files.png"
             alt="Sketch of a woman's head facing left wearing sunglasses.">
        <h2>What's Haute</h2>
      </div>
      <b-row>
        <b-col cols="12" md="4" class="mb-5">
          <CaptionBox
              :ratio=100
              :image=linkBox3.image
              :caption=linkBox3.caption
              :link=linkBox3.link
              captionPosition="bottom-left"/>
        </b-col>
        <b-col cols="12" md="4" class="mb-5">
          <CaptionBox
              :ratio=100
              :image=linkBox4.image
              :caption=linkBox4.caption
              :link=linkBox4.link
              captionPosition="bottom-left"/>
        </b-col>
        <b-col cols="12" md="4" class="mb-5">
          <CaptionBox
              :ratio=100
              :image=linkBox5.image
              :caption=linkBox5.caption
              :link=linkBox5.link
              captionPosition="bottom-left"/>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
<script>
import AboveFold from '@/components/home/AboveFold'
/*import RetailerList from '@/components/home/RetailerList'*/
/*import ArticleGallery from '@/components/home/ArticleGallery'*/
import Instagram from '@/components/Instagram'
import ProductSlider from '@/components/NewProductSlider'
/*import FireworkCarousel from '@/components/FireworkCarousel'*/
import {mapState} from "vuex";
import {butter} from "@/buttercms";

export default {
  name: 'Home',
  components: {AboveFold, /*RetailerList, ArticleGallery,*/ Instagram, ProductSlider/*, FireworkCarousel*/},
  data() {
    return {
      posts: [],
      page: {},
      featuredRetailers: {},
      featuredProducts: {},
      imageSlider: {},
      linkBox1: {
        caption: 'Directory',
        link: {
          route: '/directory',
          title: 'Store Directory'
        },
        image: {
          src: 'https://cdn.buttercms.com/WJsVYtPHTlWjj0dqQPC2',
          alt: 'Photo of laptop on table'
        }
      },
      /*linkBox2: {
          caption: 'Gift Cards',
          link: {
              route: '/gastronomer',
              title: 'Gift Cards'
          },
          image: {
              src: 'https://cdn.buttercms.com/0aU2b6hvRyWgZQHKwx11',
              alt: 'Photo of a bag bearing the Bayview Village logo'
          }
      },*/
      linkBox2: {
        caption: 'Centre Hours',
        link: {
          route: '/directions#hoursofoperation',
          title: 'Directions - Hours of Operation'
        },
        image: {
          src: 'https://cdn.buttercms.com/sL7ZB88iQaGikqPpAbWW',
          alt: 'Photo of various products'
        }
      },
      linkBox3: {
        caption: 'Events & Promotions',
        link: {
          route: '/events',
          title: 'Events at Bayview Village'
        },
        image: {
          src: 'https://cdn.buttercms.com/jWkY2UotS1R3kfF6CD8D',
          alt: 'Photo of lipstick and various makeup'
        }
      },
      linkBox4: {
        caption: 'BV Blog',
        link: {
          route: '/blog',
          title: 'Events at Bayview Village'
        },
        image: {
          src: 'https://cdn.buttercms.com/NhPMrhBfSRaQkSctkmdE',
          alt: 'Photo of Bayview Village entrance between someone\'s hands forming a heart shape'
        }
      },
      linkBox5: {
        caption: 'Hautelisted',
        link: {
          route: '/hautelisted',
          title: 'Hautelisted product listing'
        },
        image: {
          src: 'https://cdn.buttercms.com/xVBIFuQSxGlNj2nye8y9',
          alt: 'A collage of imagery including the Eiffel Tower, garments with various textures including pink lace as well as shiny sequins, a woman\'s legs with grey high heels, Parisian architecture and a disco ball.'
        }
      },
      instagram: []
    }
  },
  computed: {
    ...mapState({
      // pages: state => state.pages,
      featured: state => state.featured
    }),
    products: function () {
      let products = this.featuredProducts.product_list || []
      for (let i = 0; i < products.length; i++) {
        let product = products[i]
        if (product.retailer) {
          product.link = {
            route: '/store/' + product.retailer.slug,
            title: product.retailer.name + ' retailer profile',
          }
        }
        product.image = {
          src: product.featured_image,
          alt: product.featured_image_alt
        }
      }
      return products
    },
    retailers: function () {
      let retailers = this.featuredRetailers.retailer_list || []
      for (let i = 0; i < retailers.length; i++) {
        let retailer = retailers[i]
        retailer.link = {
          route: '/store/' + retailer.slug,
          title: retailer.name + ' retailer profile',
        }
        retailer.image = {
          src: retailer.logo,
          alt: 'Logo for ' + retailer.name
        }
      }
      return retailers
    },

    /*articles: function () {
      let articles = JSON.parse(JSON.stringify(this.featured.articles.list_assignments || []))
      for (let i = 0; i < articles.length; i++) {
        let article = articles[i]
        article.caption = article.content
        article.link = {
          route: '/' + article.taxonomy.alias + '/' + article.alias,
          title: article.taxonomy.title + ': ' + article.title
        }
        article.image = {
          src: article.alt_image && article.alt_image.length > 0 ? article.alt_image[0] : article.image,
          alt: 'Thumbnail image for ' + article.title
        }
      }
      return articles
    },*/

    featurePosts: function () {
      let result = []
      for (let i = 0; i < this.posts.length; i++) {
        const post = this.posts[i]['recent_posts'][0]
        const slug = this.posts[i]['slug'].split('-')
        if (slug.length !== 2 || slug[0] !== 'feature')
          continue
        const p = slug[1] - 1
        let r = {}
        r.title = post.title
        r.caption = post.summary
        r.taxonomy = post.categories.length > 0 ? post.categories[0].name : 'BV Blog'
        r.link = {
          title: r.taxonomy + ': ' + post.title
        }
        if(post.categories.length > 0) {
          r.link.route = '/' + post.categories[0].slug.replace('bv-blog', 'blog') + '/' + post.slug
        } else {
          r.link.route = '/' + post.slug
        }

        r.image = {
          src: post.featured_image,
          alt: post.featured_image_alt
        }
        result[p] = r
      }
      return result
    },

    slides: function () {
      let slides = this.imageSlider.image_list || []
      for (let i = 0; i < slides.length; i++) {

        slides[i].caption = slides[i].title

        if(slides[i].link) {
          const link = {
          }
          if (slides[i].link.indexOf('http') === 0) {
            link.href = slides[i].link
          } else {
            link.route = slides[i].link
          }
          slides[i].link = link
        }
        slides[i].image = {
          src: slides[i].featured_image,
          alt: slides[i].featured_image_alt
        }
      }
      return slides
    }
  },
  methods: {
    initializePage() {

    /*  this.$store.dispatch('LOAD_API', {
        name: 'featured',
        id: 'slider',
        url: '/collection/main-slider'
      })*/

      /* this.$store.dispatch('LOAD_API', {
         name: 'featured',
         id: 'articles',
         url: 'list/featured-articles'
       })*/

      /* this.$store.dispatch('LOAD_API', {
        name: 'featured',
        id: 'retailers',
        url: 'list/featured-retailers'
      })*/

      /*this.$store.dispatch('LOAD_API', {
        name: 'featured',
        id: 'products',
        url: 'list/currently-coveting'
      })*/
    },
    async getFeaturedPosts() {
      butter.tag.list({"include": "recent_posts"})
          .then((response) => {
            this.posts = response.data.data
          })
    },
    async getPage() {
      const response = (
          await butter.page.retrieve('*', 'landing-page', {'levels': 2})
      ).data
      this.page = response.data
      this.splitComponents(this.page)
    },
    splitComponents(page) {
      const sections = page.fields.body
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        if (section.type === 'retailer_logos') {
          this.featuredRetailers = section.fields
        } else if (section.type === 'product_carousel') {
          this.featuredProducts = section.fields
        } else if (section.type === 'image_slider') {
          this.imageSlider = section.fields
        }

      }
    }
  },
  created() {
    this.initializePage()
  },
  mounted() {
    this.getPage()
    this.getFeaturedPosts()
  }
}
</script>
<style lang="scss" scoped>
h1 {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.instagram-header a {
  color: #070707;
}
</style>
