import store from "@/store/index";
//import config from '@/config'
import axios from 'axios'

//axios.defaults.baseURL = config.serverURI;

export default {
    /*
     * Load API
     */

    LOAD_API({commit, dispatch}, payloadObjOrString) {
        let payload = {}
        if (typeof payloadObjOrString === 'string') {
            payload.name = payloadObjOrString
            payload.url = payloadObjOrString
        } else {
            payload = payloadObjOrString
        }
        if (payload.url !== undefined) {
            return new Promise(function (resolve, reject) {
                let axiosConfig = {}
                axiosConfig.withCredentials = true
                axios.get('https://api.bayviewvillageshops.com/' + payload.url, axiosConfig)
                    .then(response => {
                        let data = response.data
                        if (!isJSON(data)) {
                            reject('Invalid response')
                        }
                        if (data.error) {
                            dispatch('VERIFY_SESSION')
                            reject(data.error)
                        }
                        if (payload.id !== undefined) {
                            if (payload.id === true && data.id !== undefined) {
                                commit('SET_STATE', {name: payload.name, id: data.id, data: data})
                            } else {
                                commit('SET_STATE', {name: payload.name, id: payload.id, data: data})
                            }
                        } else if(payload.name !== undefined) {
                            commit('SET_STATE', {name: payload.name, data: data})
                        }
                        store.commit('UPDATE_CART')
                        resolve(data)
                    })
                    .catch(error => {
                        dispatch('VERIFY_SESSION')
                        console.log(error)
                        reject('Request failed.')
                    })
            })
        }
    },
    /*
     * Post to API
     */

    POST_TO_API({state}, event) {
        if (event.target.attributes.action.value && event.target.id) {
            let action = event.target.attributes.action.value
            return new Promise(function (resolve, reject) {
                let formData = new FormData(event.target)
                if(state.formData[event.target.id] !== undefined)
                    for (let formField in state.formData[event.target.id]) {
                        let fieldValue = state.formData[event.target.id][formField]
                        if (formField !== 'id' && formField !== 'type' && formField !== 'image' && formField !== 'attachment' &&
                            (formField !== 'parent' || typeof fieldValue !== 'object')) {

                            if (typeof fieldValue === 'object') {
                                fieldValue = JSON.stringify(fieldValue)
                            }

                            formData.append(formField, fieldValue)
                        }
                    }
                axios.post('https://api.bayviewvillageshops.com/' + action, formData, {withCredentials: true})
                    .then(response => {

                        let data = response.data

                        if (!isJSON(data)) {
                            reject('Invalid response')
                        }
                        if (data.error) {
                            reject(data.error)
                        }
                        if (data.type && data.type === 'error') {
                            reject(data.message)
                        }
                        resolve(data)
                    })
                    .catch(error => {
                        console.log(error)
                        reject('Server appears to be offline')
                    })
            })
        }
    },
    /*
   * Submit active order
   */
    CHECK_OUT({state}) {
        let action = 'order/orders/create'
        return new Promise(function (resolve, reject) {
            let postData = state.formData.orderForm

            postData['order_item'] = []
            let items = state.cart.items
            for (let key in items) {
                if (Object.prototype.hasOwnProperty.call(items, key)) {
                    postData['order_item'].push('did' + key + '!D!' + items[key])
                }
            }

            postData['order_totals'] = JSON.stringify(state.cart.totals)

            axios.post('https://api.bayviewvillageshops.com/' + action, postData, {withCredentials: true})
                .then(response => {
                    let data = response.data
                    if (!isJSON(data)) {
                        reject('Invalid response')
                    }
                    if (data.type === 'error') {
                        reject(data.message)
                    }
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    reject('Server appears to be offline')
                })
        })

    },
    VERIFY_SESSION({state, commit}) {
        if (state.user && state.token) {
            // If the user is not logged into the API, but is still logged into the dashboard
            IsAPILoggedIn(state.token).then(function (response) {
                if (response !== true) {
                    // Log user out of dashboard and reload
                    commit('SET_USER', null)
                    commit('SET_TOKEN', null)

                    if (window.localStorage) {
                        window.localStorage.setItem('user', null)
                        window.localStorage.setItem('token', null)
                    }
                    location.reload()
                }
            })
        }
    },
    SET_FLAG ({commit}, payload) {
        if (payload.value === '') {
            axios.get('flag.remove?type=' + payload.type + '&subject=' + payload.id).then(function() {
                commit('FLAG_ITEM', payload)
            })
        } else {
            axios.get('flag.set?type=' + payload.type + '&subject=' + payload.id).then(function() {
                commit('FLAG_ITEM', payload)
            })
        }
    },
    UPDATE_USER_FLAGS ({commit}, payload) {
        // TODO: Make this one call for all flags
        axios.get('flag.list?type=' + payload.type).then(response => {
            if (response.data.status !== undefined && response.data.status === 'success') {
                commit('SET_USER_FLAGS', {name: payload.name, items: response.data.list})
            }
        })
    }
}

function IsAPILoggedIn(Token) {
    return axios.get('https://api.bayviewvillageshops.com/' + 'user?token=' + Token, {withCredentials: true}).then(response => {
        return response.status === 200 && response.data.type && response.data.type === 'profile'
    }).catch(function () {
        return false
    })
}

function isJSON(item) {
    item = typeof item !== 'string'
        ? JSON.stringify(item)
        : item

    try {
        item = JSON.parse(item)
    } catch (e) {
        return false
    }

    if (typeof item === 'object' && item !== null) {
        return true
    }
    return false
}
