<template>
    <section class="instagram">
        <div class="powr-social-feed" id="7f13bdc0_1617245989"></div>
    </section>
</template>
<script>
    export default {
        name: 'Instagram',
        props: {
            posts: Array
        }
    }
</script>
<style lang="scss" scoped>
    .instagram {
        .powr-social-feed {
            margin-left: -15px;
            margin-right: -15px;
        }
        &__post {
            /* Display 5 columns on >= lg to make 2 rows of 5 */
            @media (min-width: 992px) {
                flex: 0 0 20%;
                max-width: 20%;
            }
            /* Hide last 2 posts on md to make 2 rows of 4 */
            @media (min-width: 768px) and (max-width: 991px) {
                &:nth-child(n+9) {
                    display: none;
                }
            }
            /* Hide last 6 posts on <= sm to make 2 rows of 3 */
            @media (max-width: 767px) {
                &:nth-child(n+7) {
                    display: none;
                }
            }
        }
    }
</style>