<template>
    <VueSlickCarousel v-bind="settings" class="product-slider" ref="carousel" v-if="products.length > 0">
        <div v-for="(product, index) in products"
             :key="index" class="product-slider__product">
            <NavLink :link="product.link && product.link.content ? null : product.link">
                <div class="product-slider__image">
                    <ImageObject :image="product.image"/>
                </div>
                <h2>{{product.name}}</h2>
                <h3 v-if="product.retailer">{{product.retailer.name}}</h3>
                <div v-if="showDescription && product.description" class="product-slider__description" v-html="product.description"></div>
                <NavLink v-if="product.link && product.link.content" :link="product.link" class="product-slider__cta-link">
                    {{product.link.content}}
                </NavLink>
              <div class="mt-3">
                <a href="#" v-on:click.prevent="handleAddToWishlist(product)">Add to Wish List</a>
              </div>
            </NavLink>
        </div>
    </VueSlickCarousel>
</template>
<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: 'ProductSlider',
        components: {VueSlickCarousel},
        props: {
            products: Array,
            slides: {
                type: Number,
                default: 5
            },
            showDescription: {
              type: Boolean,
              default: false
            }
        },
        data() {
            return {
                settings: {
                    arrows: true,
                    dots: false,
                    slidesPerRow: Math.abs(this.slides),
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesPerRow: this.slides > 1 ? (this.slides - 1) : 1
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesPerRow: this.slides > 2 ? (this.slides - 2) : 1
                            }
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                slidesPerRow: 1
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            goToSlide(slide) {
                this.$refs.carousel.goTo(slide-1)
            },
            handleAddToWishlist(product) {
              this.$store.commit('ADD_TO_WISHLIST', product)
              this.$store.commit('SET_STATE', {name: 'wishListDrawerOpen', data: true});
              if (this.$store.state.userNavigatingByTab === true)
                document.getElementById('cart-close-button').focus();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .product-slider {
        &__image {
            position: relative;
            padding-bottom: 80%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        &__product {
            padding: 0 15px;

            a:hover {
                text-decoration: none;
            }

            h2 {
                text-decoration: none;
                text-transform: none;
                font-size: 1.5rem;
                color: #070707;
                padding: 0 15px;
                margin-top: 15px;
            }

            h3 {
                text-decoration: none;
                text-transform: uppercase;
                color: #D9017A;
                font-size: 1.3rem;
                padding: 0 15px;
            }
        }

        ::v-deep {
            .slick-slide > div {
                display: flex;
            }

            .slick-prev, .slick-next {
                left: 0;
                z-index: 1;
                background: white;
                height: 58px;
                width: 52px;
                opacity: 0.5;
                transition: .3s;

                &:hover {
                    opacity: 1 !important;
                }
            }

            .slick-next {
                right: 0;
                left: unset;
            }

            .slick-prev:before, .slick-next:before {
                font-family: FontAwesome;
                opacity: 1;
                color: black;
            }

            .slick-prev:before {
                content: '\f053';
            }

            .slick-next:before {
                content: '\f054';
            }
        }
    }
</style>
