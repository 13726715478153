<template>
  <b-container class="vignette-detail mb-5">
    <b-row class="my-5">
      <b-col cols="12">
        <h1 class="gallery__header" v-html="vignette.title"></h1>
        <div class="gallery__description" v-html="vignette.description"></div>
        <!--    <div class="featured-image">
              <img :src="vignette.featured_image"
                   :alt="vignette.featured_image_alt || 'Featured image of closeup of ' + vignette.title"
              />
            </div>-->
      </b-col>
    </b-row>
    <b-row class="gallery mt-5">
      <b-col cols="6" md="4" lg="3" v-for="(image, index) in vignette.images"
             :key="index"
             :id="'image' + index"
             @click="scrollToCaptions"
             @mouseenter="thumbHover"
             @mouseleave="thumbHover">
        <div class="square-image gallery__image mb-5">
          <img :src="image.image"
               :alt="image.image_alt || 'Closeup thumbnail #' + (index + 1) + ' of ' + vignette.title"
          />
        </div>
      </b-col>
    </b-row>
    <h2 class="text-left mb-2 captions__header">Product details:</h2>
    <div class="captions text-left" id="captions" v-html="mergeCaptions"></div>
  </b-container>
</template>
<script>
export default {
  name: 'VignetteDetail',
  props: {
    vignette: Object
  },
  methods: {
    thumbHover(event) {
      const elements = document.getElementsByClassName(event.target.id)
      if(event.type==='mouseenter') {
        elements.forEach(element => element.classList.add('active'))
      }
      if(event.type==='mouseleave') {
        elements.forEach(element => element.classList.remove('active'))
      }
    },
    extractParagraphs(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const paragraphs = doc.querySelectorAll('p');
      return Array.from(paragraphs, (p) => p.innerHTML);
    },
    scrollToCaptions() {
      document.getElementById('captions').scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  },
  computed: {
    mergeCaptions() {
      let captions = []
      this.vignette.images.forEach((image, index) => {
        const paragraphs = this.extractParagraphs(image.caption).map(paragraph =>
            '<span class="image' + index + '">' + paragraph + '</span>'
        )
        /*const paragraphs = '<span class="caption-string image' + index + '">' + image.caption + '</span>'*/
        captions = captions.concat(paragraphs)
      })

      return captions.join("&nbsp; &nbsp;")
    }
  }
}
</script>
<style lang="scss" scoped>
.gallery {
  &__header {
    color: #D9017A;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 3.4rem;
    letter-spacing: 0.25rem;
    margin: 1.75rem 0;
    text-align: left;

    ::v-deep i {
      font-size: 3.7rem;
      font-family: Cardo, serif;
      text-transform: none !important;
      margin: 0.25rem;
      letter-spacing: 0.1rem;
    }
  }
  &__description {
    color: #D9017A;
    ::v-deep p {
      max-width: 650px;
      font-family: Montserrat, sans-serif;
    }
  }
}

.featured-image img {
  max-width: 100%;
}

.gallery {
  &__image {
    cursor: pointer;
  }
}

::v-deep .captions {
  font-size: 1.4rem;
  &__header {
    color: #D9017A;
    font-family: Cardo, serif;
    text-transform: none;
    font-size: 2.1rem;
    font-style: italic;
  }
  span {
    transition: 1s;
  }
  a {
    text-transform: uppercase;
    color: #222222;
    font-weight: 800;
    transition: 1s;
  }
  .active {
    &, a {
      color: #D9017A;
      transition: .3s;
    }
  }
}
</style>

