// TODO: Organize this into individual modules?
export default () => {
    return {
        pages: {
            main: {},
            mainSlider: {},
            hautelisted: {}
        },
        blogs: [],
        articles: [],
        featured: {
          slider: [],
          articles: {},
          retailers: {},
          products: {}
        },
        products: [],
        directory: [],
        directorySearch: '',
        categories: [],
        retailers: {},
        bvevents: {},
        events: [],
        'media-room': {},
        hautecast: {},
        hautelisted: {},
        lookbooks: {},
        lookbook: {},
        mediaItems: {},
        footerContent: {},
        presenters: [],
        activations: [],
        activeProduct: {},
        vendors: [],
        cart: {
            items: {},
            totals: {
                discountPercent: 0,
                discountTotal: 0,
                itemsQuantity: 0,
                itemsTotal: 0,
                subtotal: 0,
                totalShipping: 0,
                taxTotal: 0,
                totalValue: 0
            }
        },
        wishlist: [],
        formData: {
            orderForm: {
                customer_name: '',
                customer_email: '',
                customer_address: '',
                customer_city: '',
                customer_province: '',
                customer_postal: '',
                customer_phone: '',
                pickup_date: '',
                pickup_time: '',
                stripe_token: ''
                /*card_number: '',
                card_year: '',
                card_month: '',
                card_cvv: ''*/
            },
            newsletterForm: {
                email: ''
            },
            santaForm: {
                order_number: '',
                name: '',
                email: '',
                photo_names: ''
            }
        },
        user: {},
        token: null,
        discounts: [],
        product_taxonomies: [],
        cartDrawerOpen: false,
        wishListDrawerOpen: false,
        COVIDmodalOpen: true,
        userNavigatingByTab: false,
        occupancy: {},
        capacity: 850
    }
}
