<template>
  <b-container tag="main" id="main" class="list-page py-0">
    <CaptionBox :ratio=50
                :image="{
                   src: 'https://cdn.buttercms.com/NhPMrhBfSRaQkSctkmdE',
                   alt: 'A person makes the shape of a heart with their hands around the Bayview Village sign'}"
                caption="Employment"
                captionPosition="top-left"
                class="mb-5"/>
    <List v-if="postings.length > 0" :list="listings" class="pt-5" cta="Read More"></List>
    <div v-else>
      Nothing at the moment!
    </div>
  </b-container>
</template>
<script>
import List from "@/components/List.vue"
import {butter} from "@/buttercms";

export default {
  name: 'ListPage',
  components: {List},
  data() {
    return {
      postings: []
    }
  },
  computed: {
    listings() {
      console.log(this.postings)
      let postings = this.postings
      if(postings.length > 0) {
        for(let i=0;i<postings.length;i++) {
          let posting = postings[i]

          posting.description = posting.retailer.description

          posting.vendors = [{
            title: posting.retailer.name,
            alias: posting.retailer.slug
          }]

          posting.image = {
            src: posting.retailer.featured_image,
            alt: posting.retailer.featured_image_alt
          }

          posting.link = {
            route: '/job/' + posting.meta.id,
            title: posting.retailer.name
          }
        }
      }
      return postings
    },
  },
  methods: {
    async initializePage() {
      const response = (
          await butter.content.retrieve(['job_posting'], {order: '-publish_date'})
      ).data
      this.postings = response.data.job_posting
    }
  },
  created() {
    this.initializePage()
  }
}
</script>
<style lang="scss" scoped>
.page {
  &__content {
    ::v-deep h1 {
      text-align: left;
      font-size: 2.4rem;
      text-transform: uppercase;
    }

    ::v-deep h2, ::v-deep h3 {
      text-align: left;
    }

    ::v-deep ul {
      font-family: Cardo, serif;
      margin-bottom: 15px;

      li {
        text-align: left;
      }
    }

  }
}
</style>
