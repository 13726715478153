var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"tag":"section"}},[_c('b-row',[_c('b-col',{staticClass:"mb-5",attrs:{"cols":"12","md":_vm.LinkBoxPosition === 'none' ? 12 : 9,"order":_vm.LinkBoxPosition === 'left' ? 2 : 1}},[(_vm.section.featured_image.includes('www.google.com/maps'))?_c('iframe',{staticClass:"map-iframe",attrs:{"title":"Google map of Bayview Village and surrounding area","src":"https://www.google.com/maps/embed/v1/place?q=Bayview%20Village%20Shopping%20Centre&key=AIzaSyARwsBiL0cZi1P1wtqTlisIfSpHwGQDWtM"}}):_c('CaptionBox',{attrs:{"ratio":_vm.LinkBoxPosition === 'none' ? 45 : 65.5,"image":{
                         src: _vm.section.featured_image,
                         alt: _vm.section.featured_image_alt
                       },"caption":_vm.section.title,"captionPosition":"bottom-left"}})],1),(_vm.LinkBoxPosition !== 'none')?_c('b-col',{attrs:{"cols":"12","md":"3","order":_vm.LinkBoxPosition === 'left' ? 1 : 2}},[_c('b-row',[_c('b-col',{staticClass:"mb-5",attrs:{"cols":"6","md":"12"}},[_c('CaptionBox',{attrs:{"ratio":100,"image":{
                        src: _vm.section.top_image,
                        alt: _vm.section.top_image_alt
                      },"caption":_vm.section.top_image_label,"link":{
                route: _vm.section.top_image_link,
                title: _vm.section.top_image_label
              },"captionPosition":"bottom-left"}})],1),_c('b-col',{staticClass:"mb-5",attrs:{"cols":"6","md":"12"}},[_c('CaptionBox',{attrs:{"ratio":100,"image":{
                        src: _vm.section.bottom_image,
                        alt: _vm.section.bottom_image_alt
                      },"caption":_vm.section.bottom_image_label,"link":{
                 route: _vm.section.bottom_image_link,
                 title: _vm.section.bottom_image_label
               },"captionPosition":"top-left"}})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }