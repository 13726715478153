<template>
    <router-link :to="'/'+vendor.id" class="vendor" v-if="vendor.title">
        <div :class="['vendor__image-container mb-5', {'not-loaded': !showImg}]">
            <div class="square-image">
                <!--<img class="vendor__image"
                     v-show="typeof vendor.image.src != null && showImg"
                     :src="vendor.image.src"
                     :alt="vendor.image.alt"
                     @load="showImg=true"/>-->
                <img class="vendor__image"
                     v-show="typeof vendor.image != null && showImg"
                     :src="vendor.image"
                     @load="showImg=true"/>
            </div>
        </div>
        <!--<div class="vendor__details">
            <b-row>
                <b-col cols="9">
                    <h3 class="vendor__title text-left" v-if="vendor.title">{{vendor.title}}</h3>
                </b-col>
            </b-row>
        </div>-->
    </router-link>
</template>
<script>
    export default {
        name: 'Vendor',
        props: {
            vendor: {
                type: Object
            }
        },
        data() {
            return {
                showImg: false
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .vendor {
        position: relative;
        color: #070707;
        display: block;
        text-decoration: none;

        &__image-container {
            width: 100%;
            border: 1px solid #E5E5E5;
            padding: 1.5em;

            &.not-loaded {
                background: url(../assets/bayview-village-placeholder.png) no-repeat center center #F1F1F1;
                background-blend-mode: color-burn;
            }
        }

        &__image {
            object-fit: contain;
        }

        &__title {
            font-size: 1.4rem;
        }
    }
</style>