<template>
  <BlogPost v-if="post" :postdata="post" />
  <Page v-else-if="alias" :alias="alias"/>
  <b-container tag="main" id="main" v-else>
    <img src="@/assets/heads/blog.png"
         alt="Sketch of a woman's head facing left wearing sunglasses." class="mb-3">
    <div class="loading">
      <p>Sorry . . . we can't find that page!</p>
    </div>
  </b-container>
</template>
<script>
import Page from '@/views/Page'
import {butter} from "@/buttercms";
import BlogPost from "@/views/BlogPost.vue";

export default {
  name: 'Wildcard',
  components: {
    BlogPost,
    Page
  },
  data() {
    return {
      alias: false,
      post: false
    }
  },
  computed: {
    path() {
      return this.$route.path.split('/')[1]
    }
  },
  methods: {
    searchWildcard() {
      butter.post.retrieve(this.path)
          .then((resp) => {
            this.post = resp.data
          }).catch(() => {
        // If butter fails, check old CMS
        this.$store.dispatch('LOAD_API', {
          url: 'status/' + this.path
        }).then((status) => {
          if(status.alias) {
            this.alias = status.alias
          }
        })
      });
    }
  },
  created() {
    this.searchWildcard()
  },
  watch: {
    '$route.path': function () {
      this.searchWildcard()
    }
  }
}
</script>
<style lang="scss" scoped>
main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
