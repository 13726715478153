import WildcardView from '@/views/Wildcard'
import HomeView from '@/views/Home'
import SimplePageView from '@/views/SimplePage'
import ListPageView from '@/views/ListPage'
import EventsView from '@/views/Events'
// import GastronomerView from '@/views/Gastronomer'
import VideoCardView from '@/views/VideoCardView'
import DirectoryView from '@/views/Directory'
import RetailerView from '@/views/Retailer'
// import PageView from '@/views/Page'
import NewPageView from '@/views/NewPage'
// import BlogView from '@/views/Blog'
import StoreView from '@/views/Store'
//import StoreView from '@/views/StoreNew'
import ProductView from '@/views/Product'
//import ProductView from '@/views/ProductNew'
import CheckoutView from '@/views/Checkout'
import CondoForm from '@/views/CondoForm'
/*import FFSView from '@/views/FFS'*/
import LookbookView from '@/views/Lookbook'
//import HauteListedView from '@/views/HauteListed'
import HauteListedNewView from '@/views/HauteListedNew'
import ProductListing from '@/views/ProductListing'
import GiftGuideView from '@/views/GiftGuide'
// import FashionistaLiveView from '@/views/FashionistaLive'
// import SantaView from '@/views/SantaCountdown'
import MediaRoomView from '@/views/MediaRoom'
import MediaItemView from '@/views/MediaItem'
import HautecastView from '@/views/HauteCast'
import HautecastItemView from '@/views/HautecastItem'
/*import OccupancyView from '@/views/Occupancy'*/
import BlogHome from "@/views/BlogHome"
import BlogPost from "@/views/BlogPost"

const routes = [
    {
        path: '/',
        component: HomeView
    },
    {
        path: '/directory/:filter?/:subfilter?',
        meta: {
            title: 'Directory'
        },
        component: DirectoryView
    },
    {
        path: '/map',
        meta: {
            title: 'Directory'
        },
        component: DirectoryView
    },
    {
        path: '/store/:id',
        meta: {
            title: 'Store'
        },
        component: RetailerView
    },
    /*{
        path: '/blog/:id?',
        name: 'BV Blog',
        meta: {
            title: 'BV Blog'
        },
        component: BlogView
    },*/
    {
        component: BlogHome,
        name: "blog-home",
        path: "/blog/",
        meta: {
            title: 'BV Blog'
        }
    },
    {
        component: BlogPost,
        name: "blog-post",
        meta: {
            title: 'BV Blog'
        },
        path: "/blog/:slug",
    },
    {
        component: BlogHome,
        name: "lifestyle-files-home",
        path: "/lifestyle-files/",
        meta: {
            title: 'Lifestyle Files'
        }
    },
    {
        component: BlogPost,
        name: "lifestyle-files-post",
        meta: {
            title: 'Lifestyle Files'
        },
        path: "/lifestyle-files/:slug",
    },
    /*  {
          path: '/lifestyle-files/:id?',
          name: 'Lifestyle Files',
          meta: {
              title: 'Lifestyle Files'
          },
          component: BlogView
      },*/
    {
        component: BlogHome,
        name: "ask-the-expert-home",
        path: "/ask-the-expert/",
        meta: {
            title: 'Ask the Expert'
        }
    },
    {
        component: BlogPost,
        name: "ask-the-expert-post",
        meta: {
            title: 'Ask the Expert'
        },
        path: "/ask-the-expert/:slug",
    },
    /*{
        path: '/ask-the-expert/:id?',
        name: 'Ask the Expert',
        meta: {
            title: 'Ask The Expert'
        },
        component: BlogView
    },*/
    {
        path: '/leasing',
        meta: {
            title: 'Leasing'
        },
        component: NewPageView
    },
    /*{
        path: '/covet-and-click',
        meta: {
            title: 'Covet & Click'
        },
        component: PageView,
        props: {
            headerCaption: false
        }
    },*/
    /*{
        path: '/candyland',
        meta: {
            title: 'Candyland'
        },
        component: PageView,
        props: {
            headerCaption: false
        }
    },*/
    /*{
        path: '/santas-workshop',
        meta: {
            title: 'Santa\'s Workshop'
        },
        component: SantaView
    },*/
    {
        path: '/haute-lives-here',
        meta: {
            title: 'Haute Lives Here'
        },
        component: CondoForm
    },
/*    {
        path: '/gastronomer',
        meta: {
            title: 'Gastronomer'
        },
        component: GastronomerView,
        children: [
            {
                path: 'product/:id?',
                meta: {
                    title: 'Gastronomer'
                },
                component: ProductView
            },
            {
                path: 'checkout',
                meta: {
                    title: 'Gastronomer'
                },
                component: CheckoutView
            },
            {
                path: ':section?',
                meta: {
                    title: 'Gastronomer'
                },
                component: StoreView
            }
        ]
    },*/
    /*{
        path: '/fashionistasummit/:section?',
        meta: {
            title: 'Fashionista Virtual Summit'
        },
        component: FFSView
    },
    {
        path: '/fashionistasummit-f4hrk754bk3d/:section?',
        meta: {
            title: 'Fashionista Virtual Summit'
        },
        component: FashionistaLiveView
    },*/
    {
        path: '/hautelisted/:filter?',
        meta: {
            title: 'HauteListed'
        },
        component: HauteListedNewView
    },
    {
        path: '/giftguide/:filter?',
        meta: {
            title: 'Gift Guide'
        },
        component: GiftGuideView
    },
    {
        path: '/media-room',
        meta: {
            title: 'Media Room'
        },
        component: MediaRoomView,
    },
    {
        path: '/media/:id?',
        meta: {
            title: 'Media Item'
        },
        component: MediaItemView,
    },
    {
        path: '/hautecast',
        meta: {
            title: 'The Hautecast'
        },
        component: HautecastView
    },
    {
        path: '/hautecast/:id?',
        meta: {
            title: 'Media Item'
        },
        component: HautecastItemView,
    },
    {
        path: '/lookbook/:id?',
        meta: {
            title: 'Lookbook'
        },
        component: LookbookView
    },
    {
        path: '/magazine/:id?',
        meta: {
            title: 'Haute Magazine'
        },
        component: LookbookView
    },
    {
        path: '/employment',
        meta: {
            title: 'Employment'
        },
        component: ListPageView
    },
    {
        path: '/events',
        meta: {
            title: 'Events'
        },
        component: EventsView
    },
    {
        path: '/about',
        meta: {
            title: 'About Us'
        },
        component: NewPageView
    },
    {
        path: '/directions',
        meta: {
            title: 'Visit Us'
        },
        component: NewPageView
    },
    {
        path: '/contact',
        meta: {
            title: 'Contact Us'
        },
        component: NewPageView
    },
    {
        path: '/job/:id',
        meta: {
            title: 'Employment Opportunity'
        },
        component: SimplePageView
    },
    {
        path: '/services',
        meta: {
            title: 'Guest Services'
        },
        component: NewPageView
    },
    {
        path: '/privacypolicy',
        meta: {
            title: 'Privacy Policy'
        },
        component: NewPageView
    },
    {
        path: '/community',
        meta: {
            title: 'Community Relations'
        },
        component: NewPageView
    },
    {
        path: '/breakfast-at-bayview/:id?',
        name: 'VideoCard',
        meta: {
            title: 'Spring'
        },
        component: VideoCardView
    },
    {
        path: '/uptown-girls/:id/:filter?',
        meta: {
            title: 'Uptown Girls',
        },
        component: ProductListing
    },
    {
        path: '/uptown-girls',
        meta: {
            title: 'Uptown Girls',
        },
        component: VideoCardView
    },
    {
        path: '/holiday-gift-guide/:id/:filter?',
        meta: {
            title: 'Holiday Gift Guide',
        },
        component: ProductListing
    },
    {
        path: '/holiday-gift-guide',
        meta: {
            title: 'Holiday Gift Guide',
        },
        component: VideoCardView
    },
    /*{
        path: '/occupancy',
        meta: {
            title: 'Centre Occupancy'
        },
        component: OccupancyView
    },*/
    {
        path: '*',
        component: WildcardView
    }
]

export default routes
