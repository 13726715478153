<template>
    <main id="main" class="store">
        <b-container tag="section" class="hero-image mb-5 px-0">
            <CaptionBox
                    :ratio=45
                    :image=headerImage
                    :caption=captionImage
                    captionPosition="bottom-left"
                    captionMaxWidth="400px"
                    class="header-image"
                    v-if="routerSection !== 'thanks'"/>
        </b-container>
        <b-container tag="section" v-if="routerSection !== 'thanks'" class="intro-text mb-5">
            <h1 class="mb-3">Welcome to Gastronomer</h1>
            <p><b>GASTRONOMER</b> is a virtual marketplace and food concierge service that allows you to customize a
                gourmet experience from a curated collection of BV's greatest culinary hits.</p>
        </b-container>
        <StoreFilters :submenu="routerSection === 'theme'" :active="routerSection" class="mb-5"/>
        <b-container tag="section" v-if="routerSection === 'how-it-works'" class="about mb-5">
            <b-row>
                <b-col cols="12" sm="6" md="4">
                    <img src="../assets/about-image.jpg" class="about__image"/>
                </b-col>
                <b-col cols="12" sm="6" md="8" class="text-left px-5 mt-5 mt-md-0">
                    <h2 class="mb-3"><b>One stop, gourmet spot</b></h2>
                    <ol class="mt-5">
                        <li>Peruse our drool-worthy offering.</li>
                        <li>Choose your yummy adventure.</li>
                        <li>Pay.</li>
                        <li>Select your preferred pickup time.</li>
                        <li>Drive up to one of our curbside stalls.</li>
                        <li>Our Concierge will deliver your order.</li>
                        <li>Bon appétit!</li>
                    </ol>
                </b-col>
            </b-row>
        </b-container>
        <b-container tag="section" v-else-if="routerSection === 'thanks'" class="thanks mb-5 pt-5">
            <b-row>
                <b-col cols="12" sm="6" md="4">
                    <img src="../assets/about-image.jpg" class="thanks__image"/>
                </b-col>
                <b-col cols="12" sm="6" md="8" class="text-left mt-5 px-5">
                    <img src="../assets/gastronomer-logo.jpg" alt="Gastronomer Logo"
                         class="thanks__store-logo mb-5"/>
                    <p>Thank you for your order, foodie friend!</p>
                    <p>You are in for a gourmet experience.</p>
                    <p>Please check your email inbox for all the delish deets as it pertains to your order including
                        important information about order pick-up. If you don't receive an order confirmation within 30 minutes,
                        please contact us at 416.226.0404.</p>
                    <p>Bon appétit!</p>
                    <router-link to="/gastronomer" class="thanks__back-link mt-5">Back to Shop</router-link>
                </b-col>
            </b-row>
        </b-container>
        <Vendors v-else-if="routerSection === 'vendor'" :vendors="vendors" class="mb-5"/>
        <Products v-else :productsPerRow=3 :products="products" :vendors="vendors" class="mb-5"/>
        <Pagination/>
        <GastronomerModal />
    </main>
</template>
<script>
    import StoreFilters from '@/components/StoreFilters'
    import Products from '@/components/Products'
    import Vendors from '@/components/Vendors'
    import Pagination from '@/components/Pagination'
    import GastronomerModal from '@/components/global/GastronomerModal'
    import {mapState} from "vuex";

    export default {
        name: 'Store',
        components: {
            StoreFilters, Products, Vendors, Pagination, GastronomerModal
        },
        data() {
            return {
                headerImage: {
                    src: require('@/assets/test-header.jpg'),
                    alt: 'Image alt'
                },
                captionImage: {
                    src: require('@/assets/gastronomer-logo.jpg'),
                    alt: 'Gastronomer Marketplace'
                }
            }
        },
        computed: {
            routerSection: function () {
                return this.$route.params.section;
            },
            ...mapState({
                products: state => state.products,
                vendors: state => state.vendors
            })
        },
        created() {
            if (this.products.length === 0) {
                this.$store.dispatch('LOAD_API', {name: 'products', url: 'products?showsubs=true'})
            }
            if (this.vendors.length === 0) {
                this.$store.dispatch('LOAD_API', {name: 'vendors', url: 'vendors?filter=ecommerce&sort=title'});
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../utils/global.scss';

    .intro-text {
        max-width: 900px;

        h1 {
            text-transform: uppercase;
            max-width: 800px;
            margin: auto;
        }

        h2 {
            text-transform: uppercase;
        }

        p {
            font-family: Montserrat, Arial, sans-serif;
            font-size: 1.6rem;
        }
    }

    .header-image {
        &::v-deep .caption-box__caption {
            padding: 0 1.5em 0.5rem;
        }
    }

    .about, .thanks {
        h2 {
            text-transform: uppercase;
        }

        &__image {
            width: 100%;
        }

        ol {
            list-style: none;
            counter-reset: about-ol-counter;
            margin: auto;
            padding: 0;
            max-width: 400px;
        }

        ol li {
            counter-increment: about-ol-counter;
            display: flex;
            align-items: flex-start;
            font-size: 1.7rem;
            margin-bottom: 1rem;

            &:nth-child(even) {
                margin-left: 3rem;
            }
        }

        ol li::before {
            content: counter(about-ol-counter);
            font-weight: bold;
            font-size: 4rem;
            margin-right: 1rem;
            font-family: serif;
            line-height: 1;
            color: #D9017A;
        }

        &__store-logo {
            max-width: 400px;
            width: 100%;
        }

        &__back-link {
            display: block;
            color: #D9017A;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            font-size: 1.2rem;
            text-decoration: none;

            &::before {
                content: '<';
                margin-right: 0.4rem;
            }
        }

        @include media-breakpoint-up(lg) {
            .about__image {
                height: 400px;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
</style>